import type { ToasterProps } from 'sonner';

export const SnackBarSettings: ToasterProps = {
    position: 'bottom-center',
    className: 'snackbar-toaster',
    theme: 'dark',
    offset: 24,
    visibleToasts: 3,
};

export const SnackBarSettingsMob: ToasterProps = {
    ...SnackBarSettings,
    position: 'top-center',
    offset: 12,
};
