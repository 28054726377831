import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'notifications.title',
        defaultMessage: 'Уведомления',
    },
    checkAll: {
        id: 'notifications.checkAll',
        defaultMessage: 'Отметить все прочитанным',
    },
    noNewNotifications: {
        id: 'notifications.noNewNotifications',
        defaultMessage: 'Новых уведомлений нет',
    },
    futureNotifications: {
        id: 'notifications.futureNotifications',
        defaultMessage: 'Позже здесь будут новости и напоминания о важных событиях',
    },
    noNotificationsYet: {
        id: 'notifications.noNotificationsYet',
        defaultMessage: 'Уведомлений пока нет',
    },
    deleted: {
        id: 'notifications.deleted',
        defaultMessage: 'Уведомление удалено',
    },
    friendRequest: {
        id: 'notifications.friendRequest',
        defaultMessage: 'хочет добавить вас в друзья',
    },
    friendRequestAccepted: {
        id: 'notifications.friendRequestAccepted',
        defaultMessage: 'Вы приняли заявку на дружбу',
    },
    friendRequestDeclined: {
        id: 'notifications.friendRequestDeclined',
        defaultMessage: 'Вы отклонили заявку на дружбу',
    },
    deleteNotification: {
        id: 'notifications.deleteNotification',
        defaultMessage: 'Удалить уведомление',
    },
    hideProject: {
        id: 'notifications.hideProject',
        defaultMessage: 'Не показывать уведомления проекта',
    },
    accept: {
        id: 'button.accept',
        defaultMessage: 'Принять',
    },
    decline: {
        id: 'button.decline',
        defaultMessage: 'Отклонить',
    },
    copyPromo: {
        id: 'notifications.copyPromo',
        defaultMessage: 'Скопировать промокод',
    },
    copyPromoSuccess: {
        id: 'notifications.copyPromoSuccess',
        defaultMessage: 'Промокод успешно скопирован',
    },
    cancel: {
        id: 'app.cancel',
        defaultMessage: 'Отменить',
    },
    unsubscribed: {
        id: 'notifications.unsubscribed',
        defaultMessage: 'Вы больше не будете получать уведомления от проекта {project_name}',
    },
    controlCancel: {
        id: 'search.control.cancel',
        defaultMessage: 'Отмена',
    },
});
