import React, { useCallback, useContext, useState } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Counter, Icon, WrapperButton } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import NotificationsContext from '@components/TopNav/Notifications/context/NotificationsContext';
import MobileNotificationOverlay from '@components/TopNav/Notifications/Mobile/MobileNotificationOverlay';

import styles from './MobOverlay.module.css';
import messages from '../../navigation/messages';

function NotificationMenuItem() {
    const { pushReachGoal } = useAnalyticsMethods();
    const { unread } = useContext(NotificationsContext);
    const { formatMessage } = useIntl();
    const [mobileOverlay, setMobileOverlay] = useState(false);

    const handleCloseMobileOverlay = useCallback(() => {
        setMobileOverlay(false);
    }, []);

    const handleOpenMobileOverlay = useCallback(() => {
        setMobileOverlay(true);

        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-notifications',
            },
        });
    }, []);

    return (
        <>
            <WrapperButton
                className={cn(styles.menuItem, styles.transparent)}
                onClick={handleOpenMobileOverlay}
            >
                <div className={styles.btnIcon}>
                    <Icon name={unread > 0 ? 'notification-subtract-outline' : 'notification-outline'} />

                    {unread > 0 && (
                        <Counter
                            size="small"
                            className={styles.counter}
                            mode="negative"
                        >
                            {unread}
                        </Counter>
                    )}
                </div>
                <div className={styles.btnText}>
                    {formatMessage(messages.notifications)}
                </div>
            </WrapperButton>

            <MobileNotificationOverlay
                onClose={handleCloseMobileOverlay}
                open={mobileOverlay}
            />
        </>
    );
}

export default NotificationMenuItem;
