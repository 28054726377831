import React from 'react';

import cn from 'classnames';

import styles from './Overlay.module.css';

import type { Props } from './types';
import type { FC } from 'react';

// Протестировано только на мобильной версии
const Overlay: FC<Props> = ({ className, children }) => (
    <div className={cn(styles.root, className)}>
        {children}
    </div>
);

export default Overlay;
