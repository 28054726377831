import React, { useState } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Backend, inGamecenter } from '@vkplay/shared';
import { Icon, Tooltip, WrapperButton } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import snackbar from '@kit/SnackBar/SnackBar';
import { Urls } from '@services/hosts';

import messages from './messages';
import styles from './Wishlist.module.css';

import type { Game } from '@components/Wishlist/types';
import type { SyntheticEvent } from 'react';

function GameWishlistButton({ game }: { game: Game }) {
    const { formatMessage } = useIntl();
    const { pushReachGoal } = useAnalyticsMethods();
    const [wishlist, setWishlist] = useState(true);

    const handleClick = async (event: SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();

        const isInWishlist = game.is_in_wishlist;
        const value = !isInWishlist ? 1 : 0;

        try {
            await Backend.api_post(Urls.gameSetting, {
                game_id: game.id,
                val: value,
                [value ? 'keys_on' : 'keys_off']: 'show_in_wishlist',
            });

            setWishlist((prevState) => {
                if (!inGamecenter) {
                    if (prevState) {
                        pushReachGoal({
                            params: {
                                category: 'library',
                                action: 'delete_click',
                                label: inGamecenter ? 'Gamecenter' : window.location.host,
                                entity_id: game.id,
                            },
                        });

                        snackbar.sendMessage({
                            status: 'success',
                            message: formatMessage(messages.deleted),
                        });
                    } else {
                        snackbar.sendMessage({
                            status: 'success',
                            message: formatMessage(messages.added),
                        });
                    }
                }

                return !prevState;
            });
        } catch (e) {
            console.error(e);

            if (!inGamecenter) {
                snackbar.sendMessage({
                    status: 'error',
                    message: formatMessage(messages.error),
                });
            }
        }
    };

    const tooltipId = `wishlist-tooltip-${game.id}`;

    return (
        <>
            <WrapperButton
                className={styles.wishlistGameButton}
                onClick={handleClick}
                data-tooltip-id={tooltipId}
            >
                <Icon name={wishlist ? 'bookmark-20' : 'bookmark-outline'} />
            </WrapperButton>
            <Tooltip
                id={tooltipId}
                place="bottom-end"
                appearance="neutral"
            >
                {wishlist ? formatMessage(messages.delete) : formatMessage(messages.add)}
            </Tooltip>
        </>
    );
}

export default GameWishlistButton;
