import { Backend, setCookie, DOMAIN } from '@vkplay/shared';

import { Urls } from '@services/hosts';

type Params = {
    lang: string,
    withSession?: boolean,
    onChangeLang?: (lang: string) => void,
}

export default async function changeLang({
    lang,
    withSession,
    onChangeLang,
}: Params) {
    const onSuccess = () => {
        setCookie('amc_lang', lang, {
            expires: 365 * 86400,
            domain: `.${DOMAIN}`,
            path: '/',
        });

        if (onChangeLang) {
            onChangeLang(lang);
        } else {
            window.location.reload();
        }
    };

    if (withSession) {
        try {
            await Backend.post(Urls.change_language, { lang });
        } catch (e) {
            console.error(e);
        }

        onSuccess();
    } else {
        onSuccess();
    }
}
