import React, { useCallback, useContext, useState } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Button, Counter, Icon } from '@vkplay/ui';

import NotificationsContext from '@components/TopNav/Notifications/context/NotificationsContext';

import MobileNotificationOverlay from './MobileNotificationOverlay';
import commonStyles from '../Notifications.module.css';

import type { SyntheticEvent } from 'react';

function NotificationsContainer() {
    const { unread } = useContext(NotificationsContext);
    const { pushReachGoal } = useAnalyticsMethods();
    const [mobileOverlay, setMobileOverlay] = useState(false);

    const handleClose = (event: SyntheticEvent) => {
        event.stopPropagation();
        setMobileOverlay(false);
    };

    const handleClick = useCallback(() => {
        setMobileOverlay(true);
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-notifications',
            },
        });
    }, []);

    return (
        <>
            <Button
                size="lg"
                appearance="neutral"
                onClick={handleClick}
                className={commonStyles.button}
                leftIcon={(
                    <div className={commonStyles.buttonInner}>
                        <Icon name={unread > 0 ? 'notification-subtract-outline' : 'notification-outline'} />
                        {unread > 0 && (
                            <Counter
                                size="small"
                                className={commonStyles.counter}
                                mode="negative"
                            >
                                {unread}
                            </Counter>
                        )}
                    </div>
                )}
                data-tooltip-id="notification-notifications"
            />

            <MobileNotificationOverlay
                onClose={handleClose}
                open={mobileOverlay}
            />
        </>
    );
}

export default NotificationsContainer;
