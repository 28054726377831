import React from 'react';

import { VKPLAY_DOMAIN, STATIC_CDN_DOMAIN } from '@vkplay/shared';
import { Icon, LabelSpecial } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import Price from '@components/Price';
import useDatetimeToLocaleString from '@hooks/useDatetimeToLocaleString';
import numberToLocalString from '@utils/numberToLocalString';

import { useSearchContext } from './context/SearchContext';
import messages from './messages';
import styles from './SearchDropdownItem.module.css';

import type { SearchDropdownItemProps, SpecialTypes } from './types';
import type { FC } from 'react';

const SearchDropdownItem: FC<SearchDropdownItemProps> = ({
    type, data, index, onClick,
}) => {
    const { size } = useSearchContext();
    const { datetimeToLocaleString } = useDatetimeToLocaleString();

    const { formatMessage } = useIntl();

    if (type === 'player') {
        const { icon, title, url } = data || {};

        return (
            <a
                data-id="search-dropdown__item"
                className={cn(styles.root, {
                    [styles.fullscreen]: size === 'fullscreen',
                })}
                href={url}
                onClick={() => onClick(url, index, 'player')}
            >
                <div className={cn(styles.imageWrapper, styles.avatar, {
                    [styles.avatarPlaceholder]: !icon,
                })}
                >
                    {icon
                        ? <img className={styles.image} src={icon} alt={title} />
                        : <div className={styles.imagePlaceholder}><Icon name="user-fill" /></div>}
                </div>
                <div className={styles.content}>
                    <div className={styles.title}>{title}</div>
                </div>
            </a>
        );
    }

    if (type === 'team') {
        const {
            icon, title, tags, url,
        } = data || {};

        return (
            <a
                data-id="search-dropdown__item"
                className={cn(styles.root, {
                    [styles.fullscreen]: size === 'fullscreen',
                })}
                href={url}
                onClick={() => onClick(url, index, 'team')}
            >
                <div className={cn(styles.imageWrapper, styles.logo)}>
                    {icon
                        ? <img className={styles.image} src={icon} alt={title} />
                        : <div className={styles.imagePlaceholder}><Icon name="users-fill" /></div>}
                </div>
                <div className={styles.content}>
                    <div className={styles.title}>{data.title}</div>
                    {tags[0] && <div className={styles.description}>{tags[0]}</div>}
                </div>
            </a>
        );
    }

    if (type === 'game') {
        const {
            icon, title, extra, url,
        } = data || {};

        const {
            cost_info, release_soon, relationship_kinds = [], is_cloud = false, type: gameType, has_distribution,
        } = extra.alias === 'play_game'
            ? extra
            : { cost_info: undefined, release_soon: false };

        const label = relationship_kinds[0]?.replace(/_?item_?/gm, '') as SpecialTypes;

        return (
            <a
                data-id="search-dropdown__item"
                className={cn(styles.root, {
                    [styles.fullscreen]: size === 'fullscreen',
                })}
                href={url}
                onClick={() => onClick(url, index, 'game')}
            >
                <div className={cn(styles.imageWrapper, styles.poster)}>
                    {icon && (
                        <img
                            className={styles.image}
                            src={icon.replace(`https://${VKPLAY_DOMAIN}/`, `https://${STATIC_CDN_DOMAIN}/`)}
                            alt={title}
                        />
                    )}
                </div>
                <div className={styles.content}>
                    {label && messages[label] && size === 'fullscreen' && (
                        <LabelSpecial
                            type={label}
                            size="xs"
                            label={formatMessage(messages[label])}
                            className={styles.labelMobile}
                        />
                    )}
                    <div className={styles.title}>{data.title}</div>
                    {cost_info && (
                        <div className={styles.description}>
                            <div>
                                <Price
                                    costInfo={cost_info}
                                    isReleaseSoon={release_soon}
                                    isCloud={is_cloud}
                                    noDistribution={!has_distribution}
                                    gameType={gameType}
                                />
                            </div>
                            {label && messages[label] && size !== 'fullscreen' && (
                                <LabelSpecial
                                    type={label}
                                    size="lg"
                                    label={formatMessage(messages[label])}
                                />
                            )}
                        </div>
                    )}
                </div>
            </a>
        );
    }

    if (type === 'service') {
        const {
            icon, title, url,
        } = data || {};

        const intlTitle = messages[title] ? formatMessage(messages[title]) : title;

        return (
            <a
                data-id="search-dropdown__item"
                className={cn(styles.root, {
                    [styles.fullscreen]: size === 'fullscreen',
                })}
                href={url}
                onClick={() => onClick(url, index, 'service')}
            >
                <div className={cn(styles.imageWrapper, styles.icon)}>
                    {icon && <Icon name={icon} />}
                </div>
                <div className={styles.content}>
                    <div className={styles.title}>{intlTitle}</div>
                </div>
            </a>
        );
    }

    if (type === 'media') {
        const {
            icon, title, extra, url,
        } = data || {};

        return (
            <a
                data-id="search-dropdown__item"
                className={cn(styles.root, {
                    [styles.fullscreen]: size === 'fullscreen',
                })}
                href={url}
                onClick={() => onClick(url, index, 'media')}
            >
                <div className={cn(styles.imageWrapper, styles.poster)}>
                    {icon && (
                        <img
                            className={styles.image}
                            src={icon.replace(`https://${VKPLAY_DOMAIN}/`, `https://${STATIC_CDN_DOMAIN}/`)}
                            alt={title}
                        />
                    )}
                </div>
                <div className={styles.content}>
                    <div className={styles.title}>{data.title}</div>
                    <div className={styles.description}>
                        <div className={styles.leftPart}>
                            {!!extra.date_published && (
                                <span>{datetimeToLocaleString(extra.date_published)}</span>
                            )}
                            {!!extra.views && (
                                <span className={styles.views}>
                                    <Icon name="view" />
                                    {numberToLocalString(extra.views, { notation: 'compact' })}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </a>
        );
    }

    return null;
};

export default SearchDropdownItem;
