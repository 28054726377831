import React from 'react';

import { createRoot } from 'react-dom/client';

import snackbar from '@kit/SnackBar/SnackBar';
import { showLogin } from '@services/auth';
import GcApi from '@services/gamecenter';
import { showDownloadHint } from '@services/showDownloadHint';
import { showSteamError } from '@services/showSteamError';

import './css/fonts.css';
import './css/shell.css';
import './css/variables.css';
import ProxyApp from './ProxyApp';

import type { AppShellProps } from './types';

function init(id: string, options: AppShellProps) {
    if (!id) {
        throw new Error('Eva: you have to specify the id in init function');
    }

    const container = document.getElementById(id);

    if (!container) {
        throw new Error('Eva: no container was found');
    }

    const root = createRoot(container);

    root.render(<ProxyApp {...options} />);
}

const gc = new GcApi();

window.EVA = {
    init,
    showLogin,
    showSteamError,
    showDownloadHint,
    gc,
    snackbar,
};

declare global {
    interface Window {
        EVA: {
            init: typeof init;
            showLogin: typeof showLogin,
            showSteamError: typeof showSteamError,
            showDownloadHint: typeof showDownloadHint,
            gc: typeof gc,
            snackbar: typeof snackbar,
            props?: AppShellProps;
        };
    }
}
