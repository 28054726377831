const LogoDeskAnimated = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-desk.webm';
const LogoDeskAnimated2x = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-desk@2x.webm';

const LogoMobAnimated = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-mob.webm';
const LogoMobAnimated2x = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-mob@2x.webm';

const LogoSmAnimated = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-sm.webm';
const LogoSmAnimated2x = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-sm@2x.webm';

const LogoDeskAnimatedMac = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-desk-hevc.mov';
const LogoDeskAnimatedMac2x = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-desk-hevc@2x.mov';

const LogoMobAnimatedMac = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-mob-hevc.mov';
const LogoMobAnimatedMac2x = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-mob-hevc@2x.mov';

const LogoSmAnimatedMac = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-sm-hevc.mov';
const LogoSmAnimatedMac2x = 'https://vkplay.ru/hotbox/gem-media/landings/ny2025/logo-sm-hevc@2x.mov';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isRetina = window.devicePixelRatio === 2;

export const LOGO_ANIMATIONS = {
    desktop: isSafari ? LogoDeskAnimatedMac : LogoDeskAnimated,
    small: isSafari ? LogoSmAnimatedMac : LogoSmAnimated,
    mobile: isSafari ? LogoMobAnimatedMac : LogoMobAnimated,
};

export const LOGO_ANIMATIONS_2X = {
    desktop: isSafari ? LogoDeskAnimatedMac2x : LogoDeskAnimated2x,
    small: isSafari ? LogoSmAnimatedMac2x : LogoSmAnimated2x,
    mobile: isSafari ? LogoMobAnimatedMac2x : LogoMobAnimated2x,
};

export const NEWYEAR_LOGO = isRetina ? LOGO_ANIMATIONS_2X : LOGO_ANIMATIONS;
