import { isMyGamesApp, inGamecenter } from '@vkplay/shared';

import { Urls } from '@services/hosts';

interface Options {
    continueUrl?: string;
    fromUrl?: string;
}

export const makeAuthUrl = (params?: Options): string => {
    const { continueUrl, fromUrl } = params || {};
    const continueParam = continueUrl ? `&continue=${encodeURIComponent(continueUrl)}` : '';

    return `${Urls.login}?from=${encodeURIComponent(fromUrl || window.location.href)}${continueParam}`;
};

export const showLogin = (params?: Options): void => {
    if (isMyGamesApp) {
        window.messageHandler?.postMessage('LOGIN');
    } else if (inGamecenter) {
        window.gc_show_auth_window?.();
    } else {
        window.location.href = makeAuthUrl(params);
    }
};

export default {
    showLogin,
};

declare global {
    interface Window {
        messageHandler?: {
            postMessage: (val: string) => void;
        };
        gc_show_auth_window?: () => void;
    }
}
