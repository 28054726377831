import React, { useContext, useState, useRef } from 'react';

import { Icon } from '@vkplay/ui';
import cn from 'classnames';

import IntlContext from '@context/IntlContext';
import changeLang from '@services/changeLang';

import styles from './LangDropdown.module.css';
import LangMobile from './LangMobile';
import LangTooltip from './LangTooltip';

import type { LangDropdownProps } from './types';

function LangDropdown({
    tooltipId = '',
    isMobile,
    transparent,
    title,
}: LangDropdownProps) {
    const { locale, langsList } = useContext(IntlContext);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLang, setSelectedLang] = useState(locale);
    const currentLang = langsList[locale as keyof typeof langsList] || langsList.ru_RU;

    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleToggleClass = () => {
        buttonRef.current?.classList.toggle(styles.open);
    };

    const handleToggleDialog = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleSelectLang = (key: string) => {
        setSelectedLang(key);
    };

    const handleSetLang = () => {
        if (selectedLang !== locale) {
            changeLang({ lang: selectedLang, withSession: true });
        }

        handleToggleDialog();
    };

    const handleChangeLangTooltip = (_: string, lang: string) => {
        if (lang !== locale) {
            changeLang({ lang, withSession: true });
        }
    };

    return (
        <>
            <div
                className={cn(styles.lang, {
                    [styles.transparent]: transparent,
                })}
            >
                {title && title}
                <button
                    data-tooltip-id={tooltipId}
                    // TODO: Переделать на компонент как будет
                    className={styles.dropdownButton}
                    onClick={handleToggleDialog}
                    ref={buttonRef}
                >
                    <span>
                        <img src={currentLang.flag} alt="Country flag" />
                        {currentLang.name}
                    </span>

                    <Icon name="chevron-right" />
                </button>
            </div>

            {isMobile
                ? (
                    <LangMobile
                        isOpen={isOpen}
                        onClose={handleToggleDialog}
                        onSelectLang={handleSelectLang}
                        onSetLang={handleSetLang}
                        selectedLang={selectedLang}
                    />
                )
                : (
                    <LangTooltip
                        locale={locale}
                        id={tooltipId}
                        place="bottom-start"
                        openOnClick
                        afterHide={handleToggleClass}
                        afterShow={handleToggleClass}
                        onEvent={handleChangeLangTooltip}
                    />
                )}
        </>
    );
}

export default LangDropdown;
