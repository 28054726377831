import { useContext, useEffect, useState } from 'react';

import { Backend } from '@vkplay/shared';

import SessionContext from '@context/SessionContext';
import { Urls } from '@services/hosts';

import type { Term } from '@components/TermsDialog/types';

const TERMS_VERSION = 4;
const CLIENT_ID = 'vkplay.com';

function useGetTerms({ hideTerms, setOpen }: { hideTerms?: boolean, setOpen?: (value: boolean) => void
}) {
    const { session: { session } } = useContext(SessionContext);
    const [cisTerms, setCisTerms] = useState<Array<Term>>([]);
    const [worldTerms, setWorldTerms] = useState<Array<Term>>([]);

    useEffect(() => {
        if (hideTerms) return;

        const getTerms = async () => {
            try {
                const { terms } = await Backend.get(Urls.get_terms, { client_id: CLIENT_ID });
                const currentTerms = terms[CLIENT_ID]?.urls;

                if (Array.isArray(currentTerms)) {
                    setCisTerms(currentTerms.slice(0, 2));
                    setWorldTerms(currentTerms.slice(2));
                }
            } catch (e) {
                console.error(e);
            }

            if (setOpen) setOpen(true);
        };

        if (session && +(session?.client_profile_fields?.terms_accepted ?? '0:0').split(':')[0] < TERMS_VERSION) {
            getTerms();
        }
    }, [session?.client_profile_fields]);

    return {
        cisTerms,
        worldTerms,
    };
}

export default useGetTerms;
