import React from 'react';

import { COLUMNS } from './constants';
import styles from './Footer.module.css';
import FooterColumn from './FooterColumn';

function FooterGrid() {
    return (
        <div className={styles.grid}>
            {COLUMNS.map((column, index) => (
                <FooterColumn
                    column={column}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                />
            ))}
        </div>
    );
}

export default FooterGrid;
