import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    login: {
        id: 'profile.login',
        defaultMessage: 'Войти',
    },
    cancel: {
        id: 'app.cancel',
        defaultMessage: 'Отменить',
    },
    logout: {
        id: 'profile.logout',
        defaultMessage: 'Выйти из аккаунта',
    },
    logoutShort: {
        id: 'profile.logout_short',
        defaultMessage: 'Выйти',
    },
    logoutTitle: {
        id: 'profile.logout.title',
        defaultMessage: 'Выход из аккаунта',
    },
    logoutDescr: {
        id: 'profile.logout.descr',
        defaultMessage: 'Вы уверены, что хотите выйти из аккаунта?',
    },
    goToProfile: {
        id: 'profile.go_to_profile',
        defaultMessage: 'Перейти в профиль',
    },
    settings: {
        id: 'profile.settings',
        defaultMessage: 'Настройки',
    },
    support: {
        id: 'profile.support',
        defaultMessage: 'Служба поддержки',
    },
    copy: {
        id: 'profile.id.copy',
        defaultMessage: 'Скопировать ID',
    },
    copied: {
        id: 'profile.id.copied',
        defaultMessage: 'ID успешно скопирован',
    },
});

export default messages;
