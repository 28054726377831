import React, { useContext, useCallback, type FC } from 'react';

import { htmlUnescape, copyTextToClipboard } from '@vkplay/shared';
import { Button, Icon } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import MenuItem from '@components/MobOverlay/MenuItem';
import NotificationMenuItem from '@components/MobOverlay/NotificationMenuItem';
import WishlistMenuItem from '@components/MobOverlay/WishlistMenuItem';
import SessionContext from '@context/SessionContext';
import snackbar from '@kit/SnackBar/SnackBar';
import getProfileSchema from '@navigation/profile.schema';
import { makeAuthUrl } from '@services/auth';

import messages from './messages';
import styles from './MobOverlay.module.css';

import type { NavigationItemProfile } from '@navigation/types';

const ProfileBlock: FC = () => {
    const { session: { session, profile } } = useContext(SessionContext);
    const { formatMessage } = useIntl();
    const authUrl = makeAuthUrl();
    const authLinks = getProfileSchema({
        onNotificationsClick: () => console.warn('notif click'),
    });

    const onCopyId = useCallback(() => {
        if (!session?.user_id) {
            return;
        }

        copyTextToClipboard(session.user_id.toString(), () => {
            snackbar.sendMessage({
                status: 'success',
                message: formatMessage(messages.copied),
            });
        });
    }, [session]);

    const getMenuItem = (item: NavigationItemProfile) => {
        if (item.id === 'notifications') {
            return (
                <NotificationMenuItem />
            );
        }

        if (item.id === 'favorites') {
            return (
                <WishlistMenuItem />
            );
        }

        return (
            <MenuItem
                key={item.id}
                item={item}
                transparent
            />
        );
    };

    if (!profile) {
        return (
            <div className={styles.noAuthBlock}>
                <div className={styles.noAuthContent}>
                    <div className={styles.noAuthTitle}>{formatMessage(messages.noAuth)}</div>
                    <Button
                        component="a"
                        href={authUrl}
                        mode="primary"
                        size="md"
                        appearance="overlay"
                    >
                        {formatMessage(messages.loginToAccount)}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.authBlock}>
            <div className={styles.authHeader}>
                <div className={styles.avatar}>
                    <img src={profile.avatar} alt={htmlUnescape(profile.nick)} />
                </div>
                <div className={styles.nick}>{htmlUnescape(profile.nick)}</div>
                <button
                    type="button"
                    className={styles.copy}
                    onClick={onCopyId}
                >
                    ID:
                    {' '}
                    {session?.user_id}
                    {' '}
                    <Icon name="copy-outline" />
                </button>
            </div>
            <div className={styles.profileActions}>
                {authLinks.map(getMenuItem)}
            </div>
        </div>
    );
};

export default ProfileBlock;
