import { isValidDate } from '@vkplay/shared';
import { useIntl } from 'react-intl';

import fixIsoDate from '@utils/fixIsoDate';
import isSameDate from '@utils/isSameDate';

import messages from './messages';

const useDatetimeToLocaleString = () => {
    const { formatDate, formatTime, formatMessage } = useIntl();

    const dateToLocaleString = (date: Date) => {
        const currentDate = new Date();

        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);

        if (isSameDate(currentDate, date)) {
            return formatMessage(messages.todayLabel);
        }

        currentDate.setDate(currentDate.getDate() - 1);

        if (isSameDate(currentDate, date)) {
            return formatMessage(messages.yesterdayLabel);
        }

        currentDate.setDate(currentDate.getDate() - 1);

        if (isSameDate(currentDate, date)) {
            return formatMessage(messages.beforeYesterdayLabel);
        }

        return formatDate(date, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        });
    };

    const datetimeToLocaleString = (isoString: string) => {
        const dateInstance = fixIsoDate(isoString);

        if (!isValidDate(dateInstance)) {
            return null;
        }

        const date = dateToLocaleString(dateInstance);

        const time = formatTime(dateInstance, {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
        });

        return `${date} ${formatMessage(messages.atTimeLabel)} ${time}`;
    };

    return {
        datetimeToLocaleString,
    };
};

export default useDatetimeToLocaleString;
