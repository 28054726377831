import React from 'react';

import cn from 'classnames';

import LogoVkPlaySm from '@assets/img/logo/logo-vkplay-sm.svg';
import LogoVkPlay from '@assets/img/logo/logo-vkplay.svg';
import VKPlayLogoNewYear from '@kit/VKPlayLogo/VKPlayLogoNewYear';

import styles from './VKPlayLogo.module.css';

import type { VKPlayLogoTypes } from './types';
import type { FC } from 'react';

const VKPlayLogo: FC<VKPlayLogoTypes> = ({ responsive, className }) => {
    const dateStop = new Date('2025-01-09T00:00:00');
    const showNewYear = Date.now() < dateStop.getTime() && !window.location.search.includes('jingle_bells=0');

    if (showNewYear) {
        return (
            <VKPlayLogoNewYear
                className={className}
                responsive={responsive}
            />
        );
    }

    return (
        <span
            className={cn(styles.logo, className, {
                [styles.responsive]: responsive,
            })}
        >
            <img
                src={LogoVkPlay}
                alt="VK Play"
                width="136"
                height="36"
                className={styles.logoImgLg}
            />
            {responsive && (
                <img
                    src={LogoVkPlaySm}
                    alt="VK Play"
                    width="36"
                    height="36"
                    className={styles.logoImgSm}
                />
            )}
        </span>
    );
};

export default VKPlayLogo;
