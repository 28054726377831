/* eslint-disable */
// @ts-nocheck

/**
 * Game Center API
 * @export
 * @namespace GameCenter
 */
const GameCenter = window.GameCenter
    || {
        /**
         * @const
         * @type {number}
         * @private
         */
        protoVersion: 3,

        /**
         * Auto timer period (ms).
         * Adjust before calling [GameCenter.init|#GameCenter.init].
         * @member {number} GameCenter.autoTimerPeriod
         */
        autoTimerPeriod: 1000,

        /**
         * WebSocket transport enabled.
         * Adjust before calling [GameCenter.init|#GameCenter.init].
         * @member {boolean} GameCenter.wsEnabled
         */
        wsEnabled: true,

        /**
         * WebSocket Tunnel transport enabled.
         * Adjust before calling [GameCenter.init|#GameCenter.init].
         * @member {boolean} GameCenter.wsTunEnabled
         */
        wsTunEnabled: false,

        /**
         * CallIn enum
         * Call error
         * @const
         * @member {number} GameCenter.ciError
         */
        ciError: 0,

        /**
         * CallIn enum
         * Call success
         * @const
         * @member {number} GameCenter.ciSuccess
         */
        ciSuccess: 1,

        /**
         * PluginDetect enum
         * GameCenter not installed
         * @const
         * @member {number} GameCenter.pdError
         */
        pdError: 0,

        /**
         * PluginDetect enum
         * GameCenter installed but not launched
         * @const
         * @member {number} GameCenter.pdSuccessNotLaunched
         */
        pdSuccessNotLaunched: 1,

        /**
         * PluginDetect enum
         * GameCenter installed and launched
         * @const
         * @member {number} GameCenter.pdSuccess
         */
        pdSuccess: 2,

        /**
         * @const
         * @member {number} GameCenter.cStepStart
         * @protected
         */
        cStepStart: 0,

        /**
         * @const
         * @member {number} GameCenter.cStepInit
         * @protected
         */
        cStepInit: 1,

        /**
         * @const
         * @member {number} GameCenter.cStepDetect
         * @protected
         */
        cStepDetect: 2,

        /**
         * @const
         * @type {string}
         * @private
         */
        jsonFormat: 'json',

        /**
         * @type {?function(*)}
         * @protected
         */
        onDispatch: null,

        /**
         * @type {?function()}
         * @protected
         */
        onInit: null,

        /**
         * @type {?function()}
         * @protected
         */
        onConnect: null,

        /**
         * @type {?function()}
         * @protected
         */
        onDisconnect: null,

        /**
         * @type {number}
         * @protected
         */
        step: 0,

        /**
         * @type {number}
         * @private
         */
        lastDetectState: -1,

        /**
         * @type {Object}
         * @private
         */
        wsHandle: null,

        /**
         * @type {Array<string>}
         * @private
         */
        wsQueue: [],

        /**
         * @type {Array<string>}
         * @protected
         */
        wsHost: ['127', '0', '0', '1'],

        /**
         * @type {string}
         * @protected
         */
        wsUrl: '',

        /**
         * @type {string}
         * @protected
         */
        wsTunUrlPrefix: '',

        /**
         * @type {string}
         * @private
         */
        wsTunName: '',

        /**
         * @type {number}
         * @private
         */
        wsTunPollNum: 0,

        /**
         * @type {HTMLImageElement}
         * @private
         */
        wsTunInitImg: null,

        /**
         * @type {Element}
         * @private
         */
        wsTunPollImg: null,

        /**
         * @param {string} key
         * @return {string}
         * @function
         * @protected
         */
        getCookie(key) {
            // noinspection ES6ConvertVarToLetConst
            let key1; let allCookies; let i; let
                currentCookie;

            key1 = `${key}=`;
            allCookies = document.cookie.split(';');
            for (i = 0; i < allCookies.length; i++) {
                currentCookie = allCookies[i];
                while (currentCookie.charAt(0) === ' ') {
                    currentCookie = currentCookie.substring(1);
                }

                if (currentCookie.indexOf(key1) === 0) {
                    return currentCookie.substring(key1.length, currentCookie.length);
                }
            }

            return '';
        },

        /**
         * @param {HTMLImageElement} inputImage
         * @return {string}
         * @function
         * @private
         */
        decImg2Str(inputImage) {
            // noinspection ES6ConvertVarToLetConst
            let imageCanvas; let imageCanvasCtx; let imageData; let buffer; let imageDataLen; let stringLen; let idx; let idxCount; let
                charCode;

            imageCanvas = document.createElement('canvas');
            // noinspection JSUnresolvedVariable
            imageCanvas.width = inputImage.width;
            // noinspection JSUnresolvedVariable
            imageCanvas.height = inputImage.height;
            imageCanvasCtx = imageCanvas.getContext('2d');
            imageCanvasCtx.globalCompositeOperation = 'copy';
            imageCanvasCtx.mozImageSmoothingEnabled = false;
            imageCanvasCtx.msImageSmoothingEnabled = false;
            imageCanvasCtx.imageSmoothingEnabled = false;
            imageCanvasCtx.drawImage(inputImage, 0, 0);
            // noinspection JSUnresolvedVariable
            imageData = imageCanvasCtx.getImageData(0, 0, inputImage.width, inputImage.height);
            buffer = '';
            imageDataLen = imageData.data.length;

            if (imageDataLen < 4) {
                // not enough data
                return '';
            }

            stringLen = imageData.data[0] + (imageData.data[1] << 8) + (imageData.data[2] << 16);
            idx = 4;
            idxCount = 0;
            while (stringLen > 0) {
                if (imageDataLen <= idx) {
                    // not enough data
                    return '';
                }

                charCode = imageData.data[idx++];

                if ((++idxCount) === 3) {
                    idx++;
                    idxCount = 0;
                }

                if (imageDataLen <= idx) {
                    // not enough data
                    return '';
                }

                charCode += imageData.data[idx++] << 8;

                if ((++idxCount) === 3) {
                    idx++;
                    idxCount = 0;
                }

                buffer += String.fromCharCode(charCode);
                stringLen--;
            }

            return buffer;
        },

        /**
         * @param {string} inputString
         * @return {string}
         * @function
         * @private
         */
        encStr2Param(inputString) {
            // noinspection ES6ConvertVarToLetConst
            let i; let buffer; let charCode; let charCode1; let
                charCode2;

            i = 0;
            buffer = '';
            while (i < inputString.length) {
                charCode = inputString.charCodeAt(i);
                charCode1 = (charCode & 255).toString(16);
                charCode2 = ((charCode >> 8) & 255).toString(16);

                if (charCode1.length === 1) {
                    buffer += '0';
                }

                buffer += charCode1;

                if (charCode2.length === 1) {
                    buffer += '0';
                }

                buffer += charCode2;
                i++;
            }

            return buffer;
        },

        /**
         * @param {string} postFix
         * @param {function()} onImageLoad
         * @param {function()} onImageError
         * @return {HTMLImageElement}
         * @function
         * @private
         */
        createTunImage(postFix, onImageLoad, onImageError) {
            // noinspection ES6ConvertVarToLetConst
            const image = new Image();
            // var image = document.createElement('img');
            image.style.visibility = 'hidden';
            image.crossOrigin = 'Anonymous';
            image.onload = onImageLoad;
            image.onerror = onImageError;

            image.src = `${GameCenter.wsTunUrlPrefix + postFix}ac=${Math.random() * 100000}`;

            return image;
        },

        /**
         * @function
         * @private
         */
        tryTunReceive(inputImage, schedule) {
            try {
                // noinspection ES6ConvertVarToLetConst
                let tunString; let
                    soon;

                tunString = GameCenter.decImg2Str(inputImage);

                if (typeof tunString === 'string') {
                    soon = tunString.length > 0;

                    if (soon) {
                        GameCenter.wsQueue.push(tunString);
                    }

                    if (schedule) {
                        GameCenter.scheduleTunPoll(soon);
                    }
                }
            } catch (e) {
                GameCenter.wsTunName = '';
            }
        },

        /**
         * @function
         * @private
         */
        tryTunPoll(tunName) {
            if (GameCenter.wsTunName.length === 0) {
                return;
            }

            if (tunName !== GameCenter.wsTunName) {
                return;
            }

            GameCenter.wsTunPollImg = GameCenter.createTunImage(
                `${GameCenter.wsTunName}/poll.png?`,
                function () {
                    GameCenter.tryTunReceive(this, true);
                },
                () => {
                    GameCenter.wsTunName = '';
                },
            );
        },

        /**
         * @param {boolean} soon
         * @function
         * @private
         */
        scheduleTunPoll(soon) {
            // noinspection ES6ConvertVarToLetConst
            let period; let
                tunName;

            tunName = GameCenter.wsTunName;

            if (soon) {
                period = 100;
            } else {
                period = GameCenter.autoTimerPeriod;
            }

            setTimeout(() => {
                GameCenter.tryTunPoll(tunName);
            }, period);
        },

        /**
         * @function
         * @private
         */
        tryTunInit() {
            GameCenter.wsTunInitImg = GameCenter.createTunImage(
                'init.png?',
                () => {
                    try {
                        // noinspection ES6ConvertVarToLetConst
                        let tunString; let
                            tunObj;

                        tunString = GameCenter.decImg2Str(GameCenter.wsTunInitImg);
                        tunObj = JSON.parse(tunString);

                        // noinspection JSUnresolvedVariable
                        if (typeof tunObj.Name === 'string') {
                            // noinspection JSUnresolvedVariable
                            GameCenter.wsTunName = tunObj.Name;
                            GameCenter.scheduleTunPoll(true);
                        }
                    } catch (ignore) {
                    }

                    GameCenter.wsTunInitImg = null;
                },
                () => {
                    GameCenter.wsTunInitImg = null;
                },
            );
        },

        /**
         * @function
         * @private
         */
        tryTunSend(str) {
            if (GameCenter.wsTunName.length === 0) {
                return;
            }

            // noinspection ES6ConvertVarToLetConst
            const param = GameCenter.encStr2Param(str);
            GameCenter.createTunImage(
                `${GameCenter.wsTunName}/send.png?num=${GameCenter.wsTunPollNum.toString()}&param=${param}&`,
                function () {
                    GameCenter.tryTunReceive(this, false);
                },
                () => {
                    GameCenter.wsTunName = '';
                },
            );
        },

        /**
         * @function
         * @private
         */
        callOut(dataType, data) {
            if (dataType === GameCenter.jsonFormat) {
                try {
                    // noinspection ES6ConvertVarToLetConst
                    const obj = JSON.parse(data);
                    GameCenter.onDispatch(obj);
                } catch (ignore) {
                }
            }
        },

        /**
         * @return {boolean}
         * @function
         * @protected
         */
        isWSConnected() {
            return ((GameCenter.wsEnabled) && (GameCenter.wsHandle) && (GameCenter.wsHandle.readyState === WebSocket.OPEN));
        },

        /**
         * @return {boolean}
         * @function
         * @protected
         */
        isWSTunConnected() {
            return ((GameCenter.wsTunEnabled) && (GameCenter.wsTunName.length > 0));
        },

        /**
         * @param {string} dataType
         * @param {string} data
         * @return {number}
         * @function
         * @private
         */
        callIn(dataType, data) {
            if (dataType === GameCenter.jsonFormat) {
                if (GameCenter.isWSConnected()) {
                    GameCenter.wsHandle.send(data);

                    return GameCenter.ciSuccess;
                }

                if (GameCenter.isWSTunConnected()) {
                    GameCenter.tryTunSend(data);

                    return GameCenter.ciSuccess;
                }
            }

            return GameCenter.ciError;
        },

        /**
         * @function
         * @private
         */
        poll() {
            // noinspection ES6ConvertVarToLetConst
            let data;

            while (GameCenter.wsQueue.length > 0) {
                data = GameCenter.wsQueue.shift();

                if (data.length !== 0) {
                    GameCenter.callOut(GameCenter.jsonFormat, data);
                }
            }
        },

        /**
         * @function
         * @private
         */
        wsOnMessage(event) {
            try {
                if (event.data.length > 0) {
                    GameCenter.wsQueue.push(event.data);
                }
            } catch (ignore) {
            }
        },

        /**
         * @function
         * @protected
         */
        autoTimer() {
            try {
                // WebSocket stuff
                if (GameCenter.wsHandle) {
                    if (GameCenter.wsHandle.readyState === WebSocket.CLOSED) {
                        GameCenter.wsHandle = null;
                    } else if (!GameCenter.wsEnabled) {
                        GameCenter.wsHandle.close();
                    }
                } else if (GameCenter.wsEnabled) {
                    GameCenter.wsHandle = new WebSocket(GameCenter.wsUrl);
                    GameCenter.wsHandle.onmessage = GameCenter.wsOnMessage;
                }
            } catch (ignore) {
            }

            try {
                // WebSocket Tun stuff
                if (GameCenter.wsTunEnabled) {
                    if ((GameCenter.wsTunInitImg === null) && (GameCenter.wsTunName.length === 0)) {
                        GameCenter.tryTunInit();
                    }
                }
            } catch (ignore) {
            }

            try {
                if (GameCenter.step === GameCenter.cStepInit) {
                    if (GameCenter.isWSConnected() || GameCenter.isWSTunConnected()) {
                        GameCenter.step = GameCenter.cStepDetect;

                        // noinspection JSValidateTypes
                        if (GameCenter.onInit !== undefined) {
                            GameCenter.onInit();
                        }
                    }
                }

                if (GameCenter.step === GameCenter.cStepDetect) {
                    // noinspection ES6ConvertVarToLetConst
                    const d = GameCenter.detect();

                    if (d !== GameCenter.lastDetectState) {
                        GameCenter.lastDetectState = d;

                        if (GameCenter.lastDetectState === GameCenter.pdSuccess) {
                            // noinspection JSValidateTypes
                            if (GameCenter.onConnect !== undefined) {
                                GameCenter.onConnect();
                            }
                        } else {
                            // noinspection JSValidateTypes
                            if (GameCenter.onDisconnect !== undefined) {
                                GameCenter.onDisconnect();
                            }
                        }
                    }

                    if (GameCenter.lastDetectState === GameCenter.pdSuccess) {
                        GameCenter.poll();
                    }
                }
            } catch (ignore) {
            }

            // noinspection JSCheckFunctionSignatures
            setTimeout(GameCenter.autoTimer, GameCenter.autoTimerPeriod);
        },

        /**
         * Initialize GameCenter plugin
         * @namespace GameCenter.init
         * @param {Element} detectorContainer HTML control that will contain
         *                 detector plugin instances.
         * @param {function(*)} onDispatch Callback function that receives
         *                   GameCenter messages.
         * @param {function()} onInit Called upon first success plugin init.
         * @param {function()} onConnect Called upon Gamecenter connected with plugin.
         * @param {function()} onDisconnect Called upon Gamecenter disconnected
         *                   from plugin.
         * @param {number} region 0 - vkplay.ru;
         *                 1 - my.games;
         * @function
         */
        init(detectorContainer, onDispatch, onInit, onConnect, onDisconnect, region) {
            // noinspection ES6ConvertVarToLetConst
            let wsPrefix; let
                checkCanvas;

            if (GameCenter.step === GameCenter.cStepStart) {
                wsPrefix = `${GameCenter.wsHost.join('.')}:`;

                if (region === 1) {
                    wsPrefix += '51201';
                } else {
                    wsPrefix += '51200';
                }

                GameCenter.wsUrl = `ws://${wsPrefix}/detector`;
                GameCenter.wsTunUrlPrefix = `http://${wsPrefix}/tun/`;

                if (typeof WebSocket !== 'function') {
                    GameCenter.wsEnabled = false;
                }

                checkCanvas = document.createElement('canvas');

                if (!(checkCanvas.getContext && checkCanvas.getContext('2d'))) {
                    GameCenter.wsTunEnabled = false;
                }

                if (window.location.protocol === 'https:') {
                    GameCenter.wsEnabled = false;
                } else {
                    GameCenter.wsTunEnabled = false;
                }

                GameCenter.onDispatch = onDispatch;
                GameCenter.onInit = onInit;
                GameCenter.onConnect = onConnect;
                GameCenter.onDisconnect = onDisconnect;
                GameCenter.step = GameCenter.cStepInit;
                GameCenter.autoTimer();
            }
        },

        /**
         * Detect GameCenter presence
         * @namespace GameCenter.detect
         * @return {number} refer to
         *                  [PluginDetect enum|#GameCenter.PluginDetect enum].
         * @function
         */
        detect() {
            if (GameCenter.isWSConnected() || GameCenter.isWSTunConnected()) {
                return GameCenter.pdSuccess;
            }

            return GameCenter.pdError;
        },

        /**
         * @param {*} obj
         * @return {number}
         * @function
         * @protected
         */
        callInJson(obj) {
            // noinspection ES6ConvertVarToLetConst
            const str = JSON.stringify(obj);

            return GameCenter.callIn(GameCenter.jsonFormat, str);
        },

        /**
         * @param {*} obj
         * @function
         * @protected
         */
        fillCookies(obj) {
            obj.FirstLink = GameCenter.getCookie('mr1lad');
            obj.FirstLinkExt = GameCenter.getCookie('mr1lext');
            obj.FirstLinkUID = GameCenter.getCookie('mr1uid');
            obj.AdId = GameCenter.getCookie('adId');
            obj.FBC = GameCenter.getCookie('_fbc');
            obj.FBP = GameCenter.getCookie('_fbp');
            obj.GA = GameCenter.getCookie('_ga');
            obj.Cookiesync = GameCenter.getCookie('cookiesync');
        },

        // high level

        /**
         * Set Auth Code
         * @namespace GameCenter.setAuthCode
         * @param {string} code - AuthCode.
         * @param {number} force 0 - false use code only if current login is empty;
         *                       1 - true force using supplied code (replace existing login).
         * @param {number} typeId Game typeId.
         * @param {number} projectId Game projectId.
         * @param {number} remigrate 0 - false no remigration;
         *                           1 - true try remigrate to supplied game id after authorization.
         * @param {string} state - Native app auth state.
         * @return {number} refer to [CallIn enum|#GameCenter.CallIn enum].
         * @function
         * @example
         * web site should call GameCenter.setAuthCode after receiving getAuthCode
         * request object
         * {
         *   Method: 'GetAuthCode'
         * }
         */
        setAuthCode(code, force, typeId, projectId, remigrate, state) {
            // noinspection ES6ConvertVarToLetConst
            const obj = { Method: 'SetAuthCode' };
            obj.Code = code;
            obj.Force = force;
            obj.TypeId = typeId;
            obj.ProjectId = projectId;
            obj.Remigrate = remigrate;
            obj.State = state;

            return GameCenter.callInJson(obj);
        },

        /**
         * Play game
         * @namespace GameCenter.playGame
         * @param {number} typeId Game typeId.
         * @param {number} projectId Game projectId.
         * @param {number} startDownload start download (0 - false, 1 - true)
         * @param {string} adParams ad params (e.g. "_1lp=1&_1ld=123_456&mt_link_id=xxx&mt_sub1=yyy")
         * @return {number} refer to [CallIn enum|#GameCenter.CallIn enum].
         * @function
         * @example
         * upon completion "dispatch" callback will receive answer object:
         * {
         *   Method: 'PlayGame',
         *   Result: 0,
         *   ProjectId: 1177,
         *   TypeId: 0
         * }

         * Result {number} 0 - false (unable to play game);
         *                 1 - true (game launched);
         *
         */
        playGame(typeId, projectId, startDownload, adParams) {
            // noinspection ES6ConvertVarToLetConst
            const obj = { Method: 'PlayGame' };
            obj.TypeId = typeId;
            obj.ProjectId = projectId;
            obj.StartDownload = startDownload;
            obj.AdParams = adParams;
            GameCenter.fillCookies(obj);

            return GameCenter.callInJson(obj);
        },

        /**
         * Show game
         * @namespace GameCenter.showGame
         * @param {number} typeId Game typeId.
         * @param {number} projectId Game projectId.
         * @param {number} startDownload start download (0 - false, 1 - true)
         * @param {string} adParams ad params (e.g. "_1lp=1&_1ld=123_456&mt_link_id=xxx&mt_sub1=yyy")
         * @return {number} refer to [CallIn enum|#GameCenter.CallIn enum].
         * @function
         * @example
         * upon completion "dispatch" callback will receive answer object:
         * {
         *   Method: 'ShowGame',
         *   Result: 0,
         *   ProjectId: 1177,
         *   TypeId: 0
         * }

         * Result {number} 0 - false (unable to show game);
         *                 1 - true (game showed);
         *
         */
        showGame(typeId, projectId, startDownload, adParams) {
            // noinspection ES6ConvertVarToLetConst
            const obj = { Method: 'ShowGame' };
            obj.TypeId = typeId;
            obj.ProjectId = projectId;
            obj.StartDownload = startDownload;
            obj.AdParams = adParams;
            GameCenter.fillCookies(obj);

            return GameCenter.callInJson(obj);
        },

        /**
         * Send Support Report
         * @namespace GameCenter.sendSupportReport
         * @param {string} hash Support hash.
         * @param {number} typeId Game typeId.
         * @param {number} projectId Game projectId.
         * @return {number} refer to [CallIn enum|#GameCenter.CallIn enum].
         * @function
         * @example
         * upon completion "dispatch" callback will receive answer object:
         * {
         *   Method: 'SendSupportReport',
         *   Result: 0,
         *   Hash: '',
         *   Text: '',
         *   ProjectId: 1177,
         *   TypeId: 0
         * }

         * Result {number} 0 - false
         *                 1 - true
         *                 2 - interrupted
         *
         * Text {string}   'Game not found' - wrong TypeId+ProjectId specified
         *                 'Game not installed' - game not installed
         */
        sendSupportReport(hash, typeId, projectId) {
            // noinspection ES6ConvertVarToLetConst
            const obj = { Method: 'SendSupportReport' };
            obj.Hash = hash;
            obj.TypeId = typeId;
            obj.ProjectId = projectId;

            return GameCenter.callInJson(obj);
        },

        /**
         * Show games showcase
         * @namespace GameCenter.showGamesShowcase
         * @return {number} refer to [CallIn enum|#GameCenter.CallIn enum].
         * @function
         */
        showGamesShowcase() {
            // noinspection ES6ConvertVarToLetConst
            const obj = { Method: 'ShowGamesShowcase' };
            GameCenter.fillCookies(obj);

            return GameCenter.callInJson(obj);
        },

        /**
         * Set Ticket cookies
         * @namespace GameCenter.setTicket
         * @return {number} refer to [CallIn enum|#GameCenter.CallIn enum].
         * @function
         */
        setTicket() {
            // noinspection ES6ConvertVarToLetConst
            const obj = { Method: 'SetTicket' };
            GameCenter.fillCookies(obj);
            // noinspection ES6ConvertVarToLetConst
            const firstLinkLPS = GameCenter.getCookie('mr1lad_lps');

            if (firstLinkLPS.length > 0) obj.FirstLink = firstLinkLPS;

            return GameCenter.callInJson(obj);
        },

        /**
         * Set Stream Auth Code
         * @namespace GameCenter.setStreamAuthCode
         * @param {string} code - AuthCode.
         * @param {number} typeId Game typeId.
         * @param {number} projectId Game projectId.
         * @return {number} refer to [CallIn enum|#GameCenter.CallIn enum].
         * @function
         */
        setStreamAuthCode(code, typeId, projectId) {
            // noinspection ES6ConvertVarToLetConst
            const obj = { Method: 'SetStreamAuthCode' };
            obj.Code = code;
            obj.TypeId = typeId;
            obj.ProjectId = projectId;

            return GameCenter.callInJson(obj);
        },

        /**
         * Become Streamer Finished
         * @namespace GameCenter.becomeStreamerFinished
         * @return {number} refer to [CallIn enum|#GameCenter.CallIn enum].
         * @function
         */
        becomeStreamerFinished() {
            // noinspection ES6ConvertVarToLetConst
            const obj = { Method: 'BecomeStreamerFinished' };

            return GameCenter.callInJson(obj);
        },

        /**
         * Set launcher foreground.
         * @namespace GameCenter.setForeground
         * @return {number} refer to [CallIn enum|#GameCenter.CallIn enum].
         * @function
         */
        setForeground() {
            // noinspection ES6ConvertVarToLetConst
            const obj = { Method: 'SetForeground' };

            return GameCenter.callInJson(obj);
        },

    };

export default GameCenter;
