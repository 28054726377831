import React from 'react';

import Notification from '@components/TopNav/Notifications/Notification';

import type { NotificationPopupProps } from '../types';

function NotificationPopup({
    notification,
    onClose,
}: NotificationPopupProps) {
    return (
        <Notification
            notification={notification}
            onClose={onClose}
        />
    );
}

export default NotificationPopup;
