import { GAMES_HOST } from '@services/hosts';

import messages from './messages';

import type { NavigationItem } from './types';

const ORIGIN = `https://${GAMES_HOST}`;

const live = {
    id: 'live',
    label: messages.live,
    href: `${ORIGIN}/live/`,
    icon: 'live-outline',
    analyticsLabel: 'ph-menu-streams',
    subnav: [
        {
            label: messages.often,
            id: 'often',
            href: `${ORIGIN}/live/`,
        },
        {
            label: messages.online,
            id: 'online',
            href: `${ORIGIN}/live/`,
        },
    ],
} as const satisfies NavigationItem;

export default live;
