import { useIntl, type MessageDescriptor } from 'react-intl';

const useTranslate = (label: MessageDescriptor | string = '') => {
    const { formatMessage } = useIntl();

    const translatedLabel = (typeof label !== 'string' && label.id)
        ? formatMessage(label)
        : label;

    return translatedLabel.toString();
};

export default useTranslate;
