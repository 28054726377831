import React from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { useIntl } from 'react-intl';

import gamesList from './gamesList';
import messages from './messages';
import styles from './PopularGames.module.css';

import type { FC, MouseEvent } from 'react';

const PopularGames: FC = () => {
    const { formatMessage } = useIntl();
    const { pushReachGoal } = useAnalyticsMethods();

    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-market',
                entity_id: event.currentTarget.href,
            },
        });
    };

    return (
        <div className={styles.popularGames}>
            <div className={styles.title}>
                {formatMessage(messages.title)}
            </div>
            {gamesList.map((game) => (
                <a
                    href={game.url}
                    className={styles.game}
                    key={game.url}
                    onClick={handleClick}
                    rel="noreferrer"
                >
                    <span className={styles.icon}>
                        <img src={game.icon} alt={game.name} />
                    </span>
                    {game.name}
                </a>
            ))}
        </div>
    );
};

export default PopularGames;
