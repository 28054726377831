import { type RefObject, useEffect } from 'react';

export default function useOnClickOutside(
    ref: RefObject<HTMLElement>,
    handler: (event: MouseEvent | TouchEvent) => void,
    touch: 'touchstart' | 'touchend' = 'touchstart',
) {
    useEffect(
        () => {
            const listener = (event: MouseEvent | TouchEvent) => {
                // Do nothing if clicking ref"s element or descendent elements
                if (!ref.current || ref.current.contains(event.target as Node)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mouseup', listener);
            document.addEventListener(touch, listener);

            return () => {
                document.removeEventListener('mouseup', listener);
                document.removeEventListener(touch, listener);
            };
        },
        [ref, handler],
    );
}
