import { GAMES_HOST, PROFILE_HOST } from '@services/hosts';

import messages from './messages';

import type { NavigationItem } from './types';

const ORIGIN = `https://${GAMES_HOST}`;
const PROFILE_ORIGIN = `https://${PROFILE_HOST}`;

const store = {
    id: 'store',
    label: messages.store,
    href: `${ORIGIN}/play/`,
    icon: 'game-outline',
    analyticsLabel: 'ph-menu-catalog',
    subnav: [
        {
            label: messages.play,
            id: 'play',
            href: `${ORIGIN}/play/`,
            allowPartialMatch: true,
            exclude: [
                `${ORIGIN}/play/my`,
                `${ORIGIN}/play/game`,
                `${ORIGIN}/play/promo`,
                `${ORIGIN}/play/tags`,
                `${ORIGIN}/play/is_free/`,
                `${ORIGIN}/play/sale/`,
            ],
        },
        {
            label: messages.free,
            id: 'free',
            href: `${ORIGIN}/play/is_free/`,
        },
        {
            label: messages.sale,
            id: 'sale',
            href: `${ORIGIN}/play/sale/`,
        },
        {
            label: messages.my,
            id: 'my',
            href: `${PROFILE_ORIGIN}/profile/games`,
        },
    ],
} as const satisfies NavigationItem;

export default store;
