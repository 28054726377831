import getPathname from '@navigation/utils/getPathname';

import type { NavigationItemBasic } from '@navigation/types';

export default function getCurrentAliasUrl(item: NavigationItemBasic, type: 'exclude'|'include'): boolean {
    const targetArr = type === 'exclude' ? item.exclude : item.include;
    const allowPartial = item.allowPartialMatch;
    const currentPath = window.location.pathname;

    if (!targetArr?.length) {
        return false;
    }

    return !!targetArr.find((url: string) => {
        const pathname = getPathname(url);

        return allowPartial ? currentPath.includes(pathname) : pathname === currentPath;
    });
}
