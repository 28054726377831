import React, { useContext } from 'react';

import cn from 'classnames';

import AppContext from '@context/AppContext';
import Portal from '@kit/Portal';
import ThemeWrapper from '@kit/ThemeWrapper';

import styles from './Footer.module.css';
import FooterBottom from './FooterBottom';
import FooterTop from './FooterTop';

function Footer() {
    const { simplified, footerSelector } = useContext(AppContext);
    const FooterContent = (
        <footer
            className={cn(styles.footer, {
                [styles.simplified]: simplified,
            })}
        >
            <div className={styles.container}>
                <FooterTop />
                <FooterBottom />
            </div>
        </footer>
    );

    if (simplified && footerSelector) {
        return (
            <Portal into={footerSelector}>
                <ThemeWrapper>
                    {FooterContent}
                </ThemeWrapper>
            </Portal>
        );
    }

    return FooterContent;
}

export default Footer;
