import { useCallback, useEffect, useState } from 'react';

import { API_HOST } from '@services/hosts';
import debounce from '@utils/debounce';

import useSearchData from './useSearchData';

import type { UseSearchOptions, MediaSearchResponse, GameSearchResponse } from '@components/Search/types';

const useSearch = ({ includeSearch, onShouldShowResult }: UseSearchOptions) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    // Doesn't have stage or gm3
    const playersSearch = useSearchData({
        url: 'https://pvp.vkplay.ru/api/search/vkplay',
        defaultQuerySet: {
            category: 'pvp_players',
            limit: '5',
        },
    });

    // Doesn't have stage or gm3
    const teamsSearch = useSearchData({
        url: 'https://pvp.vkplay.ru/api/search/vkplay',
        defaultQuerySet: {
            category: 'pvp_teams',
            limit: '5',
        },
    });

    const gamesSearch = useSearchData<GameSearchResponse>({
        url: `https://${API_HOST}/pc/v3/search/`,
        defaultQuerySet: {
            alias: 'play_game',
            limit: '5',
        },
    });

    const devSearch = useSearchData<GameSearchResponse>({
        url: `https://${API_HOST}/play/search/`,
        defaultQuerySet: {
            is_dev: '1',
        },
    });

    const mediaSearch = useSearchData<MediaSearchResponse>({
        url: `https://${API_HOST}/pc/v3/search/`,
        defaultQuerySet: {
            limit: '5',
        },
    });

    const handleSetSearchQuery = (value: string) => {
        setSearchQuery(value);
    };

    const fetchData = useCallback(debounce(async (_searchQuery: string) => {
        setIsLoading(true);

        const additionalSearches = [];

        if (includeSearch?.includes('pvp')) {
            additionalSearches.push(
                playersSearch.handleSearch(_searchQuery),
                teamsSearch.handleSearch(_searchQuery),
            );
        }

        try {
            await Promise.all([
                gamesSearch.handleSearch(_searchQuery),
                devSearch.handleSearch(_searchQuery),
                mediaSearch.handleSearch(_searchQuery),
                ...additionalSearches,
            ]);
        } catch (error) {
            // ignore
        } finally {
            setIsLoading(false);
        }
    }), [
        playersSearch.handleSearch,
        teamsSearch.handleSearch,
        gamesSearch.handleSearch,
        devSearch.handleSearch,
        mediaSearch.handleSearch,
    ]);

    const loadMore = async (type: 'player' | 'team' | 'game' | 'dev' | 'media') => {
        if (type === 'player') {
            await playersSearch.loadMore();
        }

        if (type === 'team') {
            await teamsSearch.loadMore();
        }

        if (type === 'game') {
            await gamesSearch.loadMore();
        }

        if (type === 'dev') {
            await devSearch.loadMore();
        }

        if (type === 'media') {
            await mediaSearch.loadMore();
        }
    };

    useEffect(() => {
        if (searchQuery.length < 3) {
            onShouldShowResult?.(false);

            return;
        }

        onShouldShowResult?.(true);
        fetchData(searchQuery);
    }, [searchQuery]);

    return {
        searchQuery,
        handleSetSearchQuery,
        loadMore,
        isLoading,
        data: {
            playersSearch,
            teamsSearch,
            gamesSearch,
            devSearch,
            mediaSearch,
        },
    };
};

export default useSearch;
