export enum HiddenElementsTypes {
    Notifications = 'notifications',
    Search = 'search',
    Favorites = 'favorites',
    User = 'user',
    RecPopup = 'recPopup',
    Terms = 'terms',
}

export default {
    HiddenElementsTypes,
};
