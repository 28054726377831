import IconAw from '@assets/img/popular/aw.svg';
import IconRv from '@assets/img/popular/rv.png';
import IconWf from '@assets/img/popular/wf.svg';
import IconWtl from '@assets/img/popular/wtl.png';

const Icons = {
    wf: IconWf,
    aw: IconAw,
    wtl: IconWtl,
    rv: IconRv,
};

export default [
    {
        name: 'Warface',
        url: 'https://market.vkplay.ru/game/1/',
        icon: Icons.wf,
    },
    {
        name: 'Armored Warfare',
        url: 'https://market.vkplay.ru/shop/3/',
        icon: Icons.aw,
    },
    {
        name: 'Will To Live Online',
        url: 'https://market.vkplay.ru/shop/115/',
        icon: Icons.wtl,
    },
    {
        name: 'Revelation',
        url: 'https://market.vkplay.ru/game/5/',
        icon: Icons.rv,
    },
];
