import React, { useCallback, useContext } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import {
    Button, Counter, Icon, Tooltip,
} from '@vkplay/ui';
import cn from 'classnames';

import NotificationsContext from '@components/TopNav/Notifications/context/NotificationsContext';
import NotificationsList from '@components/TopNav/Notifications/NotificationsList';
import SessionContext from '@context/SessionContext';
import { showLogin } from '@services/auth';

import styles from './Notifications.module.css';

function NotificationsContainer() {
    const { pushReachGoal } = useAnalyticsMethods();
    const {
        list,
        unread,
        onOpenNotification,
    } = useContext(NotificationsContext);

    const { noSession, loaded } = useContext(SessionContext);

    const handleClick = useCallback(() => {
        if (noSession) {
            showLogin();
        }

        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-notifications',
            },
        });
    }, [loaded]);

    if (!loaded) return null;

    return (
        <>
            <Button
                size="lg"
                appearance="neutral"
                onClick={handleClick}
                className={styles.button}
                leftIcon={(
                    <div className={styles.buttonInner}>
                        <Icon name={unread > 0 ? 'notification-subtract-outline' : 'notification-outline'} />
                        {unread > 0 && (
                            <Counter
                                size="small"
                                className={styles.counter}
                                mode="negative"
                            >
                                {unread}
                            </Counter>
                        )}
                    </div>
                )}
                data-tooltip-id="notification-notifications"
            />
            {!noSession && (
                <Tooltip
                    id="notification-notifications"
                    place="bottom-start"
                    className={cn(styles.wrapper, {
                        [styles.full]: list.length === 0,
                    })}
                    afterShow={onOpenNotification}
                    opacity={1}
                    clickable
                    openOnClick
                    closeEvents={{
                        click: true,
                    }}
                >
                    <NotificationsList />
                </Tooltip>
            )}
        </>
    );
}

export default NotificationsContainer;
