import React, { useState, useEffect, type FC } from 'react';

import { Icon, WrapperButton } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import Portal from '@kit/Portal';
import { GC_DOWNLOAD_HINT_EVENT } from '@services/showDownloadHint';

import styles from './GCDownloadHint.module.css';
import messages from './messages';
import { browserFamily, browserClassName, browserVersionClassName } from './utils';

const GCDownloadHint: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { formatMessage } = useIntl();

    const showHint = () => {
        setIsOpen(true);
    };

    const hideHint = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        window.addEventListener(GC_DOWNLOAD_HINT_EVENT, showHint);

        return (() => {
            window.removeEventListener(GC_DOWNLOAD_HINT_EVENT, showHint);
        });
    }, []);

    if (!isOpen || browserFamily.toLowerCase() === 'safari') {
        return null;
    }

    return (
        <Portal into="body">
            <div
                className={cn(styles.popupContainer, {
                    [styles[browserClassName]]: browserClassName && styles[browserClassName],
                    [styles[browserVersionClassName]]: browserVersionClassName && styles[browserVersionClassName],
                })}
            >
                <WrapperButton
                    className={styles.overlay}
                    onMouseDown={hideHint}
                    onClick={() => {}}
                />
                <div className={cn(styles.arrow, styles.isShaking)}>
                    <Icon name="arrow-down-outline" />
                </div>
                <div className={styles.text}>
                    <ol>
                        <li>
                            {formatMessage(messages.overlayStep1)}
                        </li>
                        <li>
                            {formatMessage(messages.overlayStep2)}
                        </li>
                    </ol>
                </div>
            </div>
        </Portal>
    );
};

export default GCDownloadHint;
