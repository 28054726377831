import React from 'react';

import cn from 'classnames';

import styles from './Notification.module.css';

import type { NotificationLabelProps } from '../types';

function NotificationLabel({
    label,
    position = 'bottom',
}: NotificationLabelProps) {
    if (!label) return null;

    return (
        <div className={cn(styles.notificationLabel, styles[position])}>
            {label}
        </div>
    );
}

export default NotificationLabel;
