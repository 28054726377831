import { useEffect, useState } from 'react';

import { Backend, inGamecenter } from '@vkplay/shared';

import { Urls } from '@services/hosts';

import type { Game } from '@components/Wishlist/types';

function useWishlistData() {
    const [data, setData] = useState<Array<Game>>([]);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);

    useEffect(() => {
        const getData = async () => {
            try {
                const { results, count: currentCount } = await Backend.get(Urls.wishlist, {
                    limit: 24,
                });

                if (Array.isArray(results)) {
                    setData(results);
                    setCount(currentCount);
                }
            } catch (e) {
                console.error(e);
            }

            setLoading(false);
        };

        if (inGamecenter) {
            window.gc_window_state = (state: number) => {
                if (state === 3) {
                    getData();
                }

                if (state === 1) {
                    setData([]);
                    setLoading(true);
                }
            };
        }

        getData();
    }, []);

    return {
        data,
        loading,
        count,
    };
}

export default useWishlistData;
