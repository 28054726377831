import React from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Icon } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import ProfileExit from '@components/TopNav/Profile/ProfileExit';
import { Urls } from '@services/hosts';

import messages from './messages';
import styles from './Profile.module.css';
import ProfileLangSelect from './ProfileLangSelect';

function ProfileLinks() {
    const { pushReachGoal } = useAnalyticsMethods();
    const { formatMessage } = useIntl();

    const handleClickSettings = () => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-settings',
            },
        });
    };

    const handleClickSupport = () => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-support',
            },
        });
    };

    return (
        <div className={styles.profileLinks}>
            <a
                href={Urls.accountSettings}
                className={styles.link}
                onClick={handleClickSettings}
            >
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <Icon name="settings-outline" className={styles.icon} />
                <span>{formatMessage(messages.settings)}</span>
            </a>

            <a
                href={Urls.support}
                className={styles.link}
                onClick={handleClickSupport}
            >
                <Icon name="help-outline" className={styles.icon} />
                <span>{formatMessage(messages.support)}</span>
            </a>

            <ProfileLangSelect />

            <div className={styles.divider} />

            <ProfileExit />
        </div>
    );
}

export default ProfileLinks;
