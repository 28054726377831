import React from 'react';

import Overlay from '@components/Overlay';
import { MOB_CONTAINER_ID } from '@components/TopNav/Notifications/constants';
import styles from '@components/TopNav/Notifications/Mobile/MobileNotifications.module.css';
import WishlistOverlayContent from '@components/Wishlist/Overlay/WishlistOverlayContent';
import Portal from '@kit/Portal';

import type { WishlistOverlayProps } from '@components/Wishlist/types';

function WishlistOverlay(props: WishlistOverlayProps) {
    if (!props.open) return null;

    return (
        <Portal
            shouldDisableBodyScroll
            into={MOB_CONTAINER_ID}
        >
            <Overlay className={styles.container}>
                <WishlistOverlayContent
                    {...props}
                />
            </Overlay>
        </Portal>
    );
}

export default WishlistOverlay;
