import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    controlCancel: {
        id: 'search.control.cancel',
        defaultMessage: 'Отмена',
    },
    controlShowMore: {
        id: 'search.control.show_more',
        defaultMessage: 'Показать еще',
    },
    groupLabelTeams: {
        id: 'search.group.label.teams',
        defaultMessage: 'Команды',
    },
    groupLabelPlayers: {
        id: 'search.group.label.players',
        defaultMessage: 'Игроки',
    },
    groupLabelGames: {
        id: 'search.group.label.games',
        defaultMessage: 'Игры',
    },
    groupLabelMedia: {
        id: 'search.group.label.media',
        defaultMessage: 'Медиа',
    },
    placeholderInput: {
        id: 'search.placeholder.input',
        defaultMessage: 'Поиск',
    },
    placeholderNotFoundTitle: {
        id: 'search.placeholder.not_found.title',
        defaultMessage: 'Ничего не найдено',
    },
    placeholderNotFoundDescription: {
        id: 'search.placeholder.not_found.description',
        defaultMessage: 'Измените поисковый запрос или воспользуйтесь фильтрами в каталоге',
    },
    dlc: {
        id: 'search.label.dlc',
        defaultMessage: 'Дополнение',
    },
    bundle: {
        id: 'search.label.bundle',
        defaultMessage: 'Набор',
    },
    demo: {
        id: 'search.label.demo',
        defaultMessage: 'Есть демо',
    },
    bought: {
        id: 'search.price.bought',
        defaultMessage: 'Приобретено',
    },
    releaseSoon: {
        id: 'search.price.release_soon',
        defaultMessage: 'Скоро релиз',
    },
    free: {
        id: 'search.price.free',
        defaultMessage: 'Бесплатно',
    },
    emptyTitle: {
        id: 'search.empty.title',
        defaultMessage: 'Игры ближе, чем кажутся',
    },
    emptyDescr: {
        id: 'search.empty.descr',
        defaultMessage: 'Введите что-нибудь, чтобы начать поиск',
    },
});

export default messages;
