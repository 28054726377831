import React, { useEffect, useRef } from 'react';

import { Button, Icon } from '@vkplay/ui';
import cn from 'classnames';

import styles from './Dialog.module.css';

import type { DialogProps } from './types';

function Dialog(props: DialogProps) {
    const {
        open,
        onClose,
        className,
    } = props;

    const dialogRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (open) {
            dialogRef.current?.showModal();
        } else if (dialogRef.current?.close) {
            dialogRef.current.close();
        }
    }, [open]);

    function handleClose() {
        if (dialogRef.current?.close) {
            dialogRef.current.close();
        }

        onClose();
    }

    if (props.type === 'base') {
        return (
            <dialog
                ref={dialogRef}
                onCancel={handleClose}
                className={cn(styles.dialog, className)}
            >
                <div className={styles.base}>
                    <h4 className={styles.header}>
                        {props.header}
                    </h4>

                    <p className={styles.content}>
                        {props.content}
                    </p>

                    <div className={styles.actions}>
                        <Button
                            onClick={onClose}
                            mode="link"
                        >
                            {props.closeBtn}
                        </Button>

                        <Button onClick={props.onSuccess}>
                            {props.successBtn}
                        </Button>
                    </div>

                    <Icon
                        name="close"
                        className={styles.closeIcon}
                        onClick={onClose}
                    />
                </div>
            </dialog>
        );
    }

    return (
        <dialog
            ref={dialogRef}
            onCancel={handleClose}
            className={cn(styles.dialog, className)}
        >
            {props.children}
        </dialog>
    );
}

export default Dialog;
