import { useAnalyticsMethods } from '@vkplay/analytics';

import type {
    SearchEventItemClick, SearchEventMoreClick, SearchEventSearch,
    SearchEvent,
    BookmarksEvent,
} from './types';

const useAnalytics = () => {
    const { pushReachGoal } = useAnalyticsMethods();

    // Поиск. Клик по поиску
    const handleClickSearch = () => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-search',
            },
        });
    };

    // Поиск. Отправка поискового запроса
    const handleSearch = ({ query, isFound }: SearchEventSearch['params']) => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'search',
                label: query,
                other: isFound,
            },
        });
    };

    // Поиск. Клик по ссылке в выдаче
    const handleClickSearchItem = ({
        query, url, section, index,
    }: SearchEventItemClick['params']) => {
        pushReachGoal({
            params: {
                category: 'search',
                action: 'click',
                label: query,
                entity_id: url,
                other: section,
                value: index,
            },
        });
    };

    // Поиск. Клик по Показать ещё
    const handleClickShowMore = ({ query, section }: SearchEventMoreClick['params']) => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'search_more_click',
                label: query,
                other: section,
            },
        });
    };

    /* const handleClickOpenNotifications = () => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-notifications',
            },
        });
    }; */

    // Нотификации. Клик на Отметить все прочитанным
    // Повесить на кнопку "Ответить все прочитанным", когда та появится
    // const handleClickReadAllNotifications = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'notifications',
    //             action: 'click',
    //             label: 'read_all',
    //         },
    //     });
    // };

    // Нотификации. Клик на Уведомление
    // Повесить на клик по уведомлению
    // YYY – url уведомления
    // ZZZ – расположение уведомления (попап, внутри колокольчика)
    // const handleClickNotificationItem = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'notifications',
    //             action: 'click',
    //             label: 'YYY',
    //             entity_id: 'ZZZ',
    //         },
    //     });
    // };

    // Нотификации. Показ всплывающего окна
    // Повесить на показ попапа с уведомлением снизу
    // Или на показ попапа с нотификациями ??? Уточнить у https://home.vk.team/users/r.alekseenko
    // const handleShowNotificationPopup = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'notifications',
    //             action: 'show',
    //         },
    //     });
    // };

    // Нотификации. Закрытие всплывающего окна
    // Повесить на клик кнопке закрытия попапа с уведомлением снизу
    // Или на закрытие попапа с нотификациями ??? Уточнить у https://home.vk.team/users/r.alekseenko
    // const handleClickNotificationPopupClose = () => {
    //     pushReachGoal({
    //         params: {
    //             category: "notifications",
    //             action: "close",
    //         },
    //     });
    // };

    // Нотификации. Клик на Три точки в уведомлении.
    // Повесить на клик кнопке контекстного меню на уведомлении
    // YYY – расположение уведомления (попап, внутри колокольчика)
    // ZZZ – url уведомления
    // const handleClickNotificationContextMenu = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'notifications',
    //             action: 'menu_click',
    //             label: 'YYY',
    //             entity_id: 'ZZZ',
    //         },
    //     });
    // };

    // Нотификации. Клик на Не показывать уведомления проекта.
    // Повесить на клик по кнопке "Не показывать уведомления проекта" контекстного меню
    // YYY – расположение уведомления (попап, внутри колокольчика)
    // ZZZ – url уведомления
    // const handleClickHideNotification = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'notifications',
    //             action: 'menu_hide_click',
    //             label: 'YYY',
    //             entity_id: 'ZZZ',
    //         },
    //     });
    // };

    // Нотификации. Клик на "Удалить уведомление".
    // Повесить на клик по кнопке "Удалить уведомление" контекстного меню
    // YYY – расположение уведомления (попап, внутри колокольчика)
    // ZZZ – url уведомления
    // const handleClickDeleteNotification = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'notifications',
    //             action: 'menu_delete_click',
    //             label: 'YYY',
    //             entity_id: 'ZZZ',
    //         },
    //     });
    // };

    // Избранное. Клик на кнопку
    const handleClickBookmark = () => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-library',
            },
        });
    };

    // Избранное. Клик по элементу
    // Повесить на клик по элементу в закладках
    // YYY – раздел (Игры, Медиа, Стримы)
    // ZZZ – id сущности (можем поменять на url, по которому кликают)
    // const handleClickBookmarkItem = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'library',
    //             action: 'click',
    //             label: 'YYY',
    //             entity_id: 'ZZZ',
    //         },
    //     });
    // };

    // Избранное. Клик на "Смотреть всё"
    // Повесить на клик по элементу в закладках
    // const handleClickShowBookmarks = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'library',
    //             action: 'view_all_click',
    //         },
    //     });
    // };

    // Избранное. Клик на "Перейти в каталог"
    // Повесить на клик по кнопке "Перейти в каталог"
    // const handleClickCatalogAnchor = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'library',
    //             action: 'goto_catalog_click',
    //         },
    //     });
    // };

    // Избранное. Клик на "Убрать из избранного"
    // Повесить на клик по кнопке "Убрать из избранного"
    // YYY – раздел (Игры, Медиа, Стримы)
    // ZZZ – id сущности (можем поменять на url, по которому кликают)
    // const handleClickRemoveBookmark = () => {
    //     pushReachGoal({
    //         params: {
    //             category: 'library',
    //             action: 'delete_click',
    //             label: 'YYY',
    //             entity_id: 'ZZZ',
    //         },
    //     });
    // };

    const handleSearchEvents: SearchEvent = ({ type, params }) => {
        if (type === 'search') {
            handleSearch(params);
        } else if (type === 'search-click') {
            handleClickSearch();
        } else if (type === 'item-click') {
            handleClickSearchItem(params);
        } else if (type === 'more-click') {
            handleClickShowMore(params);
        }
    };

    const handlBookmarksEvents: BookmarksEvent = ({ type }) => {
        if (type === 'bookmarks-click') {
            handleClickBookmark();
        }
    };

    return {
        handleSearchEvents,
        handlBookmarksEvents,
    };
};

export default useAnalytics;
