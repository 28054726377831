import React from 'react';

import { Icon } from '@vkplay/ui';

import styles from './Checkbox.module.css';

import type { SyntheticEvent } from 'react';

type Props = {
    checked: boolean,
    onChange: (value: boolean) => void,
    label?: string,
    labelFor?: string,
}

function Checkbox({
    checked,
    onChange,
    label,
    labelFor,
}: Props) {
    const handleInputChange = (event: SyntheticEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.checked);
    };

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className={styles.label}>
            <input
                className={styles.checkbox}
                type="checkbox"
                id={labelFor}
                onChange={handleInputChange}
                checked={checked}
            />

            <Icon name="checked" className={styles.checked} />

            <span className={styles.text}>{label}</span>
        </label>
    );
}

export default Checkbox;
