import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    scroll_top: {
        id: 'button.scroll_top',
        defaultMessage: 'Подняться вверх',
    },
});

export default messages;
