import React, {
    useState, useEffect, useCallback, useContext, type FC, type MouseEvent,
} from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Backend, triggerPixel } from '@vkplay/shared';
import { Button, Icon, useBreakpoints } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import SessionContext from '@context/SessionContext';
import Loader from '@kit/Loader';
import Portal from '@kit/Portal';
import Tooltip from '@kit/Tooltip';
import { showLogin } from '@services/auth';
import { Urls } from '@services/hosts';

import {
    getStorageSteamRefillLogin,
    hasRefillParam,
    makeUrlWithParam,
    removeStorageSteamRefillLogin,
    setStorageSteamRefillLogin,
    STATS_VALUE,
    updateUrlParam,
} from './helpers';
import messages from './messages';
import styles from './SteamRefill.module.css';

import type { CkassaResponseType } from './types';

const SteamRefill: FC = () => {
    const { pushReachGoal } = useAnalyticsMethods();
    const [ifrUrl, setIfrUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const { session: { session }, loaded: sessionLoaded } = useContext(SessionContext);
    const { tabletMdMax } = useBreakpoints();
    const { formatMessage } = useIntl();
    const steamMobUrl = makeUrlWithParam();

    const getFrameUrl = async () => {
        setLoading(true);

        try {
            const response: CkassaResponseType = await Backend.post(Urls.ckassa);

            setIfrUrl(response.ifr_url);
            setLoading(false);
        } catch (error) {
            console.warn(error);
            setLoading(false);
        }
    };

    const getRefillUrl = useCallback((noAuthCheck?: boolean) => {
        if (loading) {
            return;
        }

        if (sessionLoaded && session?.user_id) {
            getFrameUrl();
            removeStorageSteamRefillLogin();
        } else if (!noAuthCheck) {
            // Не поднимать окно авторизации при загрузке страницы, если уже есть флаг в ls (noAuthCheck)
            if (!getStorageSteamRefillLogin()) {
                setStorageSteamRefillLogin();
            }

            showLogin();
        }
    }, [sessionLoaded, session, loading]);

    const handleIframeMessage = (e: MessageEvent) => {
        if (e.origin.includes('ckassa') && e.data === 'close') {
            setIfrUrl('');
            // Убрать параметр статистики из урла
            updateUrlParam(true);
        }
    };

    const handleBtnClick = (e: MouseEvent) => {
        pushReachGoal({
            params: {
                category: 'steam_wallet',
                action: 'click',
            },
        });

        if (!tabletMdMax) {
            e.preventDefault();
            getRefillUrl();
            triggerPixel(`https://trk.mail.ru/c/${STATS_VALUE}`);
        }
    };

    useEffect(() => {
        const hasStorageValue = !!getStorageSteamRefillLogin();

        if (hasRefillParam || hasStorageValue) {
            updateUrlParam();

            // если есть флаг в ls и нет параметра в урле, то ставим noAuthCheck
            const noAuthCheck = hasStorageValue && !hasRefillParam;

            getRefillUrl(noAuthCheck);
        }

        window.addEventListener('message', handleIframeMessage);

        return (() => {
            window.removeEventListener('message', handleIframeMessage);
        });
    }, []);

    return (
        <>
            <Button
                component="a"
                className={styles.steamBtn}
                appearance="neutral"
                onClick={handleBtnClick}
                href={steamMobUrl}
                target="_blank"
                {...(tabletMdMax ? {
                    mode: 'outline',
                } : {
                    'data-tooltip-id': 'steam-btn',
                    'data-tooltip-position-strategy': 'fixed',
                })}
            >
                <span className={styles.icon}>
                    <Icon name="wallet-outline" />
                </span>
                <span className={styles.label}>{formatMessage(messages.btnName)}</span>

                <Tooltip
                    id="steam-btn"
                    content={formatMessage(messages.btnTooltip)}
                    className={styles.tooltip}
                />
            </Button>

            {(loading || ifrUrl)
            && (
                <Portal into="body">
                    <div className={styles.popup}>
                        <Loader className={styles.loader} />
                        {ifrUrl
                        && (
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            // eslint-disable-next-line react/no-unknown-property,max-len
                            <iframe src={ifrUrl} frameBorder="0" width="100%" height="100%" allowPaymentRequest title="Steam" />
                        )}
                    </div>
                </Portal>
            )}
        </>
    );
};

export default SteamRefill;
