import React, { createContext, useContext, useMemo } from 'react';

import type { SearchContextOptions, SearchProviderProps } from '@components/Search/types';
import type { FC } from 'react';

const SearchContext = createContext<SearchContextOptions>({
    size: 'default',
});

export const SearchProvider: FC<SearchProviderProps> = ({
    size = 'default',
    children,
}) => {
    const value = useMemo(() => ({
        size,
    }), [size]);

    return (
        <SearchContext.Provider value={value}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => {
    const context = useContext(SearchContext);

    if (context === undefined) {
        throw new Error('useSearch must be used within a SearchProvider');
    }

    return context;
};
