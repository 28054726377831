import React, { useCallback, useState } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Icon, WrapperButton } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import WishlistOverlay from '@components/Wishlist/Overlay/WishlistOverlay';

import styles from './MobOverlay.module.css';
import messages from '../../navigation/messages';

function WishlistMenuItem() {
    const { pushReachGoal } = useAnalyticsMethods();
    const { formatMessage } = useIntl();
    const [mobileOverlay, setMobileOverlay] = useState(false);

    const handleCloseMobileOverlay = useCallback(() => {
        setMobileOverlay(false);
    }, []);

    const handleOpenMobileOverlay = useCallback(() => {
        setMobileOverlay(true);

        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-library',
            },
        });
    }, []);

    return (
        <>
            <WrapperButton
                className={cn(styles.menuItem, styles.transparent)}
                onClick={handleOpenMobileOverlay}
            >
                <div className={styles.btnIcon}>
                    <Icon name="bookmark-outline" />
                </div>
                <div className={styles.btnText}>
                    {formatMessage(messages.favorites)}
                </div>
            </WrapperButton>

            <WishlistOverlay
                onClose={handleCloseMobileOverlay}
                open={mobileOverlay}
            />
        </>
    );
}

export default WishlistMenuItem;
