import React, { useState, useEffect, type FC } from 'react';

import { Dialog } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import { Urls } from '@services/hosts';
import { SHOW_STEAM_ERROR_EVENT } from '@services/showSteamError';

import messages from './messages';

const SteamError: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { formatMessage } = useIntl();

    const showDialog = () => {
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const onLogout = () => {
        window.location.href = Urls.logout;
    };

    useEffect(() => {
        window.addEventListener(SHOW_STEAM_ERROR_EVENT, showDialog);

        return (() => {
            window.removeEventListener(SHOW_STEAM_ERROR_EVENT, showDialog);
        });
    }, []);

    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            type="dialog"
            title={formatMessage(messages.steamErrorDialog)}
            modalOptions={{
                description: `${formatMessage(messages.steamErrorTitle)} ${formatMessage(messages.steamErrorText)}`,
                successText: formatMessage(messages.logout),
                noSuccessText: formatMessage(messages.close),
                onSuccess: onLogout,
                isSuccessAccent: true,
            }}
            zIndex={1000000}
        />
    );
};

export default SteamError;
