import React, {
    createContext, type FC,
} from 'react';

import { SessionProvider } from '@context/SessionContext';

import type { AppShellConfig } from '@/types';
import type { AppProps } from '@context/types';

const AppContext = createContext<AppShellConfig>({});

export const AppProvider: FC<AppProps> = ({ config, children }) => {
    const { user, onGetUser } = config;

    return (
        <AppContext.Provider value={config}>
            <SessionProvider
                {...(user && { user })}
                {...(onGetUser && { onGetUser })}
                config={config}
            >
                {children}
            </SessionProvider>
        </AppContext.Provider>
    );
};

export default AppContext;
