import { Backend } from '@vkplay/shared';

import GameCenter from '@services/gcdapi';
import { Urls } from '@services/hosts';
import { showDownloadHint } from '@services/showDownloadHint';

type OptsType = {
    isFlash?: boolean;
    adParams?: string;
};

interface GcApiType {
    detectGameCenter: (timeout?: number) => void;
    downloadGame: (
        gcId: string,
        gcDownloadUrl?: string,
        opts?: OptsType,
    ) => void;
    detectAndDownload: (
        gcId: string,
        gcDownloadUrl?: string,
        timeout?: number,
        opts?: OptsType,
    ) => void;
}

interface GlobalGameCenter {
    init: (
        holder: HTMLDivElement,
        dispatchGcEvents: (jobj: { Method: string; }) => void,
        onInit: () => void,
        onConnect: () => void,
        onDisconnect: () => void,
        gcVersion: number,
    ) => void;
    wsTunEnabled: number;
    setAuthCode: (code: string) => void;
    playGame: (
        typeId: number,
        projectId: number,
        startDownload?: 0 | 1,
        adParams?: string,
    ) => void;
    showGame: (
        typeId: number,
        projectId: number,
        startDownload?: 0 | 1,
        adParams?: string,
    ) => void;
}

class GcApi implements GcApiType {
    private readonly timeout: number;

    private sessionChecked: boolean | undefined;

    private gcDetected: boolean | undefined;

    private toHandle: ReturnType<typeof setTimeout> | number | undefined;

    constructor() {
        this.timeout = 3;
        this.gcDetected = undefined;
        this.sessionChecked = undefined;
        this.toHandle = undefined;
        window.GameCenter = GameCenter;
    }

    detectGameCenter = (timeout?: number) => {
        const gcVersion = 0;

        return new Promise<void>((resolve, reject) => {
            if (typeof (this.gcDetected) !== 'undefined') {
                if (this.gcDetected) {
                    resolve();
                } else {
                    reject();
                }
            }

            const doDetect = () => {
                const minTimeout = timeout && timeout < 2 ? 2 : timeout;
                const timedOut = minTimeout || this.timeout;

                const onConnect = () => {
                    this.gcDetected = true;
                    clearTimeout(this.toHandle);
                    resolve();
                };

                const onDisconnect = () => {
                    this.gcDetected = false;
                    clearTimeout(this.toHandle);
                    reject();
                };

                const onTimeout = () => {
                    this.gcDetected = false;
                    clearTimeout(this.toHandle);
                    reject();
                };

                const onInit = () => {
                    clearTimeout(this.toHandle);
                };

                const ensureAuth = () => new Promise<void>((onResolve, onReject) => {
                    if (this.sessionChecked) {
                        onResolve();
                    } else {
                        Backend.get(Urls.get_user).then(() => {
                            this.sessionChecked = true;
                            onResolve();
                        }).catch((error) => {
                            onReject(error);
                        });
                    }
                });

                function dispatchGcEvents(jobj: { Method: string; }) {
                    if (jobj.Method === 'GetAuthCode') {
                        const url = Urls.get_gc_auth;

                        ensureAuth().then(() => {
                            Backend.get(url).then((data) => {
                                if (!data.code || !data.code.length) {
                                    return;
                                }

                                window.GameCenter.setAuthCode(data.code);
                            });
                        });
                    }
                }

                if (!this.gcDetected) {
                    this.toHandle = setTimeout(() => { onTimeout(); }, timedOut * 1000);
                }

                const $holder: HTMLDivElement = document.createElement('div');

                $holder.style.width = '0';
                $holder.style.height = '0';
                $holder.style.visibility = 'hidden';

                document.body.appendChild($holder);

                window.GameCenter.wsTunEnabled = 1;
                window.GameCenter.init($holder, dispatchGcEvents, onInit, onConnect, onDisconnect, gcVersion);
            };

            if (window.GameCenter) {
                doDetect();
            }
        });
    };

    detectAndDownload = (
        gcId: string,
        gcDownloadUrl?: string,
        timeout?: number,
        opts?: OptsType,
    ) => {
        this.detectGameCenter(timeout)
            .then(() => {
                this.downloadGame(gcId, gcDownloadUrl, opts);
            })
            .catch((e) => {
                console.warn(e);
                this.downloadGame(gcId, gcDownloadUrl, opts);
            });
    };

    downloadGame = (gcId: string, gcDownloadUrl?: string, opts?: OptsType) => {
        const gameGcId = gcId || '';
        let gameGcDownloadUrl = gcDownloadUrl || 'https://static.gc.vkplay.ru/VKPlayLoader.exe';

        if (!gameGcDownloadUrl) {
            throw new Error('No gc_download_url in downloadGame');
        }

        if (this.gcDetected && gameGcId) {
            const pair = gameGcId.split('.');
            const typeId = Number(pair[0]);
            const gameId = Number(pair[1]);
            const isFlash = opts?.isFlash;
            const adParams = opts?.adParams;

            if (Number.isNaN(typeId) || Number.isNaN(gameId)) {
                throw new Error('Invalid gc_id');
            }

            if (isFlash) {
                window.GameCenter.playGame(typeId, gameId, 1);

                return true;
            }

            window.GameCenter.showGame(typeId, gameId, 1, adParams);

            return true;
        }

        setTimeout(() => {
            showDownloadHint();
        }, 300);

        const downloadFrame = document.createElement('iframe');

        if (!gameGcDownloadUrl.match(/^https:/)) {
            console.error(`download_url should be https: ${gameGcDownloadUrl}`);
            gameGcDownloadUrl = gameGcDownloadUrl.replace(/^\w+:/, 'https:');
        }

        downloadFrame.src = `${gameGcDownloadUrl}#${Math.random()}`;
        downloadFrame.style.visibility = 'hidden';
        downloadFrame.style.width = '0px';
        downloadFrame.style.height = '0px';
        downloadFrame.style.left = '0px';
        downloadFrame.style.bottom = '0px';
        downloadFrame.style.position = 'absolute';
        document.body.appendChild(downloadFrame);

        return true;
    };
}

export default GcApi;

declare global {
    interface Window {
        GameCenter: GlobalGameCenter;
    }
}
