import { query_string, getCookie } from '@vkplay/shared';

import FlagEn from '@assets/img/flag_en.svg';
import FlagRu from '@assets/img/flag_ru.svg';

import type { LangsAvailable } from '@/types';
import type { LangsListType } from '@context/types';

type LangParamObjectType = {
    lang?: LangsAvailable;
}

const qs: LangParamObjectType = query_string();

export const DEFAULT_LANGS_AVAILABLE = ['ru_RU', 'en_US'];

export const QS_LANG = qs.lang;

export const LANGS: Record<string, Record<string, string>> = {
    ru_RU: {
        label: 'Русский язык',
        name: 'Русский',
        flag: FlagRu,
        analyticsLabel: 'ru',
    },
    en_US: {
        label: 'English',
        name: 'English',
        flag: FlagEn,
        analyticsLabel: 'en',
    },
};

export const getLangsList = (list: string[]) => Object.keys(LANGS).reduce((acc: LangsListType, key: string) => {
    if (list.includes(key)) {
        acc[key] = LANGS[key];
    }

    return acc;
}, {});

export default getCookie('amc_lang') || 'ru_RU';
