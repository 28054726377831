import React from 'react';

import { Button, Icon } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import styles from '@components/TopNav/Notifications/Mobile/MobileNotifications.module.css';
import messages from '@navigation/messages';

import type { WishlistOverlayHeaderProps } from '../types';

function WishlistOverlayHeader({
    onClose,
    count,
}: WishlistOverlayHeaderProps) {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.header}>
            <Button
                onClick={onClose}
                leftIcon={<Icon name="chevron-left" />}
                appearance="overlay"
                mode="link"
                className={styles.headerButton}
                size="sm"
            />

            <span className={styles.title}>
                <span className={styles.notify}>
                    {formatMessage(messages.favorites)}

                    {count > 0 && (
                        <span className={styles.sub}>
                            {count}
                        </span>
                    )}
                </span>
            </span>

            <Button
                onClick={() => {}}
                className={cn(styles.headerButton, styles.hidden)}
                leftIcon={<Icon name="list-check-outline" />}
                appearance="overlay"
                mode="link"
                size="sm"
            />
        </div>
    );
}

export default WishlistOverlayHeader;
