import React, { createContext, useEffect, useState } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { inGamecenter, WSD } from '@vkplay/shared';

import {
    checkVisibility,
    getCount,
    getNotifications,
    handleReadNotification,
    readAllNotifications,
} from '@components/TopNav/Notifications/helpers';

import { NotificationTemplate } from '../types';

import type { NotificationsContextState, NotificationItem, NotificationsContextProps } from '../types';

const NotificationsContext = createContext<NotificationsContextState>({
    list: [],
    unread: 0,
    loading: true,
    nextPage: null,
    needUpdate: false,
    onReadNotification: () => {},
    onCheckAll: () => {},
    onDeleteNotification: () => {},
    onOpenNotification: () => {},
});

export const NotificationsContextProvider = ({
    children,
}: NotificationsContextProps) => {
    const { pushReachGoal } = useAnalyticsMethods();
    const [list, setList] = useState<Array<NotificationItem>>([]);
    const [unread, setUnread] = useState(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [nextPage, setNextPage] = useState(null);
    const [needUpdate, setNeedUpdate] = useState(false);

    useEffect(() => {
        if (inGamecenter) {
            window.gc_window_state = (state: number) => {
                if (state === 3 && needUpdate) {
                    loadNotification();
                }
            };
        }
    }, [needUpdate]);

    useEffect(() => {
        if (inGamecenter && window.gc_set_notifications_unread_count) {
            window.gc_set_notifications_unread_count(unread);
        }
    }, [unread]);

    const loadCount = async () => {
        const updatedUnread = await getCount();
        setUnread(updatedUnread);
    };

    async function loadNotification() {
        const updatedUnread = await getCount();
        const { notifications, pager } = await getNotifications(1 || nextPage);

        if (Array.isArray(notifications)) {
            setList(notifications);
        }

        setLoading(false);

        if (pager) {
            const { next_page, page } = pager;
            setNextPage(next_page !== page ? next_page : null);
        }

        setUnread(updatedUnread);
        setNeedUpdate(false);
    }

    useEffect(() => {
        WSD.on('message', (message: NotificationItem) => {
            if (message.type === 'notification_mygames' && checkVisibility(message.visibility)) {
                setList((prevState) => [message, ...prevState]);
                setNeedUpdate(true);
                loadCount();
            }

            if (message.type === 'notification_update' && checkVisibility(message.visibility)) {
                if (![
                    NotificationTemplate.RequestAccepted,
                    NotificationTemplate.RequestDeclined,
                ].includes(message.template_id)) {
                    setList((prevState) => prevState.map((prev) => {
                        if (prev.id === message.id) {
                            return message;
                        }

                        return prev;
                    }));
                }

                setNeedUpdate(true);
            }
        });

        loadNotification();
    }, []);

    const onOpenNotification = () => {
        if (needUpdate) {
            loadNotification();
        }
    };

    const handleCheckAll = async () => {
        if (unread === 0) return;

        await readAllNotifications();

        setList((prevState) => prevState.map((item) => {
            item.is_read = true;

            return item;
        }));
        setUnread(0);

        pushReachGoal({
            params: {
                category: 'notifications',
                action: 'click',
                label: 'read_all',
            },
        });
    };

    const onReadNotification = async (id: string) => {
        await handleReadNotification(id);

        setList((prevState) => prevState.map((item) => {
            if (item.id === id) {
                item.is_read = true;
            }

            return item;
        }));

        setUnread((prevState) => Math.max(prevState - 1, 0));
    };

    const onDeleteNotification = () => {
        setNeedUpdate(true);
    };

    return (
        <NotificationsContext.Provider
            value={{
                list,
                unread,
                loading,
                nextPage,
                needUpdate,
                onReadNotification,
                onDeleteNotification,
                onOpenNotification,
                onCheckAll: handleCheckAll,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export default NotificationsContext;
