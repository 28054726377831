/* eslint-disable */
// @ts-nocheck

import { DetectType } from '@components/GCDownloadHint/types';

const BrowserDetect: DetectType = {
    detect: function () {
        if (!Array.prototype.map) {
            // eslint-disable-next-line no-extend-native
            Array.prototype.map = function (callback, thisArg) {
                let T;
                let A;
                let k;

                if (this == null) {
                    throw new TypeError(' this is null or not defined');
                }

                const O = Object(this);
                const len = O.length >>> 0;

                if (typeof callback !== 'function') {
                    throw new TypeError(`${callback} is not a function`);
                }

                if (thisArg) {
                    T = thisArg;
                }

                A = new Array(len);
                k = 0;
                while (k < len) {
                    let kValue;
                    let mappedValue;

                    if (k in O) {
                        kValue = O[k];
                        mappedValue = callback.call(T, kValue, k, O);
                        A[k] = mappedValue;
                    }

                    k++;
                }

                return A;
            };
        }

        const _this = function () {};

        const regexes = {
            browser_parsers: [{
                regex: '(OPR)/(\\d+)\\.(\\d+)\\.(\\d+)',
                family_replacement: 'Opera',
            }, {
                regex: '(YaBrowser)/(\\d+)\\.(\\d+)\\.(\\d+)',
            }, {
                regex: '(Edge)/(\\d+)\\.(\\d+)',
                family_replacement: 'Edge',
            }, {
                regex: '(Edg)/(\\d+)\\.(\\d+)',
                family_replacement: 'Edge',
            }, {
                regex: '(Namoroka|Shiretoko|Minefield)/(\\d+)\\.(\\d+)\\.(\\d+(?:pre)?)',
                family_replacement: 'Firefox ($1)',
            }, {
                regex: '(Firefox)/(\\d+)\\.(\\d+)(a\\d+[a-z]*)',
                family_replacement: 'Firefox Alpha',
            }, {
                regex: '(Firefox)/(\\d+)\\.(\\d+)(b\\d+[a-z]*)',
                family_replacement: 'Firefox Beta',
            }, {
                regex: '(Firefox)-(?:\\d+\\.\\d+)?/(\\d+)\\.(\\d+)(a\\d+[a-z]*)',
                family_replacement: 'Firefox Alpha',
            }, {
                regex: '(Firefox)-(?:\\d+\\.\\d+)?/(\\d+)\\.(\\d+)(b\\d+[a-z]*)',
                family_replacement: 'Firefox Beta',
            }, {
                regex: '(Namoroka|Shiretoko|Minefield)/(\\d+)\\.(\\d+)([ab]\\d+[a-z]*)?',
                family_replacement: 'Firefox ($1)',
            }, {
                regex: '(MozillaDeveloperPreview)/(\\d+)\\.(\\d+)([ab]\\d+[a-z]*)?',
            }, {
                regex: '(Firefox)/(\\d+)\\.(\\d+)\\.(\\d+(?:pre)?) \\(Swiftfox\\)',
                family_replacement: 'Swiftfox',
                other: true,
            }, {
                regex: '(Firefox)/(\\d+)\\.(\\d+)([ab]\\d+[a-z]*)? \\(Swiftfox\\)',
                family_replacement: 'Swiftfox',
                other: true,
            }, {
                regex: '(AdobeAIR|Chromium|FireWeb|Jasmine|ANTGalio|Midori|Fresco|Lobo|PaleMoon|Maxthon|Lynx|OmniWeb|Dillo|Camino|Demeter|Fluid|Fennec|Shiira|Sunrise|Chrome|Flock|Netscape|Lunascape|WebPilot|NetFront|Netfront|Konqueror|SeaMonkey|Kazehakase|Vienna|Iceape|Iceweasel|IceWeasel|Iron|K-Meleon|Sleipnir|Galeon|GranParadiso|Opera Mini|iCab|NetNewsWire|ThunderBrowse|Iron|Iris|UP\\.Browser|Bunjaloo|Google Earth|Raven for Mac)/(\\d+)\\.(\\d+)\\.(\\d+)',
            }, {
                regex: '(Edge|Bolt|Jasmine|IceCat|Skyfire|Midori|Maxthon|Lynx|Arora|IBrowse|Dillo|Camino|Shiira|Fennec|Phoenix|Chrome|Flock|Netscape|Lunascape|Epiphany|WebPilot|Opera Mini|Opera|NetFront|Netfront|Konqueror|Googlebot|SeaMonkey|Kazehakase|Vienna|Iceape|Iceweasel|IceWeasel|Iron|K-Meleon|Sleipnir|Galeon|GranParadiso|iCab|NetNewsWire|Iron|Space Bison|Stainless|Orca|Dolfin|BOLT|Minimo|Tizen Browser|Polaris)/(\\d+)\\.(\\d+)',
            }, {
                regex: '(iRider|Crazy Browser|SkipStone|iCab|Lunascape|Sleipnir|Maemo Browser) (\\d+)\\.(\\d+)\\.(\\d+)',
            }, {
                regex: '(iCab|Lunascape|Opera|Android|Jasmine|Polaris|BREW) (\\d+)\\.(\\d+)\\.?(\\d+)?',
            }, {
                regex: '(MSIE) (\\d+)\\.(\\d+).*XBLWP7',
                family_replacement: 'IE Large Screen',
            }, {
                regex: '(Firefox)/(\\d+)\\.(\\d+)\\.(\\d+)',
            }, {
                regex: '(Firefox)/(\\d+)\\.(\\d+)(pre|[ab]\\d+[a-z]*)?',
            }, {
                regex: '(AppleWebKit)/(\\d+)\\.?(\\d+)?\\+ .* Version/\\d+\\.\\d+.\\d+ Safari/',
                family_replacement: 'WebKit Nightly',
            }, {
                regex: '(Version)/(\\d+)\\.(\\d+)(?:\\.(\\d+))?.*Safari/',
                family_replacement: 'Safari',
            }, {
                regex: '(Safari)/\\d+',
            }, {
                regex: 'Trident(.*)rv.(\\d+)\\.(\\d+)',
                family_replacement: 'IE',
            }, {
                regex: '(MSIE) (\\d+)\\.(\\d+)',
                family_replacement: 'IE',
            }],
            os_parsers: [{
                regex: '(Windows (?:NT 5\\.2|NT 5\\.1))',
                os_replacement: 'Windows XP',
            }, {
                regex: '(Windows NT 6\\.1)',
                os_replacement: 'Windows 7',
            }, {
                regex: '(Windows NT 6\\.0)',
                os_replacement: 'Windows Vista',
            }, {
                regex: '(Windows 98|Windows XP|Windows ME|Windows 95|Windows CE|Windows 7|Windows NT 4\\.0|Windows Vista|Windows 2000)',
            }, {
                regex: '(Windows NT 6\\.2)',
                os_replacement: 'Windows 8',
            }, {
                regex: '(Windows NT 5\\.0)',
                os_replacement: 'Windows 2000',
            }, {
                regex: '(WinNT4.0)',
                os_replacement: 'Windows NT 4.0',
            }, {
                regex: '(Win98)',
                os_replacement: 'Windows 98',
            }, {
                regex: '(Mac OS X) (\\d+)[_.](\\d+)(?:[_.](\\d+))?',
                manufacturer: 'Apple',
            }, {
                regex: '(?:PPC|Intel) (Mac OS X)',
                manufacturer: 'Apple',
            }],
            mobile_os_families: [],
            device_parsers: [],
            mobile_browser_families: [],
        };

        _this.parsers = ['device_parsers', 'browser_parsers', 'os_parsers', 'mobile_os_families', 'mobile_browser_families'];
        _this.types = ['browser', 'os', 'device'];
        _this.regexes = regexes || (function () {
            const results = {};

            _this.parsers.map((parser) => {
                results[parser] = [];
            });

            return results;
        }());
        _this.families = (function () {
            const results = {};

            _this.types.map((type) => {
                results[type] = [];
            });

            return results;
        }());
        const ArrayProto = Array.prototype;
        const nativeForEach = ArrayProto.forEach;

        const find = function (ua: string, obj: {
            family: string;
            major: number | null;
            minor: number | null;
            patch: number | null;
            tablet: any;
            man: string;
        }) {
            let ret = {};

            for (let i = 0; i < obj.length; i++) {
                ret = obj[i](ua);

                if (ret) {
                    break;
                }
            }

            return ret;
        };

        const remove = function (arr: [], props: string[]) {
            each(arr, (obj) => {
                each(props, (prop: string) => {
                    delete obj[prop];
                });
            });
        };

        let forEach;

        const each = forEach = function (obj: any, iterator: any, context?: any) {
            if (obj == null) return;

            if (nativeForEach && obj.forEach === nativeForEach) {
                obj.forEach(iterator, context);
            } else if (obj.length === +obj.length) {
                for (let i = 0, l = obj.length; i < l; i++) {
                    iterator.call(context, obj[i], i, obj);
                }
            } else {
                for (const key in obj) {
                    // eslint-disable-next-line no-undef
                    if (_.has(obj, key)) {
                        iterator.call(context, obj[key], key, obj);
                    }
                }
            }
        };

        const check = function (str: string) {
            return !!(str && typeof str !== 'undefined' && str != null);
        };

        const toVersionString = function (obj: any) {
            let output = '';

            obj = obj || {};

            if (check(obj)) {
                if (check(obj.major)) {
                    output += obj.major;

                    if (check(obj.minor)) {
                        output += `.${obj.minor}`;

                        if (check(obj.patch)) {
                            output += `.${obj.patch}`;
                        }
                    }
                }
            }

            return output;
        };

        const toString = function (obj: any) {
            obj = obj || {};
            let suffix = toVersionString(obj);

            if (suffix) suffix = ` ${suffix}`;

            return obj && check(obj.family) ? obj.family + suffix : '';
        };

        _this.parse = function (ua: string) {
            const parsers = function (type: string) {
                return _this.regexes[`${type}_parsers`].map((obj) => {
                    const regexp = new RegExp(obj.regex);
                    const rep = obj[`${type === 'browser' ? 'family' : type}_replacement`];
                    const major_rep = obj.major_version_replacement;

                    function parser(ua: string) {
                        const m = ua.match(regexp);

                        if (!m) return null;
                        const ret: any = {};

                        ret.family = (rep ? rep.replace('$1', m[1]) : m[1]) || 'other';
                        ret.major = parseInt(major_rep || m[2]) || null;
                        ret.minor = m[3] ? parseInt(m[3]) : null;
                        ret.patch = m[4] ? parseInt(m[4]) : null;
                        ret.tablet = obj.tablet;
                        ret.man = obj.manufacturer || null;

                        return ret;
                    }

                    return parser;
                });
            };

            const UserAgent = function () {
            };

            const browser_parsers = parsers('browser');
            const os_parsers = parsers('os');
            const device_parsers = parsers('device');
            const a = new UserAgent();

            a.source = ua;
            a.browser = find(ua, browser_parsers);

            if (check(a.browser)) {
                a.browser.name = toString(a.browser);
                a.browser.version = toVersionString(a.browser);
            } else {
                a.browser = {};
            }

            a.os = find(ua, os_parsers);

            if (check(a.os)) {
                a.os.name = toString(a.os);
                a.os.version = toVersionString(a.os);
            } else {
                a.os = {};
            }

            a.device = find(ua, device_parsers);

            if (check(a.device)) {
                a.device.name = toString(a.device);
                a.device.version = toVersionString(a.device);
            } else {
                a.device = {
                    tablet: false,
                    family: 'Other',
                };
            }

            const mobile_agents = {};

            if (a.browser.family === 'Spider') {
                a.device.type = 'Spider';
            } else if (a.browser.tablet || a.os.tablet || a.device.tablet) {
                a.device.type = 'Tablet';
            } else if (mobile_agents.hasOwnProperty(a.browser.family)) {
                a.device.type = 'Mobile';
            } else {
                a.device.type = 'Desktop';
            }

            a.device.manufacturer = a.browser.man || a.os.man || a.device.man || null;
            remove([a.browser, a.os, a.device], ['tablet', 'man']);

            return a;
        };

        return _this;
    }
};

export default BrowserDetect;
