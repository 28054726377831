import React, { useContext } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Icon } from '@vkplay/ui';
import cn from 'classnames';

import AppContext from '@context/AppContext';
import { useTranslate } from '@hooks';
import { Urls } from '@services/hosts';

import styles from './Sidebar.module.css';

import type { SidebarItemMobProps } from '@components/Sidebar/types';
import type { FC, MouseEvent } from 'react';

const SidebarItemMob: FC<SidebarItemMobProps> = ({ item, onClick, openMobOverlay }) => {
    const { pushReachGoal } = useAnalyticsMethods();
    const { onSidenavClick, preset } = useContext(AppContext);
    const Component = item.href ? 'a' : 'button';
    const label = useTranslate(item.label);
    const currentUrl = `${window.location.origin}${window.location.pathname}`;
    const isMain = currentUrl === Urls.main;
    const currentPreset = isMain ? 'main' : preset;
    const currentId = openMobOverlay ? 'more' : currentPreset;

    const handleSendAnalytics = () => {
        if (!item.analyticsLabel) {
            return;
        }

        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: item.analyticsLabel,
            },
        });
    };

    const handleClick = (event: MouseEvent<HTMLAnchorElement> & MouseEvent<HTMLButtonElement>) => {
        const { id } = event.currentTarget.dataset;

        handleSendAnalytics();
        onClick?.();

        if (onSidenavClick && id) {
            onSidenavClick(event, id);
        }
    };

    return (
        <li className={styles.itemMob}>
            <Component
                className={cn(styles.itemLinkMob, {
                    [styles.current]: currentId === item.id,
                })}
                {...(item.href && {
                    href: item.href,
                })}
                onClick={handleClick}
                data-id={item.id}
            >
                {item.icon
                && (
                    <span className={styles.icon}>
                        <Icon name={item.icon} />
                    </span>
                )}
                {label}
            </Component>
        </li>
    );
};

export default SidebarItemMob;
