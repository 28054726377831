import React from 'react';

import { inGamecenter } from '@vkplay/shared';
import { useBreakpoints } from '@vkplay/ui';

import MobileNotificationsContainer from './Mobile';
import NotificationsContainer from './NotificationsContainer';

function Notifications() {
    const { tabletMdMax } = useBreakpoints();

    return (tabletMdMax && !inGamecenter) ? (
        <MobileNotificationsContainer />
    ) : (
        <NotificationsContainer />
    );
}

export default Notifications;
