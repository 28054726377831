import React, {
    useContext, useRef, type FC, type MouseEvent,
} from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Icon } from '@vkplay/ui';
import cn from 'classnames';

import PopularGames from '@components/PopularGames';
import SidebarSubItem from '@components/Sidebar/SidebarSubItem';
import AppContext from '@context/AppContext';
import { useTranslate } from '@hooks';
import Portal from '@kit/Portal';
import ScrollableArea from '@kit/ScrollableArea';

import styles from './Sidebar.module.css';

import type { SidebarItemProps } from '@components/Sidebar/types';

const SidebarItem: FC<SidebarItemProps> = ({ item, offsetLeft = 0, updateOffset }) => {
    const { pushReachGoal } = useAnalyticsMethods();

    const { onSidenavClick, preset } = useContext(AppContext);
    const label = useTranslate(item.label);

    const itemRef = useRef<HTMLAnchorElement>(null);
    const submenuRef = useRef<HTMLDivElement>(null);

    const handleNavClick = (event: MouseEvent<HTMLAnchorElement>) => {
        const { id } = event.currentTarget.dataset;

        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: item.analyticsLabel,
                entity_id: event.currentTarget.href,
            },
        });

        if (onSidenavClick && id) {
            onSidenavClick(event, id);
        }
    };

    let currentTimeout: ReturnType<typeof setTimeout>;

    const showSubnav = () => {
        updateOffset();
        clearTimeout(currentTimeout);

        const itemCurrent = itemRef.current;
        const submenuCurrent = submenuRef.current;

        if (!itemCurrent?.classList.contains(styles.hovered)) {
            itemCurrent?.classList.add(styles.hovered);
        }

        if (!submenuCurrent?.classList.contains(styles.fadeIn)) {
            submenuCurrent?.classList.add(styles.fadeIn);
        }

        if (!submenuCurrent?.classList.contains(styles.visible)) {
            submenuCurrent?.classList.add(styles.visible);
        }
    };

    const hideSubnav = () => {
        clearTimeout(currentTimeout);

        itemRef.current?.classList.remove(styles.hovered);
        submenuRef.current?.classList.remove(styles.fadeIn);

        currentTimeout = setTimeout(() => {
            submenuRef.current?.classList.remove(styles.visible);
        }, 300);
    };

    return (
        <li className={styles.navItem}>
            <a
                href={item.href}
                className={cn(styles.navItemLink, styles.navItemLinkMain, {
                    [styles.current]: preset === item.id,
                })}
                onMouseEnter={showSubnav}
                onMouseLeave={hideSubnav}
                data-id={item.id}
                onClick={handleNavClick}
                ref={itemRef}
            >
                <span className={styles.navItemBtn}>
                    {item.icon && (
                        <span className={styles.icon}>
                            <Icon name={item.icon} />
                        </span>
                    )}

                    <span className={styles.label}>
                        {label}
                    </span>

                    {!!item.subnav.length && (
                        <span className={cn(styles.icon, styles.arrow)}>
                            <Icon name="chevron-right" />
                        </span>
                    )}
                </span>
            </a>

            {!!item.subnav.length && (
                <Portal into="#vkp-navbar">
                    <div
                        className={cn(styles.scrollable, styles.scrollableSub)}
                        style={{
                            transform: `translateX(${offsetLeft}px)`,
                        }}
                        onMouseEnter={showSubnav}
                        onMouseLeave={hideSubnav}
                        ref={submenuRef}
                    >
                        <ScrollableArea disableHorizontal>
                            <div className={cn(styles.sidebarSubNav)}>
                                <div className={styles.menuWrapper}>
                                    <div className={styles.subNavTitle}>
                                        {label}
                                    </div>
                                    <ul className={styles.navLinks}>
                                        {item.subnav.map((subItem) => (
                                            <SidebarSubItem
                                                item={subItem}
                                                onClick={handleNavClick}
                                                key={subItem.id}
                                            />
                                        ))}
                                    </ul>

                                    {item.id === 'market'
                                    && <PopularGames />}
                                </div>
                            </div>
                        </ScrollableArea>
                    </div>
                </Portal>
            )}
        </li>
    );
};

export default SidebarItem;
