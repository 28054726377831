import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    btnName: {
        id: 'gamecenter.download',
        defaultMessage: 'Загрузить Игровой центр',
    },
});

export default messages;
