import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    todayLabel: {
        id: 'date.label.today',
        defaultMessage: 'Сегодня',
    },
    yesterdayLabel: {
        id: 'date.label.yesterday',
        defaultMessage: 'Вчера',
    },
    beforeYesterdayLabel: {
        id: 'date.label.beforeYesterday',
        defaultMessage: 'Позавчера',
    },
    atTimeLabel: {
        id: 'date.label.at',
        defaultMessage: 'в',
    },
});

export default messages;
