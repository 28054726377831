import React from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { inGamecenter } from '@vkplay/shared';
import { LabelSpecial } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import Price from '@components/Price';
import messages from '@components/Search/messages';
import styles from '@components/Search/SearchDropdownItem.module.css';

import GameWishlistButton from './GameWishlistButton';
import currentStyles from './Wishlist.module.css';

import type { WishlistGameProps } from './types';
import type { SpecialTypes } from '@components/Search/types';

function WishlistGame({
    game,
    isFullscreen = false,
}: WishlistGameProps) {
    const {
        url,
        picture,
        name,
        cost_info,
        release_soon,
        is_cloud,
        has_distribution,
        type,
        id,
        labels,
    } = game;

    const { formatMessage } = useIntl();
    const label = labels.find((item) => ['demo', 'dlc'].includes(item.type))?.type as SpecialTypes;
    const { pushReachGoal } = useAnalyticsMethods();

    const onGameClick = () => {
        pushReachGoal({
            params: {
                category: 'library',
                action: 'click',
                label: inGamecenter ? 'Gamecenter' : window.location.host,
                entity_id: id,
            },
        });

        if (inGamecenter && window.gc_open_url_mainwnd) {
            window.gc_open_url_mainwnd(game.url);

            if (window.gc_close_window) {
                window.gc_close_window();
            }
        }
    };

    const Component = inGamecenter ? 'div' : 'a';

    return (
        <Component
            className={cn(styles.root, styles.wishlist, currentStyles.wishlistGame, {
                [styles.fullscreen]: isFullscreen,
            })}
            href={url}
            onClick={onGameClick}
        >
            <div className={cn(styles.imageWrapper, styles.poster)}>
                {picture && <img className={styles.image} src={picture} alt={name} />}
            </div>

            <div className={styles.content}>
                {label && messages[label] && isFullscreen && (
                    <LabelSpecial
                        type={label}
                        size="xs"
                        label={formatMessage(messages[label])}
                        className={styles.labelMobile}
                    />
                )}
                <div className={styles.title}>{name}</div>

                {cost_info && (
                    <div className={styles.description}>
                        <div>
                            <Price
                                costInfo={cost_info}
                                isReleaseSoon={release_soon}
                                isCloud={is_cloud}
                                noDistribution={!has_distribution}
                                gameType={type}
                            />
                        </div>
                        {label && messages[label] && !isFullscreen && (
                            <LabelSpecial
                                type={label}
                                size="lg"
                                label={formatMessage(messages[label])}
                            />
                        )}
                    </div>
                )}

                {!isFullscreen && (
                    <GameWishlistButton game={game} />
                )}
            </div>
        </Component>
    );
}

export default WishlistGame;
