import React, {
    useRef, useContext, useState, useMemo, useCallback,
} from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { useBreakpoints } from '@vkplay/ui';

import GCButton from '@components/GCButton';
import MobOverlay from '@components/MobOverlay';
import Overlay from '@components/Overlay';
import Search from '@components/Search';
import SidebarItem from '@components/Sidebar/SidebarItem';
import SidebarItemMob from '@components/Sidebar/SidebarItemMob';
import SteamRefill from '@components/SteamRefill';
import AppContext from '@context/AppContext';
import { useOffsetLeft } from '@hooks';
import Portal from '@kit/Portal';
import ScrollableArea from '@kit/ScrollableArea';
import VKPlayLogo from '@kit/VKPlayLogo';
import SCHEMA, { PROJECTS_ORDER } from '@navigation';
import getMobileSchema from '@navigation/mobile.schema';
import { Urls } from '@services/hosts';

import styles from './Sidebar.module.css';

import type { NavigationItemMob } from '@navigation/types';
import type { MouseEvent, FC } from 'react';

const MOB_CONTAINER_ID = 'mob-nav-container';

const Sidebar: FC = () => {
    const { tabletMdMax } = useBreakpoints();

    const [openSearch, setOpenSearch] = useState(false);
    const [openMobOverlay, setOpenMobOverlay] = useState(false);

    const { pushReachGoal } = useAnalyticsMethods();
    const { onMainClick } = useContext(AppContext);
    const navRef = useRef(null);
    const { offsetLeft, updateOffset } = useOffsetLeft(navRef);

    const handleClickLogo = (event: MouseEvent<HTMLAnchorElement>) => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'logo',
            },
        });

        onMainClick?.(event);
    };

    const handleOpenSearch = useCallback(() => {
        setOpenSearch(true);
    }, []);

    const handleCloseSearch = useCallback(() => {
        setOpenSearch(false);
    }, []);

    const toggleOpenMobOverlay = useCallback(() => {
        setOpenMobOverlay(!openMobOverlay);
    }, [openMobOverlay]);

    const handleCloseMobOverlay = useCallback(() => {
        setOpenMobOverlay(false);
    }, []);

    const schemaMob = useMemo(() => getMobileSchema({
        onSearchClick: handleOpenSearch,
        onMoreClick: toggleOpenMobOverlay,
    }), [handleOpenSearch, toggleOpenMobOverlay]);

    return (
        <nav
            className={styles.sidebar}
            id="vkp-navbar"
            ref={navRef}
        >
            <div className={styles.scrollable}>
                <ScrollableArea disableHorizontal>
                    <div className={styles.container}>
                        <div className={styles.menuWrapper}>
                            <a
                                href={Urls.main}
                                className={styles.logo}
                                onClick={handleClickLogo}
                            >
                                <VKPlayLogo responsive />
                            </a>
                            <ul className={styles.navLinks}>
                                {PROJECTS_ORDER.map((id) => {
                                    const item = SCHEMA[id];

                                    return (
                                        <SidebarItem
                                            key={`${item.id}_${item.href}`}
                                            item={item}
                                            offsetLeft={offsetLeft}
                                            updateOffset={updateOffset}
                                        />
                                    );
                                })}
                            </ul>

                            <SteamRefill />

                            <GCButton />
                        </div>
                    </div>
                </ScrollableArea>
            </div>

            <div
                className={styles.containerMob}
                id={MOB_CONTAINER_ID}
            >
                <ul className={styles.navLinksMob}>
                    {schemaMob.map((item: NavigationItemMob) => {
                        const handleClick = () => {
                            if (item.id !== 'more') {
                                handleCloseMobOverlay();
                            }

                            if (item.onClick) {
                                item.onClick();
                            }
                        };

                        return (
                            <SidebarItemMob
                                key={`${item.id}_${item.href}`}
                                item={item}
                                onClick={handleClick}
                                openMobOverlay={openMobOverlay}
                            />
                        );
                    })}
                </ul>

                {openSearch && tabletMdMax && (
                    <Portal shouldDisableBodyScroll>
                        <Overlay className={styles.search}>
                            <Search size="fullscreen" onClose={handleCloseSearch} />
                        </Overlay>
                    </Portal>
                )}

                {openMobOverlay && tabletMdMax && (
                    <Portal
                        into={`#${MOB_CONTAINER_ID}`}
                        shouldDisableBodyScroll
                    >
                        <MobOverlay onClose={handleCloseMobOverlay} />
                    </Portal>
                )}
            </div>
        </nav>
    );
};

export default Sidebar;
