import { defineMessages } from 'react-intl';

export default defineMessages({
    steamErrorDialog: {
        id: 'steam.error.dialog',
        defaultMessage: 'Действие недоступно',
    },
    steamErrorTitle: {
        id: 'steam.error.title',
        defaultMessage: 'Действие недоступно пользователям, вошедшим в VK Play через Steam.',
    },
    steamErrorText: {
        id: 'steam.error.text',
        defaultMessage: 'Используйте другой аккаунт.',
    },
    logout: {
        id: 'profile.logout',
        defaultMessage: 'Выйти из аккаунта',
    },
    close: {
        id: 'app.close',
        defaultMessage: 'Закрыть',
    },
});
