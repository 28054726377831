import { useState, useLayoutEffect, type RefObject } from 'react';

export default function useOffsetLeft(ref: RefObject<HTMLElement>|string) {
    const [offsetLeft, setOffsetLeft] = useState(0);

    const updateOffset = () => {
        let elem;

        if (typeof ref === 'string') {
            elem = document.querySelector(ref);
        } else {
            elem = ref.current;
        }

        if (elem) {
            const rect = elem.getBoundingClientRect();

            setOffsetLeft(rect.left);
        }
    };

    useLayoutEffect(() => {
        updateOffset();
        window.addEventListener('resize', updateOffset);

        return (() => {
            window.removeEventListener('resize', updateOffset);
        });
    }, []);

    return {
        offsetLeft,
        updateOffset,
    };
}
