import React from 'react';

import Overlay from '@components/Overlay';
import Portal from '@kit/Portal';

import styles from './MobileNotifications.module.css';
import MobileNotificationsHeader from './MobileNotificationsHeader';
import MobileNotificationsList from './MobileNotificationsList';
import { MOB_CONTAINER_ID } from '../constants';

import type { MobileNotificationOverlayProps } from '../types';

function MobileNotificationOverlay({
    onClose,
    open,
}: MobileNotificationOverlayProps) {
    if (!open) return null;

    return (
        <Portal
            shouldDisableBodyScroll
            into={MOB_CONTAINER_ID}
        >
            <Overlay className={styles.container}>
                <MobileNotificationsHeader
                    onClose={onClose}
                />
                <MobileNotificationsList />
            </Overlay>
        </Portal>
    );
}

export default MobileNotificationOverlay;
