import React from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { copyTextToClipboard } from '@vkplay/shared';
import { Tooltip, Icon } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import snackbar from '@kit/SnackBar/SnackBar';
import WrapperButton from '@kit/WrapperButton';

import messages from './messages';
import styles from './Profile.module.css';

import type { ProfileIdProps } from './types';

function ProfileId({ ac_id }: ProfileIdProps) {
    const { pushReachGoal } = useAnalyticsMethods();
    const { formatMessage } = useIntl();

    const handleCopyId = () => {
        copyTextToClipboard(ac_id.toString(), () => {
            snackbar.sendMessage({
                status: 'success',
                message: formatMessage(messages.copied),
            });
        });

        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-profile-copy',
            },
        });
    };

    return (
        <WrapperButton
            className={styles.userIdWrapper}
            onClick={handleCopyId}
        >
            <span
                data-tooltip-id="copy-id-tooltip"
                className={styles.copyWrapper}
            >
                <span className={styles.userId}>
                    ID:
                    {' '}
                    {ac_id}
                </span>
                {' '}
                <Icon name="copy-outline" />
            </span>
            <Tooltip
                id="copy-id-tooltip"
                content={formatMessage(messages.copy)}
                opacity={1}
                place="bottom"
            />
        </WrapperButton>
    );
}

export default ProfileId;
