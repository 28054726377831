import { GAMES_HOST, MEDIA_HOST } from '@services/hosts';

import messages from './messages';

import type { GetMobileSchemaOptions, NavigationItemMob } from './types';

const getMobileSchema = ({ onSearchClick, onMoreClick }: GetMobileSchemaOptions): NavigationItemMob[] => ([
    {
        id: 'main',
        label: messages.main,
        href: `https://${GAMES_HOST}/`,
        icon: 'home-outline',
    },
    {
        id: 'store',
        label: messages.store,
        href: `https://${GAMES_HOST}/play`,
        icon: 'game-outline',
        analyticsLabel: 'ph-menu-catalog',
    },
    {
        id: 'media',
        label: messages.media,
        href: `https://${MEDIA_HOST}/`,
        icon: 'newsfeed-lines-outline',
        analyticsLabel: 'ph-menu-media',
    },
    {
        id: 'search',
        label: messages.search,
        icon: 'search',
        onClick: onSearchClick,
    },
    {
        id: 'more',
        label: messages.more,
        icon: 'menu',
        onClick: onMoreClick,
    },
]);

export default getMobileSchema;
