import React, { useContext } from 'react';

import { Loader } from '@vkplay/ui';

import NotificationsContext from '@components/TopNav/Notifications/context/NotificationsContext';
import EmptyList from '@components/TopNav/Notifications/EmptyList';

import styles from './MobileNotifications.module.css';
import Notification from '../Notification';

function MobileNotificationsList() {
    const { list, loading } = useContext(NotificationsContext);

    if (loading) {
        return (
            <Loader
                className={styles.mobileLoader}
                size="48"
            />
        );
    }

    if (!loading && list.length === 0) {
        return <EmptyList />;
    }

    return (
        <div className={styles.list}>
            {list.map((item) => (
                <Notification
                    key={item.id}
                    notification={item}
                />
            ))}
        </div>
    );
}

export default MobileNotificationsList;
