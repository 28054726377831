import { getGemHost, getAuthHost } from '@vkplay/shared';

export enum Hosts {
    AUTH = 'auth-ac',
    ACCOUNT = 'account',
    API = 'api',
    GAMES = 'store',
    PROFILE = 'profile',
    SUPPORT = 'support',
    MEDIA = 'media',
    PVP = 'pvp',
    LIVE = 'live',
    CLOUD = 'cloud',
    MARKET = 'market',
    DEVDOCS= 'documentation',
    MINI = 'mini',
    COMMUNITY = 'community',
}

export const API_HOST = getGemHost(Hosts.API);
export const AUTH_HOST = getAuthHost(Hosts.AUTH);
export const ACCOUNT_HOST = getGemHost(Hosts.ACCOUNT);
export const GAMES_HOST = getGemHost(Hosts.GAMES);
export const PROFILE_HOST = getGemHost(Hosts.PROFILE);
export const SUPPORT_HOST = getGemHost(Hosts.SUPPORT);
export const MEDIA_HOST = getGemHost(Hosts.MEDIA);
export const PVP_HOST = getGemHost(Hosts.PVP);
export const LIVE_HOST = getGemHost(Hosts.LIVE);
export const MARKET_HOST = getGemHost(Hosts.MARKET);
export const CLOUD_HOST = getGemHost(Hosts.CLOUD);
export const DEVDOCS_HOST = getGemHost(Hosts.DEVDOCS);
export const MINI_HOST = getGemHost(Hosts.MINI);
export const COMMUNITY_HOST = getGemHost(Hosts.COMMUNITY);

export const isAccount = window.location.hostname === ACCOUNT_HOST;
export const isStore = window.location.hostname === GAMES_HOST;
export const isProfile = window.location.hostname === PROFILE_HOST;
export const isMedia = window.location.hostname === MEDIA_HOST;
export const isPvp = window.location.hostname === PVP_HOST;
export const isLive = isStore && window.location.pathname.match(/^\/\b(live)\b\/?/);
export const isPartner = isStore && window.location.pathname.match(/^\/\b(app)\b\/?/);
export const isMarket = window.location.hostname === MARKET_HOST;
export const isCloud = window.location.hostname === CLOUD_HOST;
export const isDevdocs = window.location.hostname === DEVDOCS_HOST;
export const isMini = window.location.hostname === MINI_HOST;
export const isCommunity = window.location.hostname === COMMUNITY_HOST;
export const isSupport = window.location.hostname === SUPPORT_HOST;

// eslint-disable-next-line max-len
const continueURL = (isAccount && !window.location.pathname.includes('oauth')) ? `https://${ACCOUNT_HOST}/login/` : window.location.href;
const currentNotifyHost = API_HOST;
// const currentNotifyHost = 'api.dev-vkplay.ru';
export const Urls = {
    login: `https://${ACCOUNT_HOST}/login/`,
    logout: `https://${AUTH_HOST}/logout?continue=${encodeURIComponent(continueURL)}`,
    get_user: `https://${API_HOST}/social/profile/v2/session`,
    get_terms: `https://${API_HOST}/account/terms/versions/`,
    get_gc_auth: `https://${API_HOST}/account/get_gc_auth/`,
    terms_accept: `https://${API_HOST}/account/terms/accept/`,
    profile: `https://${PROFILE_HOST}/profile/`,
    accountSettings: `https://${ACCOUNT_HOST}/profile/userinfo/`,
    support: `https://${SUPPORT_HOST}`,
    devdocs: `https://${DEVDOCS_HOST}`,
    change_language: `https://${API_HOST}/account/change_language/`,
    main: `https://${GAMES_HOST}/`,
    gamecenter: `https://${GAMES_HOST}/about/?from=gamecenter`,
    ckassa: `https://${API_HOST}/play/market/steam/ckassa/`,
    wishlist: `https://${API_HOST}/play/games/wishlist/`,

    /* NOTIFICATIONS */
    count: `https://${currentNotifyHost}/social/notifications/count`,
    notifications: `https://${currentNotifyHost}/social/notifications/list`,
    notificationDelete: `https://${currentNotifyHost}/social/notifications/remove`,
    friendRequest: `https://${currentNotifyHost}/profile/p/friend_request/`,
    readOne: `https://${currentNotifyHost}/social/notifications/read`,
    readAll: `https://${currentNotifyHost}/social/notifications/read_all`,
    nextNotification: `https://${currentNotifyHost}/social/notifications/next`,

    gameSetting: '/play/set_game_setting/',
    userWishlist: '/games/wishlist',
};

export const getAnalyticsPreset = () => {
    if (isPartner) {
        return '';
    }

    if (isLive) {
        return Hosts.LIVE;
    }

    if (isStore) {
        return Hosts.GAMES;
    }

    if (isMarket) {
        return Hosts.MARKET;
    }

    if (isPvp) {
        return Hosts.PVP;
    }

    if (isCommunity) {
        return Hosts.COMMUNITY;
    }

    if (isMedia) {
        return Hosts.MEDIA;
    }

    if (isSupport) {
        return Hosts.SUPPORT;
    }

    return '';
};
