import { useEffect, useState } from 'react';

const useDropdownController = () => {
    const [shouldBeOpened, setShouldBeOpened] = useState(false);
    const [isContainerFocused, setIsContainerFocused] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    const handleSetShouldBeOpened = (value: boolean) => {
        setShouldBeOpened(value);
    };

    const handleSetContainerFocused = (value: boolean) => {
        setIsContainerFocused(value);
    };

    useEffect(() => {
        setIsOpened(shouldBeOpened && isContainerFocused);
    }, [shouldBeOpened, isContainerFocused]);

    return {
        isOpened,
        isContainerFocused,
        handleSetShouldBeOpened,
        handleSetContainerFocused,
    };
};

export default useDropdownController;
