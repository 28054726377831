import React, { useContext } from 'react';

import { Button, Icon, WrapperButton } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import Selected from '@assets/img/selected.png';
import IntlContext from '@context/IntlContext';
import Dialog from '@kit/Dialog';

import styles from './LangDropdown.module.css';
import messages from './messages';

import type { LangMobileProps } from './types';

function LangMobile({
    isOpen,
    onClose,
    onSelectLang,
    onSetLang,
    selectedLang,
}: LangMobileProps) {
    const { langsList } = useContext(IntlContext);
    const { formatMessage } = useIntl();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            className={styles.mobileLang}
        >
            <WrapperButton
                className={styles.closeDialog}
                onClick={onClose}
            >
                <Icon name="close" />
            </WrapperButton>

            <div className={styles.content}>
                <h4>
                    {formatMessage(messages.language)}
                </h4>

                <div>
                    {Object.entries(langsList).map(([key, value]) => (
                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                        <p
                            key={key}
                            className={cn(styles.langItem, {
                                [styles.selected]: selectedLang === key,
                            })}
                            onClick={() => onSelectLang(key)}
                        >
                            <img
                                src={value.flag}
                                alt={value.label}
                            />

                            <span>{value.name}</span>

                            {selectedLang === key && (
                                <img
                                    src={Selected}
                                    alt={key}
                                    className={styles.selectedIcon}
                                />
                            )}
                        </p>
                    ))}
                </div>

                <Button
                    size="md"
                    appearance="accent"
                    mode="primary"
                    onClick={onSetLang}
                >
                    {formatMessage(messages.apply)}
                </Button>
            </div>
        </Dialog>
    );
}

export default LangMobile;
