import React from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Icon } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import Tooltip from '@kit/Tooltip';
import { Urls } from '@services/hosts';

import styles from './GCButton.module.css';
import messages from './messages';

import type { FC } from 'react';

const GCButton: FC = () => {
    const { pushReachGoal } = useAnalyticsMethods();
    const { formatMessage } = useIntl();

    const handleClickAnchor = () => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-gc',
            },
        });
    };

    return (
        <a
            href={Urls.gamecenter}
            className={styles.gcBtn}
            target="_blank"
            rel="noreferrer"
            data-tooltip-id="gc-btn"
            data-tooltip-position-strategy="fixed"
            onClick={handleClickAnchor}
        >
            <span className={styles.icon}>
                <Icon name="download-outline" />
            </span>

            <span className={styles.label}>
                {formatMessage(messages.btnName)}
            </span>

            <Tooltip
                id="gc-btn"
                content={formatMessage(messages.btnName)}
                className={styles.tooltip}
            />
        </a>
    );
};

export default GCButton;
