import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    main: {
        id: 'menu.main',
        defaultMessage: 'Главная',
    },
    cloud: {
        id: 'menu.cloud',
        defaultMessage: 'Облачный гейминг',
    },
    cloudPlans: {
        id: 'menu.cloud.plans',
        defaultMessage: 'Тарифы',
    },
    download: {
        id: 'menu.cloud.download',
        defaultMessage: 'Скачать',
    },
    faq: {
        id: 'menu.cloud.faq',
        defaultMessage: 'FAQ',
    },
    live: {
        id: 'menu.live',
        defaultMessage: 'Стримы',
    },
    market: {
        id: 'menu.market',
        defaultMessage: 'Маркет',
    },
    goods: {
        id: 'menu.market.goods',
        defaultMessage: 'Игровые товары',
    },
    refill: {
        id: 'menu.market.refill',
        defaultMessage: 'Пополнить баланс',
    },
    media: {
        id: 'menu.media',
        defaultMessage: 'Медиа',
    },
    news: {
        id: 'menu.media.news',
        defaultMessage: 'Новости',
    },
    articles: {
        id: 'menu.media.articles',
        defaultMessage: 'Статьи',
    },
    guides: {
        id: 'menu.media.guides',
        defaultMessage: 'Руководства',
    },
    store: {
        id: 'menu.store',
        defaultMessage: 'Каталог игр',
    },
    play: {
        id: 'menu.store.play',
        defaultMessage: 'Доступно на VK Play',
    },
    free: {
        id: 'menu.store.free',
        defaultMessage: 'Бесплатно',
    },
    sale: {
        id: 'menu.store.sale',
        defaultMessage: 'Скидки',
    },
    my: {
        id: 'menu.store.my',
        defaultMessage: 'Мои игры',
    },
    search: {
        id: 'menu.search',
        defaultMessage: 'Поиск',
    },
    more: {
        id: 'menu.more',
        defaultMessage: 'Еще',
    },
    pvp: {
        id: 'menu.pvp',
        defaultMessage: 'Турниры',
    },
    overview: {
        id: 'menu.pvp.overview',
        defaultMessage: 'Обзор',
    },
    tournaments: {
        id: 'menu.pvp.tournaments',
        defaultMessage: 'Все турниры',
    },
    player: {
        id: 'menu.pvp.my',
        defaultMessage: 'Мои турниры',
    },
    profile: {
        id: 'profile.main',
        defaultMessage: 'Профиль',
    },
    favorites: {
        id: 'profile.favorites',
        defaultMessage: 'Избранное',
    },
    notifications: {
        id: 'profile.notifications',
        defaultMessage: 'Уведомления',
    },
    support: {
        id: 'profile.support',
        defaultMessage: 'Служба поддержки',
    },
    settings: {
        id: 'profile.settings',
        defaultMessage: 'Настройки',
    },
    often: {
        id: 'menu.live.often',
        defaultMessage: 'Часто стримят',
    },
    online: {
        id: 'menu.live.online',
        defaultMessage: 'Сейчас в эфире',
    },
    steamRefill: {
        id: 'steam.refill',
        defaultMessage: 'Пополнить Steam',
    },
});

export default messages;
