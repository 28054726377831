import React from 'react';

import Spinner from '@assets/img/spinner.svg?react';
import cn from 'classnames';

import styles from './Loader.module.css';

import type { LoaderProps } from './types';
import type { FC } from 'react';

const Loader: FC<LoaderProps> = ({ transparent, className, accent }) => (
    <div
        className={cn(styles.loader, className, {
            [styles.transparent]: transparent,
            [styles.accent]: accent,
        })}
    >
        <div className={styles.spinner}>
            <Spinner />
        </div>
    </div>
);

export default Loader;
