import { defineMessages } from 'react-intl';

export default defineMessages({
    empty: {
        id: 'wishlist.empty',
        defaultMessage: 'В избранном пока ничего нет',
    },
    emptySub: {
        id: 'wishlist.emptySub',
        defaultMessage: 'Для быстрого доступа добавляйте сюда игры, которые вам понравились',
    },
    toCatalog: {
        id: 'wishlist.toCatalog',
        defaultMessage: 'Перейти в каталог',
    },
    deleted: {
        id: 'wishlist.deleted',
        defaultMessage: 'Игра успешно удалена из вашей коллекции',
    },
    delete: {
        id: 'wishlist.delete',
        defaultMessage: 'Удалить из избранного',
    },
    added: {
        id: 'wishlist.added',
        defaultMessage: 'Игра успешно добавлена в вашу коллекцию',
    },
    add: {
        id: 'wishlist.add',
        defaultMessage: 'Добавить в избранное',
    },
    seeAll: {
        id: 'wishlist.seeAll',
        defaultMessage: 'Смотреть все',
    },
    error: {
        id: 'wishlist.error',
        defaultMessage: 'Что-то не так. Повторите попытку позже',
    },
});
