import React, { useContext } from 'react';

import { Icon, Tooltip, WrapperButton } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import NotificationsContext from './context/NotificationsContext';
import messages from './messages';
import styles from './Notifications.module.css';

import type { NotificationsHeaderProps } from './types';

function NotificationsHeader({
    hasNew,
    loading,
}: NotificationsHeaderProps) {
    const { onCheckAll } = useContext(NotificationsContext);
    const { formatMessage } = useIntl();

    return (
        <div className={styles.header}>
            <span className={styles.title}>
                {formatMessage(messages.title)}
            </span>

            {!loading && (
                <WrapperButton
                    onClick={onCheckAll}
                    className={cn(styles.checkAll, {
                        [styles.hasNew]: hasNew,
                    })}
                    data-tooltip-id="tooltip-no-new"
                >
                    <Icon name="list-check-outline" />
                    <span>
                        {formatMessage(messages.checkAll)}
                    </span>
                </WrapperButton>
            )}

            {!hasNew && (
                <Tooltip
                    id="tooltip-no-new"
                    opacity={1}
                    place="bottom"
                    className={styles.secondaryTooltip}
                >
                    <span>
                        {formatMessage(messages.noNewNotifications)}
                    </span>
                </Tooltip>
            )}
        </div>
    );
}

export default NotificationsHeader;
