import { defineMessages } from 'react-intl';

export default defineMessages({
    overlayStep1: {
        id: 'gc.download.steps.one',
        defaultMessage: 'Кликните на файл',
    },
    overlayStep2: {
        id: 'gc.download.steps.two',
        defaultMessage: 'Нажмите «Запустить»',
    },
});
