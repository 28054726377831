import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    title: {
        id: 'menu.popular_games',
        defaultMessage: 'Популярные игры',
    },
});

export default messages;
