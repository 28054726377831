import React, { useContext, useState } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Icon, Button } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import AppContext from '@context/AppContext';
import SessionContext from '@context/SessionContext';
import { makeAuthUrl } from '@services/auth';

import messages from './messages';
import styles from './Profile.module.css';
import ProfileButton from './ProfileButton';
import ProfilePopup from './ProfilePopup';

export default function Profile() {
    const { pushReachGoal } = useAnalyticsMethods();
    const { formatMessage } = useIntl();
    const authUrl = makeAuthUrl();

    const { loaded, noSession } = useContext(SessionContext);
    const { transparent } = useContext(AppContext);
    const [open, setOpen] = useState(false);

    const handleOpenProfilePopup = () => {
        if (open) {
            return;
        }

        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-profile-dropdown',
                url: window.location.href,
            },
        });

        setOpen(true);
    };

    const handleCloseProfilePopup = () => {
        if (!open) {
            return;
        }

        setOpen(false);
    };

    const handleClickSignIn = () => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-login',
            },
        });
    };

    if (!loaded) {
        return (
            <div className={`${styles.loading} vkp-pulse-animation`} />
        );
    }

    if (loaded && noSession) {
        return (
            <Button
                component="a"
                appearance="overlay"
                mode="primary"
                size="md"
                onClick={handleClickSignIn}
                href={authUrl}
                leftIcon={<Icon name="user-outline" />}
                data-id="login-button"
            >
                {formatMessage(messages.login)}
            </Button>
        );
    }

    return (
        <div
            className={cn(styles.wrapper, {
                [styles.transparent]: transparent,
            })}
        >
            <ProfileButton
                onClick={handleOpenProfilePopup}
                open={open}
            />

            <ProfilePopup
                open={open}
                onClose={handleCloseProfilePopup}
            />
        </div>
    );
}
