import type { GameSearchItem } from '@components/Search/types';

// Ручка для dev-игр не возвращает alias, а он нужен, чтобы отличать игры стора от игр медиа
export default function setAliasForDev(item: GameSearchItem) {
    return {
        ...item,
        extra: {
            ...item.extra,
            alias: 'play_game',
        },
    } as GameSearchItem;
}
