import React, { useContext } from 'react';

import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

import Selected from '@assets/img/selected.png';
import IntlContext from '@context/IntlContext';

import styles from './LangDropdown.module.css';
import { LangTooltipEvents } from './types';

import type { LangTooltipProps } from './types';

function LangTooltip({
    locale,
    onEvent,
    className,
    ...tooltipProps
}: LangTooltipProps) {
    const { langsList } = useContext(IntlContext);

    return (
        <Tooltip
            opacity={1}
            noArrow
            clickable
            {...tooltipProps}
            className={cn(styles.langList, className)}
            closeEvents={{ blur: true }}
        >
            {Object.entries(langsList).map(([key, value]) => (
                // eslint-disable-next-line max-len
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                <p
                    key={key}
                    className={cn(styles.langItem, {
                        [styles.selected]: locale === key,
                    })}
                    onClick={() => onEvent(LangTooltipEvents.Click, key)}
                >
                    <img
                        src={value.flag}
                        alt={value.label}
                    />

                    <span>{value.name}</span>

                    {locale === key && (
                        <img
                            src={Selected}
                            alt={key}
                            className={styles.selectedIcon}
                        />
                    )}
                </p>
            ))}
        </Tooltip>
    );
}

export default LangTooltip;
