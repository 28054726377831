import { query_string, inGamecenter } from '@vkplay/shared';

type CookieBotSettingsType = {
    no_cookiebot?: '0' | '1';
    force_show_cookiebot?: '0' | '1';
}

const qs: CookieBotSettingsType = query_string();

export const checkNeedToLoadCookiebot = (): boolean => {
    // eslint-disable-next-line max-len
    const domainRegex = /^((store|market|profile|account|documentation|developers|community|media)\.vkplay\.ru|vkplay\.ru)$/;
    const showOnDomain = domainRegex.test(window.location.hostname);
    const { no_cookiebot, force_show_cookiebot } = qs;

    if (force_show_cookiebot) {
        return true;
    }

    // авто-отключение кукибота в метрике
    let isMetrikaIframe = false;

    if (
        window.self !== window.top
        && (document.referrer.includes('metrika.yandex.ru') || document.referrer.includes('webvisor.com'))
    ) {
        isMetrikaIframe = true;
    }

    // Загружаем кукибот не в ИЦ, только на доменах vkplay.ru, не на фуллскрин-страницах авторизации,
    // не на спецпроектах и не на партнерке
    return !inGamecenter
        && showOnDomain
        && !window.location.pathname.includes('/oauth2/')
        && !window.location.pathname.includes('/signup_terms_accept/')
        && !window.location.pathname.includes('/social_back/')
        && !window.location.pathname.includes('/secstep/')
        && !window.location.pathname.startsWith('/special')
        && !window.location.pathname.includes('/app/')
        && !no_cookiebot // параметр для выключения куки бота, для Яндекс метрики и тд
        && !isMetrikaIframe;
};

export const COOKIE_SYNC_ID = 'ph-cookiesync';
