import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    btnName: {
        id: 'steam.refill',
        defaultMessage: 'Пополнить Steam',
    },
    btnTooltip: {
        id: 'steam.refill_wallet',
        defaultMessage: 'Пополнить кошелек Steam',
    },
});

export default messages;
