import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    title: {
        id: 'terms.title',
        defaultMessage: 'Юридическая информация',
    },
    descr: {
        id: 'terms.descr',
        defaultMessage: 'Перед использованием сервиса вам необходимо принять юридические документы.',
    },
    vkId: {
        id: 'terms.vk_id',
        defaultMessage: 'Согласие с запросом данных у VK ID.',
    },
    accept: {
        id: 'terms.accept',
        defaultMessage: 'Я соглашаюсь с применимыми правилами',
    },
    acceptHint: {
        id: 'terms.accept_hint',
        defaultMessage: 'Примите согласие, чтобы продолжить',
    },
    newsletter: {
        id: 'terms.newsletter',
        defaultMessage: 'Я соглашаюсь получать сообщения рекламного и информационного характера (необязательно)',
    },
    continue: {
        id: 'app.continue',
        defaultMessage: 'Продолжить',
    },
    logoutShort: {
        id: 'profile.logout_short',
        defaultMessage: 'Выйти',
    },
    docsCis: {
        id: 'terms.docs_cis',
        defaultMessage: 'Документы для пользователей из СНГ',
    },
    docsNonCis: {
        id: 'terms.docs_non_cis',
        defaultMessage: 'Документы для пользователей не из СНГ',
    },
});

export default messages;
