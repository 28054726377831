import { MEDIA_HOST } from '@services/hosts';

import messages from './messages';

import type { NavigationItem } from './types';

const ORIGIN = `https://${MEDIA_HOST}`;

const media = {
    id: 'media',
    label: messages.media,
    href: `${ORIGIN}/`,
    icon: 'newsfeed-lines-outline',
    analyticsLabel: 'ph-menu-media',
    subnav: [
        {
            label: messages.main,
            id: 'main',
            href: `${ORIGIN}/`,
        },
        {
            label: messages.news,
            id: 'news',
            href: `${ORIGIN}/news/`,
            allowPartialMatch: true,
        },
        {
            label: messages.articles,
            id: 'articles',
            href: `${ORIGIN}/articles/feat/`,
            allowPartialMatch: true,
        },
        {
            label: messages.guides,
            id: 'secret',
            href: `${ORIGIN}/articles/secret/`,
            allowPartialMatch: true,
        },
    ],
} as const satisfies NavigationItem;

export default media;
