import { MARKET_HOST } from '@services/hosts';

import messages from './messages';

import type { NavigationItem } from './types';

const ORIGIN = `https://${MARKET_HOST}`;

const market = {
    id: 'market',
    label: messages.market,
    href: `${ORIGIN}/`,
    icon: 'market-outline',
    analyticsLabel: 'ph-menu-market',
    subnav: [
        {
            label: messages.goods,
            id: 'play',
            href: `${ORIGIN}/`,
        },
        {
            label: messages.refill,
            id: 'refill',
            href: `${ORIGIN}/shop/255/`,
            include: [`${ORIGIN}/inventory/255/`, `${ORIGIN}/inventory_code/255/`, `${ORIGIN}/help/255/`],
        },
    ],
} as const satisfies NavigationItem;

export default market;
