import {
    PROFILE_HOST, ACCOUNT_HOST, SUPPORT_HOST,
} from '@services/hosts';

import messages from './messages';

import type { NavigationItemProfile, GetProfileSchemaOptions } from './types';

const getProfileSchema = ({
    onNotificationsClick,
}: GetProfileSchemaOptions): NavigationItemProfile[] => ([
    {
        id: 'profile',
        label: messages.profile,
        href: `https://${PROFILE_HOST}`,
        icon: 'user-outline',
    },
    {
        id: 'favorites',
        label: messages.favorites,
        icon: 'bookmark-outline',
    },
    {
        id: 'notifications',
        label: messages.notifications,
        icon: 'notification-outline',
        onClick: onNotificationsClick,
    },
    {
        id: 'support',
        label: messages.support,
        href: `https://${SUPPORT_HOST}`,
        icon: 'help-outline',
    },
    {
        id: 'settings',
        label: messages.settings,
        href: `https://${ACCOUNT_HOST}/profile/userinfo`,
        icon: 'settings-outline',
    },
]);

export default getProfileSchema;
