import React from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Icon } from '@vkplay/ui';
import cn from 'classnames';

import { useTranslate } from '@hooks';

import styles from './MobOverlay.module.css';

import type { MenuItemProps } from './types';
import type { FC } from 'react';

const MenuItem: FC<MenuItemProps> = ({ item, transparent }) => {
    const label = useTranslate(item.label);
    const { pushReachGoal } = useAnalyticsMethods();

    const handleSendAnalytics = () => {
        if (!item.analyticsLabel) {
            return;
        }

        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: item.analyticsLabel,
            },
        });
    };

    const handleClick = () => {
        handleSendAnalytics();

        if (item.onClick) {
            item.onClick();
        }
    };

    return (
        // eslint-disable-next-line
        <a
            className={cn(styles.menuItem, {
                [styles.transparent]: transparent,
            })}
            {...(item.href && {
                href: item.href,
            })}
            onClick={handleClick}
        >
            <div className={styles.btnIcon}>
                {item.icon
                && <Icon name={item.icon} />}
            </div>
            <div className={styles.btnText}>
                {label}
            </div>
        </a>
    );
};

export default MenuItem;
