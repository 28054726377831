import { Backend, inGamecenter } from '@vkplay/shared';

import { Urls } from '@services/hosts';

export const getCount = async () => {
    let unread = 0;

    try {
        const { counters } = await Backend.get(Urls.count);

        if (!counters) {
            return 0;
        }

        Object.keys(counters).forEach((key) => {
            unread += counters[key].unread;
        });
    } catch (e) {
        console.error(e);
    }

    return unread;
};

export const getNotifications = async (page = 1) => {
    try {
        const data = await Backend.get(Urls.notifications, {
            group: '*',
            limit: 10,
            page,
        });

        return data;
    } catch (e) {
        console.error(e);
    }
};

export const handleFriendRequest = async (user: string, type: 'accept' | 'decline') => {
    try {
        await Backend.post(Urls.friendRequest, {
            target: user,
            type,
        });
    } catch (e) {
        console.error(e);
    }
};

export const handleDeleteNotification = async (id: string) => {
    try {
        await Backend.post(`${Urls.notificationDelete}?notification_id=${id}`);
    } catch (e) {
        console.error(e);
    }
};

export const handleToggleSubscribe = async (url: string, subscribe?: boolean) => {
    try {
        const subUrl = subscribe ? url.replace('unsubscribe', 'subscribe') : url;
        await Backend.get(subUrl);
    } catch (e) {
        console.error(e);
    }
};

export const handleReadNotification = async (id: string) => {
    try {
        await Backend.post(`${Urls.readOne}?notification_id=${id}`);
    } catch (e) {
        console.error(e);
    }
};

export const readAllNotifications = async () => {
    try {
        await Backend.post(Urls.readAll);
    } catch (e) {
        console.error(e);
    }
};

export const getNextNotification = async (notification_id: string, control_id: number) => {
    try {
        await Backend.post(Urls.nextNotification, {
            notification_id,
            control_id,
        });
    } catch (e) {
        console.error(e);
    }
};

export const sendButtonRequest = async (method: 'P' | 'G' | 'N', url: string, Authorization: string) => {
    const m = method === 'P' ? 'post' : 'get';

    try {
        await Backend[m](url, {
            ...(Authorization && {
                headers: {
                    Authorization,
                },
            }),
        });
    } catch (e) {
        console.error(e);
    }
};

export function checkVisibility(value: number): boolean {
    // VkPlay: 4
    // GC: 8
    // GC + VkPlay: 12
    // All: 15

    switch (value) {
        case 15:
        case 12: {
            return true;
        }

        case 8: {
            return inGamecenter;
        }

        case 4: {
            return !inGamecenter;
        }

        default: {
            return false;
        }
    }
}
