import React, { useContext } from 'react';

import { Button, Counter, Icon } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import NotificationsContext from '@components/TopNav/Notifications/context/NotificationsContext';

import styles from './MobileNotifications.module.css';
import messages from '../messages';

import type { MobileNotificationsHeaderProps } from '../types';

function MobileNotificationsHeader({
    onClose,
}: MobileNotificationsHeaderProps) {
    const { unread, onCheckAll } = useContext(NotificationsContext);
    const { formatMessage } = useIntl();

    return (
        <div className={styles.header}>
            <Button
                onClick={onClose}
                leftIcon={<Icon name="chevron-left" />}
                appearance="overlay"
                mode="link"
                className={styles.headerButton}
                size="sm"
            />

            <span className={styles.title}>
                <span className={styles.notify}>
                    {formatMessage(messages.title)}
                    {unread > 0 && (
                        <Counter
                            size="large"
                            mode="negative"
                        >
                            {unread}
                        </Counter>
                    )}
                </span>
            </span>

            <Button
                onClick={onCheckAll}
                className={cn(styles.headerButton, {
                    [styles.read]: unread === 0,
                })}
                leftIcon={<Icon name="list-check-outline" />}
                appearance="overlay"
                mode="link"
                size="sm"
            />
        </div>
    );
}

export default MobileNotificationsHeader;
