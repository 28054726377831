import React, { useState } from 'react';

import { useBreakpoints } from '@vkplay/ui';
import cn from 'classnames';

import LogoDesk from '@assets/img/logo/newyear/logo-desk.png';
import LogoMob from '@assets/img/logo/newyear/logo-mob.png';
import LogoSm from '@assets/img/logo/newyear/logo-sm.png';
import { NEWYEAR_LOGO } from '@kit/VKPlayLogo/constants';

import styles from './VKPlayLogo.module.css';

import type { VKPlayLogoTypes } from './types';
import type { FC } from 'react';

const VKPlayLogoNewYear: FC<VKPlayLogoTypes> = ({ responsive, className }) => {
    const [showImg, setShowImg] = useState(true);
    const { tabletLgMax, tabletMdMax } = useBreakpoints();
    const isSmall = tabletLgMax && responsive;

    const logoAnimatedSrcDesk = (tabletLgMax && responsive) ? NEWYEAR_LOGO.small : NEWYEAR_LOGO.desktop;
    const logoImgSrcDesk = isSmall ? LogoSm : LogoDesk;
    const logoAnimatedSrc = tabletMdMax ? NEWYEAR_LOGO.mobile : logoAnimatedSrcDesk;
    const logoImgSrc = tabletMdMax ? LogoMob : logoImgSrcDesk;

    const hideImg = () => {
        setShowImg(false);
    };

    return (
        <div
            className={cn(styles.logo, styles.logoNewYear, className, {
                [styles.logoNewYearSm]: isSmall,
                [styles.logoNewYearMob]: tabletMdMax,
                [styles.fixed]: !responsive,
            })}
        >
            {showImg && (
                <img
                    src={logoImgSrc}
                    alt="VK Play"
                    className={styles.logoImgFallback}
                />
            )}
            <video
                loop
                muted
                autoPlay
                src={logoAnimatedSrc}
                playsInline
                onLoadedData={hideImg}
            >
                <img
                    src={logoImgSrc}
                    alt="VK Play"
                />
            </video>
        </div>
    );
};

export default VKPlayLogoNewYear;
