import { useContext, useEffect } from 'react';

import AppContext from '@context/AppContext';
import IntlContext from '@context/IntlContext';

import type { FC } from 'react';

const Ready: FC = () => {
    const { onReady, ...appContext } = useContext(AppContext);
    const intlContext = useContext(IntlContext);

    useEffect(() => {
        if (!onReady) {
            return;
        }

        onReady({
            props: appContext,
            intl: intlContext,
        });
    }, []);

    return null;
};

export default Ready;
