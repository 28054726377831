import React from 'react';

import cn from 'classnames';
import { Tooltip as ReactTooltip, type ITooltip } from 'react-tooltip';

import styles from './Tooltip.module.css';

import type { FC } from 'react';

const Tooltip: FC<ITooltip> = (props) => {
    const { className } = props;

    return (
        <ReactTooltip
            opacity={1}
            place="bottom"
            noArrow
            {...props}
            className={cn(styles.tooltip, className)}
        />
    );
};

export default Tooltip;
