import React, { useEffect, useState } from 'react';

import AppShell from './App';

import type { AppShellProps } from './types';
import type { FC } from 'react';

const ProxyApp: FC<AppShellProps> = ({
    appNode,
    onGetUser,
    onReady,
    onMainClick,
    onSidenavClick,
    onSubnavClick,
    ...props
}) => {
    const [options, setOptions] = useState(props);

    useEffect(() => {
        const optionsHandler = {
            set: (obj: Record<string, unknown>, prop: string, value: unknown) => {
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    [prop]: value,
                }));

                obj[prop] = value;

                return true;
            },
        };

        window.EVA.props = new Proxy(JSON.parse(JSON.stringify(options)), optionsHandler);
    }, []);

    return (
        <AppShell
            {...options}
            appNode={appNode}
            onGetUser={onGetUser}
            onReady={onReady}
            onMainClick={onMainClick}
            onSidenavClick={onSidenavClick}
            onSubnavClick={onSubnavClick}
        />
    );
};

export default ProxyApp;
