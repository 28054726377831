import React from 'react';

import { PriceManager } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import messages from '../Search/messages';

import type { Props } from './types';
import type { FC } from 'react';

const p2pGameType: string[] = ['typedownload', 'code', 'paid_content', 'partner_download'];

const Price: FC<Props> = ({
    costInfo, isReleaseSoon, isCloud, noDistribution, gameType,
}) => {
    const { formatMessage } = useIntl();

    const {
        currency,
        original_cost,
        actual_cost,
        discount,
        bought,
    } = costInfo;

    const isP2P = p2pGameType.includes(gameType || '');
    const freeLabel = isCloud && (isP2P || noDistribution) ? '' : formatMessage(messages.free);

    return (
        <PriceManager
            size="sm"
            variant="lime"
            currency={currency}
            actualValue={actual_cost}
            originalValue={original_cost}
            discountValue={discount}
            isBought={bought}
            isReleaseSoon={isReleaseSoon}
            boughtLabel={formatMessage(messages.bought)}
            releaseSoonLabel={formatMessage(messages.releaseSoon)}
            freeLabel={freeLabel}
        />
    );
};

export default Price;
