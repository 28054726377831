import { query_string, qsStringify } from '@vkplay/shared';

export const STORAGE_NAME = 'gem_steam_refill_login';
export const STEAM_REFILL_PARAM = 'show_steam_refill';
export const STATS_PARAM = 'mt_link_id';
export const STATS_VALUE = 'drm100';

type ParamObjectType = {
    [STEAM_REFILL_PARAM]?: string;
    [STATS_PARAM]?: string;
}

const qs: ParamObjectType = query_string();

export const getStorageSteamRefillLogin = (): string|null => {
    try {
        return window.localStorage.getItem(STORAGE_NAME);
    } catch (e) {
        console.warn(`Error getting localStorage value: ${STORAGE_NAME}`, e);

        return null;
    }
};

export const setStorageSteamRefillLogin = (): void => {
    try {
        window.localStorage.setItem(STORAGE_NAME, '1');
    } catch (e) {
        console.warn(`Error setting localStorage value: ${STORAGE_NAME}`, e);
    }
};

export const removeStorageSteamRefillLogin = (): void => {
    try {
        window.localStorage.removeItem(STORAGE_NAME);
    } catch (e) {
        console.warn(`Error removing localStorage value: ${STORAGE_NAME}`, e);
    }
};

export const hasRefillParam: boolean = qs[STEAM_REFILL_PARAM] === '1';

export const makeUrlWithParam = (): string => {
    const { search } = window.location;
    const steamRefillParam = `${STEAM_REFILL_PARAM}=1`;
    const statsParam = `${STATS_PARAM}=${STATS_VALUE}`;

    if (search.includes('?')) {
        return `${window.location.href.replace(window.location.search, `${search}&${steamRefillParam}&${statsParam}`)}`;
    }

    return `${window.location.href}?${steamRefillParam}&${statsParam}`;
};

export const updateUrlParam = (removeStats?: boolean): void => {
    if (hasRefillParam || removeStats) {
        const searchParams: ParamObjectType = { ...qs };

        delete searchParams[STEAM_REFILL_PARAM];

        if (removeStats) {
            delete searchParams[STATS_PARAM];
        }

        const newSearchParams = qsStringify(searchParams);
        const separator = newSearchParams.length > 0 ? '?' : '';
        const newUrl = `${window.location.origin}${window.location.pathname}${separator}${newSearchParams}`;

        window.history.replaceState({ page: newUrl }, '', newUrl);
    }
};
