import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

export const catalogMessages: IntlMessagesType = defineMessages({
    catalog: {
        id: 'menu.store',
        defaultMessage: 'Каталог игр',
    },
    catalogAvailable: {
        id: 'menu.store.play',
        defaultMessage: 'Доступно на VK Play',
    },
    catalogFree: {
        id: 'menu.store.free',
        defaultMessage: 'Бесплатно',
    },
    catalogDiscounts: {
        id: 'menu.store.sale',
        defaultMessage: 'Скидки',
    },
    catalogMyGames: {
        id: 'menu.store.my',
        defaultMessage: 'Мои игры',
    },
});

export const cloudMessages: IntlMessagesType = defineMessages({
    cloud: {
        id: 'menu.cloud',
        defaultMessage: 'Облачный гейминг',
    },
    cloudMain: {
        id: 'menu.main',
        defaultMessage: 'Главная',
    },
    cloudRates: {
        id: 'menu.cloud.plans',
        defaultMessage: 'Тарифы',
    },
    cloudDownload: {
        id: 'menu.cloud.download',
        defaultMessage: 'Скачать',
    },
    cloudFAQ: {
        id: 'menu.cloud.faq',
        defaultMessage: 'FAQ',
    },
});

export const marketMessages: IntlMessagesType = defineMessages({
    market: {
        id: 'menu.market',
        defaultMessage: 'Маркет',
    },
    goods: {
        id: 'menu.market.goods',
        defaultMessage: 'Игровые товары',
    },
    refill: {
        id: 'menu.market.refill_balance',
        defaultMessage: 'Пополнение баланса',
    },
});

export const streamMessages: IntlMessagesType = defineMessages({
    streams: {
        id: 'menu.live',
        defaultMessage: 'Стримы',
    },
    categories: {
        id: 'menu.live.categories',
        defaultMessage: 'Категории',
    },
    often: {
        id: 'menu.live.often',
        defaultMessage: 'Часто стримят',
    },
    chill: {
        id: 'menu.live.chill',
        defaultMessage: 'Ламповый поток',
    },
    online: {
        id: 'menu.live.online',
        defaultMessage: 'Сейчас в эфире',
    },
});

export const mediaMessages: IntlMessagesType = defineMessages({
    media: {
        id: 'menu.media',
        defaultMessage: 'Медиа',
    },
    allMaterials: {
        id: 'menu.media.all',
        defaultMessage: 'Все материалы',
    },
    gameNews: {
        id: 'menu.media.game_news',
        defaultMessage: 'Новости игр',
    },
    articles: {
        id: 'menu.media.articles',
        defaultMessage: 'Статьи',
    },
    guides: {
        id: 'menu.media.guides',
        defaultMessage: 'Руководства',
    },
});

export const tourneyMessages: IntlMessagesType = defineMessages({
    tourney: {
        id: 'menu.pvp',
        defaultMessage: 'Турниры',
    },
    review: {
        id: 'menu.pvp.overview',
        defaultMessage: 'Обзор',
    },
    allTourneys: {
        id: 'menu.pvp.tournaments',
        defaultMessage: 'Все турниры',
    },
});

export const otherMessages: IntlMessagesType = defineMessages({
    other: {
        id: 'footer.other.other',
        defaultMessage: 'Другое',
    },
    developers: {
        id: 'footer.other.developers',
        defaultMessage: 'Разработчикам',
    },
    contacts: {
        id: 'footer.other.contacts',
        defaultMessage: 'Контакты',
    },
    support: {
        id: 'profile.support',
        defaultMessage: 'Служба поддержки',
    },
});

export const bottomMessages: IntlMessagesType = defineMessages({
    terms: {
        id: 'footer.nav.terms',
        defaultMessage: 'Пользовательское соглашение',
    },
    policy: {
        id: 'footer.nav.policy',
        defaultMessage: 'Политика конфиденциальности',
    },
    use_recs: {
        id: 'footer.use_recs',
        defaultMessage: 'Правила применения рекомендательных технологий',
    },
    imprint: {
        id: 'footer.imprint',
        defaultMessage: 'Информация о компании',
    },
});

export const anotherMessages: IntlMessagesType = defineMessages({
    language: {
        id: 'app.language',
        defaultMessage: 'Язык',
    },
    social: {
        id: 'footer.social',
        defaultMessage: 'Социальные сети',
    },
});
