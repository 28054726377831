import React, { type FC } from 'react';

import { Icon, Button } from '@vkplay/ui';

import { Urls } from '@services/hosts';

import { setLocalStorageClosed } from './helpers';
import styles from './RecPopup.module.css';

import type { SystemPopupProps } from '../../types';

const RecPopup: FC<SystemPopupProps> = ({
    onClose,
}) => {
    const handleClose = () => {
        setLocalStorageClosed();
        onClose();
    };

    return (
        <div
            className={styles.wrapper}
        >
            <button
                className={styles.close}
                onClick={handleClose}
            >
                <Icon name="close" />
            </button>
            <div className={styles.title}>
                VK Play применяет рекомендательные технологии
            </div>
            <div className={styles.text}>
                Вы можете ознакомиться с правилами применения рекомендательных технологий
            </div>
            <Button
                component="a"
                href={`${Urls.devdocs}/terms_vkp/adv_algo_rules`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.btn}
                onClick={handleClose}
                appearance="overlay"
                size="sm"
            >
                Узнать больше
            </Button>
        </div>
    );
};

export default RecPopup;
