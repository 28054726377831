import React from 'react';

import cn from 'classnames';

import type { ThemeWrapperProps } from './types';
import type { FC } from 'react';

const ThemeWrapper: FC<ThemeWrapperProps> = ({ children, className, theme = 'dark' }) => (
    <div
        className={cn('vkp-app-shell', className)}
        data-theme={theme}
    >
        {children}
    </div>
);

export default ThemeWrapper;
