import React, { useContext, useRef } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Button } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import SessionContext from '@context/SessionContext';
import { useOnClickOutside } from '@hooks';
import { Urls } from '@services/hosts';

import messages from './messages';
import styles from './Profile.module.css';
import ProfileId from './ProfileId';
import ProfileLinks from './ProfileLinks';

import type { ProfilePopupProps } from './types';

function ProfilePopup({
    open,
    onClose,
}: ProfilePopupProps) {
    const { pushReachGoal } = useAnalyticsMethods();
    const { formatMessage } = useIntl();

    const popupRef = useRef(null);

    useOnClickOutside(popupRef, () => {
        // При клике снаружи на кнопку открытия попапа, попап закрывается и тут же открывается снова.
        // Поэтому добавил setTimeout для корректного закрытия попапа.
        setTimeout(() => {
            onClose();
        }, 10);
    }, 'touchend');

    const { session: { profile } } = useContext(SessionContext);

    const handleClickProfile = () => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-profile',
            },
        });
    };

    if (!profile) {
        return null;
    }

    return (
        <div
            className={cn(styles.profilePopup, {
                [styles.hidden]: !open,
            })}
            ref={popupRef}
        >
            <div className={styles.headInfo}>
                <img
                    className={styles.avatar}
                    src={profile.avatar}
                    alt={profile.nick}
                />

                <span className={styles.nickname}>
                    {profile.nick}
                </span>

                <ProfileId ac_id={profile.ac_id} />

                <Button
                    component="a"
                    href={Urls.profile}
                    appearance="overlay"
                    className={styles.toProfile}
                    onClick={handleClickProfile}
                >
                    {formatMessage(messages.goToProfile)}
                </Button>
            </div>

            <ProfileLinks />
        </div>
    );
}

export default ProfilePopup;
