import {
    GAMES_HOST, MARKET_HOST, MEDIA_HOST, MINI_HOST,
} from '@services/hosts';

export const ADV_CLOSED_NAME = 'gem_tech_popup_closed';

export const showPopupDomain = (
    (window.location.hostname === GAMES_HOST && !window.location.pathname.includes('about'))
    || window.location.hostname === MINI_HOST
    || window.location.hostname === MARKET_HOST
    || window.location.hostname === MEDIA_HOST
);

export const getLocalStorageClosed = (): boolean => {
    try {
        const storage: string|null = window.localStorage.getItem(ADV_CLOSED_NAME);

        return storage === '1';
    } catch (error) {
        console.warn('Error getting storage item', ADV_CLOSED_NAME);

        return false;
    }
};

export const setLocalStorageClosed = (): void => {
    try {
        window.localStorage.setItem(ADV_CLOSED_NAME, '1');
    } catch (error) {
        console.warn('Error setting storage item', ADV_CLOSED_NAME);
    }
};
