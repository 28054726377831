import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
} from 'react';

import { IntlProvider as Intl } from 'react-intl';

import AppContext from '@context/AppContext';
import SessionContext from '@context/SessionContext';
import defaultLang, {
    DEFAULT_LANGS_AVAILABLE, getLangsList, LANGS, QS_LANG,
} from '@services/defaultLang';

import type {
    FC,
    PropsWithChildren,
} from 'react';

export interface IntlContextValue {
    lang: string;
    locale: string;
    langsAvailable: string[];
    langsList: Record<string, Record<string, string>>;
}

const modules = import.meta.glob('../i18n/*.json', { import: 'default' });

const IntlContext = createContext({
    lang: defaultLang.split('_')[0],
    locale: defaultLang,
    langsAvailable: DEFAULT_LANGS_AVAILABLE,
    langsList: LANGS,
});

export const IntlProvider: FC<PropsWithChildren> = ({ children }) => {
    const { session: { locale } } = useContext(SessionContext);
    const { lang = QS_LANG, langsAvailable = DEFAULT_LANGS_AVAILABLE } = useContext(AppContext);

    const langsAvailableFiltered = langsAvailable.filter((val: string) => DEFAULT_LANGS_AVAILABLE.includes(val));

    const isLangAvailable = (val?: string) => (val && langsAvailableFiltered.includes(val) ? val : '');

    const [messages, setMessages] = useState<Record<string, string>>();

    const contextValue = useMemo(() => {
        const currentLocale = isLangAvailable(lang)
            || isLangAvailable(locale)
            || isLangAvailable(langsAvailableFiltered[0])
            || isLangAvailable(defaultLang)
            || 'ru_RU';

        const splittedLocale = currentLocale.split('_');
        const intlFormatLocale = splittedLocale.join('-');
        const shortlLang = splittedLocale[0];

        return {
            lang: shortlLang,
            locale: currentLocale,
            intlLocale: intlFormatLocale,
            langsAvailable: langsAvailableFiltered,
            langsList: getLangsList(langsAvailable),
        };
    }, [lang, langsAvailable, locale]);

    useEffect(() => {
        modules[`../i18n/${contextValue.locale}.json`]()
            .then((currentMessages) => {
                setMessages(currentMessages as Record<string, string>);
            })
            .catch(() => {
                setMessages(undefined);
            });
    }, [contextValue]);

    if (!messages) {
        return null;
    }

    return (
        <IntlContext.Provider value={contextValue}>
            <Intl locale={contextValue.intlLocale} messages={messages}>
                {children}
            </Intl>
        </IntlContext.Provider>
    );
};

export default IntlContext;
