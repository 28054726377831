import React, { useContext } from 'react';

import { Icon } from '@vkplay/ui';
import cn from 'classnames';

import SessionContext from '@context/SessionContext';

import styles from './Profile.module.css';

import type { ProfileButtonProps } from '@components/TopNav/Profile/types';

function ProfileButton({
    open,
    onClick,
}: ProfileButtonProps) {
    const { session: { profile } } = useContext(SessionContext);

    if (!profile) return null;

    return (
        <button
            className={cn(styles.profileButton, {
                [styles.open]: open,
            })}
            onClick={onClick}
        >
            <span className={styles.nickname}>
                {profile.nick}
            </span>

            <img
                className={styles.avatar}
                src={profile.avatar}
                alt={profile.nick}
            />

            <Icon name="chevron-down-small-24" className={styles.arrow} />
        </button>
    );
}

export default ProfileButton;
