import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    language: {
        id: 'app.language',
        defaultMessage: 'Язык',
    },
    apply: {
        id: 'app.apply',
        defaultMessage: 'Применить',
    },
});

export default messages;
