import React, { useContext } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

import Selected from '@assets/img/selected.png';
import IntlContext from '@context/IntlContext';
import changeLang from '@services/changeLang';

import styles from './Profile.module.css';

function ProfileLangSelect() {
    const { locale, langsList } = useContext(IntlContext);
    const { pushReachGoal } = useAnalyticsMethods();

    const handleLangClick = (lang: string, analyticsLabel?: string) => {
        if (lang !== locale) {
            changeLang({ lang, withSession: true });
        }

        if (analyticsLabel) {
            pushReachGoal({
                params: {
                    category: 'header',
                    action: 'click',
                    label: `ph-menu-profile-lang-${analyticsLabel}`,
                },
            });
        }
    };

    const currentLang = langsList[locale as keyof typeof langsList] || langsList.ru_RU;

    return (
        <>
            <span className={styles.langLink} data-tooltip-id="lang-list">
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={currentLang.flag} alt={currentLang.label} />
                <span>{currentLang.label}</span>
            </span>

            <Tooltip
                id="lang-list"
                noArrow
                className={styles.langList}
                place="left-start"
                clickable
                offset={16}
                opacity={1}
            >
                {Object.entries(langsList).map(([key, value]) => (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions
                    <span
                        key={key}
                        className={cn(styles.langItem, {
                            [styles.selected]: locale === key,
                        })}
                        onClick={() => handleLangClick(key, value.analyticsLabel)}
                    >
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <img src={value.flag} alt={key} />
                        <span>{value.name}</span>

                        {locale === key && (
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img
                                src={Selected}
                                className={styles.selectedIcon}
                                alt={key}
                            />
                        )}
                    </span>
                ))}
            </Tooltip>
        </>
    );
}

export default ProfileLangSelect;
