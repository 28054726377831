import React, { useRef } from 'react';

import { useIntl } from 'react-intl';

import LangDropdown from '@components/LangDropdown';
import MenuItem from '@components/MobOverlay/MenuItem';
import Overlay from '@components/Overlay';
import { useOnClickOutside } from '@hooks';
import overlaySchema from '@navigation/overlay.schema';

import messages from './messages';
import styles from './MobOverlay.module.css';
import ProfileBlock from './ProfileBlock';

import type { MobOverlayTypes } from '@components/MobOverlay/types';
import type { FC } from 'react';

const MobOverlay: FC<MobOverlayTypes> = ({ onClose }) => {
    const ref = useRef(null);
    const { formatMessage } = useIntl();

    useOnClickOutside(ref, onClose);

    return (
        <Overlay className={styles.mobOverlay}>
            <div className={styles.title}>{formatMessage(messages.title)}</div>

            <div className={styles.menuList}>
                {overlaySchema.map((item) => (
                    <MenuItem
                        key={item.id}
                        item={item}
                    />
                ))}
            </div>

            <ProfileBlock />

            <div className={styles.langSelector}>
                <LangDropdown
                    isMobile
                    transparent
                />
            </div>
        </Overlay>
    );
};

export default MobOverlay;
