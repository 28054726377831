import React, { useMemo } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { inGamecenter } from '@vkplay/shared';
import {
    Button, Dialog, Icon, Tooltip, useBreakpoints, WrapperButton,
} from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import styles from '@components/TopNav/Notifications/Notification/Notification.module.css';

import messages from '../messages';

import type { ContextMenuProps } from '../types';
import type { SyntheticEvent } from 'react';

function ContextMenu({
    id,
    onDelete,
    onHide,
    href,
}: ContextMenuProps) {
    const { formatMessage } = useIntl();
    const { tabletMdMax: isMobile } = useBreakpoints();
    const { pushReachGoal } = useAnalyticsMethods();
    const [open, setOpen] = React.useState(false);
    const tooltipId = `context-menu-${id}`;

    const handleToggleDialog = () => {
        setOpen((prevState) => {
            if (prevState) {
                pushReachGoal({
                    params: {
                        category: 'notifications',
                        action: 'menu_click',
                        label: 'inside kolokolchik',
                        entity_id: href,
                    },
                });
            }

            return !prevState;
        });
    };

    const handleClick = (event: SyntheticEvent) => {
        event.stopPropagation();

        if (isMobile && !inGamecenter) {
            handleToggleDialog();
        }
    };

    const handleDelete = (event: SyntheticEvent) => {
        onDelete(event);

        if (open) handleToggleDialog();
    };

    const handleHide = (event: SyntheticEvent) => {
        if (onHide) {
            onHide(event);

            if (open) handleToggleDialog();
        }
    };

    const buttons = useMemo(() => (
        <>
            <WrapperButton
                onClick={handleDelete}
                className={cn(styles.contextMenuItem, { [styles.gc]: inGamecenter })}
            >
                <Icon name="delete-outline" />
                <span>
                    {formatMessage(messages.deleteNotification)}
                </span>
            </WrapperButton>

            {onHide && (
                <WrapperButton
                    onClick={handleHide}
                    className={cn(styles.contextMenuItem, { [styles.gc]: inGamecenter })}
                >
                    <Icon name="hide-outline" />
                    <span>
                        {formatMessage(messages.hideProject)}
                    </span>
                </WrapperButton>
            )}
        </>
    ), [onHide, open]);

    return (
        <>
            <Button
                className={styles.contextMenuIcon}
                onClick={handleClick}
                leftIcon={<Icon name="burger" />}
                size="lg"
                appearance="overlay"
                mode="link"
                data-tooltip-id={tooltipId}
            />

            {(isMobile && !inGamecenter) ? (
                <Dialog
                    open={open}
                    onClose={handleToggleDialog}
                    className={styles.dialogMobile}
                    hideCloseIcon
                >
                    <div
                        className="vkp-app-shell"
                        data-theme="dark"
                    >
                        {buttons}

                        <Button
                            size="lg"
                            appearance="neutral"
                            onClick={handleToggleDialog}
                            className={styles.cancelContextMenuButton}
                        >
                            {formatMessage(messages.controlCancel)}
                        </Button>
                    </div>
                </Dialog>
            ) : (
                <Tooltip
                    id={tooltipId}
                    place="bottom-start"
                    clickable
                    opacity={1}
                    positionStrategy="fixed"
                    className={cn({ [styles.gc]: inGamecenter })}
                >
                    {buttons}
                </Tooltip>
            )}
        </>
    );
}

export default ContextMenu;
