import React, { useMemo } from 'react';

import { copyTextToClipboard } from '@vkplay/shared';
import { Tooltip, WrapperButton } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import styles from '@components/TopNav/Notifications/Notification/Notification.module.css';
import snackbar from '@kit/SnackBar/SnackBar';

import {
    notifHighTextRegexp, promoRegexp, highlightRegexp, urlRegexp,
} from '../constants';
import messages from '../messages';

import type { ReactElement, SyntheticEvent } from 'react';

function NotificationText({
    text,
    id,
}: { text: string, id: string, }) {
    const { formatMessage } = useIntl();
    const matches = useMemo(() => text.split(notifHighTextRegexp), [text]);

    const handlePromoClick = (event: SyntheticEvent<HTMLButtonElement>, value: string) => {
        event.stopPropagation();

        copyTextToClipboard(value, () => {
            snackbar.sendMessage({
                status: 'success',
                message: formatMessage(messages.copyPromoSuccess),
            });
        });
    };

    const highlightedText = useMemo(() => {
        let data: (ReactElement | string)[] = [];

        data = matches.map((item, index) => {
            if (promoRegexp.test(item)) {
                const promoTooltipId = `promo-tooltip-${id}`;
                const value = item.replace(/<|>/g, '');

                return (
                    <>
                        <WrapperButton
                            key={`promo-${value}`}
                            onClick={(event) => handlePromoClick(event, value)}
                            className={styles.highlighted}
                            data-tooltip-id={promoTooltipId}
                        >
                            {value}
                        </WrapperButton>
                        <Tooltip
                            id={promoTooltipId}
                            key={`promo-tooltip-${value}`}
                            opacity={1}
                            className={styles.copyTooltip}
                            place="bottom"
                        >
                            <span>{formatMessage(messages.copyPromo)}</span>
                        </Tooltip>
                    </>
                );
            }

            if (highlightRegexp.test(item)) {
                const value = item.replace(/\[|\]/g, '');

                return (
                    <span
                        key={`highlight-${index + value}`}
                        className={styles.highlighted}
                    >
                        {value}
                    </span>
                );
            }

            if (urlRegexp.test(item)) {
                const url = item.split(']')[0].replace('[url=', '');
                const value = item.replace(/\[url=[^\]\s]*\]/g, '').replace(/\[\/url\]/g, '');

                return (
                    <a
                        key={`highlight-${index + url}`}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.highlighted}
                        onClick={(event) => event.stopPropagation()}
                    >
                        {value}
                    </a>
                );
            }

            return item;
        });

        return data;
    }, []);

    return (
        <span className={styles.text}>
            {highlightedText}
        </span>
    );
}

export default NotificationText;
