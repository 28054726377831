import React, {
    useContext, useEffect, useState,
} from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { inGamecenter, triggerPixel } from '@vkplay/shared';
import { Icon, useBreakpoints, WrapperButton } from '@vkplay/ui';
import cn from 'classnames';
import { InView } from 'react-intersection-observer';
import { useIntl } from 'react-intl';

import ContextMenu from './ContextMenu';
import FriendRequestNotification from './FriendRequestNotification';
import styles from './Notification.module.css';
import NotificationActions from './NotificationActions';
import NotificationLabel from './NotificationLabel';
import NotificationPicture from './NotificationPicture';
import NotificationText from './NotificationText';
import UnsubscribedNotification from './UnsubscribedNotification';
import { notificationDate } from '../constants';
import NotificationsContext from '../context/NotificationsContext';
import { handleDeleteNotification, handleToggleSubscribe } from '../helpers';
import messages from '../messages';
import { NotificationTemplate } from '../types';

import type { NotificationProps } from '../types';
import type { SyntheticEvent } from 'react';

function Notification(props: NotificationProps) {
    const { tabletMdMax: isMobile } = useBreakpoints();
    const { onReadNotification, onDeleteNotification } = useContext(NotificationsContext);
    const { locale } = useIntl();
    const { pushReachGoal } = useAnalyticsMethods();

    const {
        notification: {
            id,
            created,
            controls,
            data: {
                title,
                text,
                project_name,
                unsubscribe_url,
                gc_link_url,
                link_url,
                pixel_click_url,
            },
            is_read,
            template_id,
        },
        onClose,
    } = props;

    const [unsubscribed, setUnsubscribed] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const { formatMessage } = useIntl();
    const inBubble = !!onClose;
    const href = inGamecenter ? gc_link_url : link_url;

    useEffect(() => {
        if (inBubble) {
            pushReachGoal({
                params: {
                    category: 'notifications',
                    action: 'show',
                },
            });
        }
    }, []);

    const handleClick = (event: SyntheticEvent) => {
        const target = event.target as HTMLElement;

        // TODO: Надоело писать везде stopPropagation, плюс срабатывает даже на диалоге, надо что-то придумать
        if ([styles.highlighted,
            styles.wrapper,
            styles.content,
            styles.title,
            styles.text,
            styles.date]
            .includes(target.className)) {
            if (href) window.open(href, '_blank')?.focus();

            if (inGamecenter) {
                triggerPixel(pixel_click_url);
            }

            pushReachGoal({
                params: {
                    category: 'notifications',
                    action: 'click',
                    label: href,
                    entity_id: inBubble ? 'popup' : 'inside kolokolchik',
                },
            });
        }
    };

    const handleDelete = async (event: SyntheticEvent) => {
        event.stopPropagation();
        await handleDeleteNotification(id);
        setDeleted(true);
        onDeleteNotification();

        pushReachGoal({
            params: {
                category: 'notifications',
                action: 'menu_delete_click',
                label: 'inside kolokolchik',
                entity_id: href,
            },
        });
    };

    const handleUnsubscribe = async (event: SyntheticEvent) => {
        event.stopPropagation();
        await handleToggleSubscribe(unsubscribe_url, unsubscribed);
        setUnsubscribed((prevState) => !prevState);

        pushReachGoal({
            params: {
                category: 'notifications',
                action: 'menu_hide_click',
                label: 'inside kolokolchik',
                entity_id: href,
            },
        });
    };

    const handleMouseEnter = async () => {
        if (is_read || inBubble) return;

        onReadNotification(id);
    };

    const handleInViewChange = (value: boolean) => {
        if (!value) return;

        setTimeout(() => {
            handleMouseEnter();
        }, 1500);
    };

    if (deleted && (!isMobile || inGamecenter)) {
        return (
            <div className={styles.deleted}>
                {formatMessage(messages.deleted)}
            </div>
        );
    }

    if (template_id === NotificationTemplate.FriendRequest) {
        return (
            <FriendRequestNotification
                {...props}
                onDelete={handleDelete}
                onRead={handleMouseEnter}
                onClose={onClose}
                onView={handleInViewChange}
            />
        );
    }

    if (unsubscribed) {
        return (
            <UnsubscribedNotification
                {...props}
                revert={handleUnsubscribe}
            />
        );
    }

    const hasUnsubscribe = (!!project_name && !!unsubscribe_url);

    return (
        <InView
            className={cn(styles.wrapper, 'vkp-notification-wrapper', {
                [styles.new]: (!is_read || (isMobile && deleted && !inGamecenter)) && !inBubble,
                [styles.inBubble]: inBubble,
                [styles.link]: !!href,
            })}
            onMouseEnter={handleMouseEnter}
            onClick={handleClick}
            triggerOnce
            skip={is_read || !isMobile || inGamecenter}
            onChange={handleInViewChange}
            threshold={1}
            rootMargin="0px 0px 60px 0px"
        >
            {deleted && isMobile && (
                <NotificationLabel
                    label={formatMessage(messages.deleted)}
                    position="top"
                />
            )}

            <div className={styles.content}>
                <NotificationPicture {...props} />

                <div className={styles.data}>
                    <span className={styles.title}>
                        {title}
                    </span>

                    {text && (
                        <NotificationText
                            text={text}
                            id={id}
                        />
                    )}

                    {!inBubble && (
                        <span className={styles.date}>
                            {notificationDate(created, locale)}
                        </span>
                    )}

                    {controls.length > 0 && (
                        <NotificationActions
                            {...props}
                            inBubble={inBubble}
                        />
                    )}
                </div>

                {inBubble ? (
                    <WrapperButton
                        onClick={() => onClose(id)}
                        className={styles.contextMenuItem}
                    >
                        <Icon name="close" />
                    </WrapperButton>
                ) : (
                    <ContextMenu
                        id={id}
                        onDelete={handleDelete}
                        onHide={hasUnsubscribe ? handleUnsubscribe : undefined}
                        href={href}
                    />
                )}
            </div>

            {(!is_read && !isMobile && !inBubble) && (
                <div className={styles.newLabel} />
            )}
        </InView>
    );
}

export default Notification;
