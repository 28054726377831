import React from 'react';

import { Icon } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import messages from './messages';
import styles from './Terms.module.css';

import type { TermDetailsProps } from '@components/TermsDialog/types';

function TermsDetails({
    cis = true,
    terms,
    lang,
}: TermDetailsProps) {
    const { formatMessage } = useIntl();

    return (
        <details className={styles.details}>
            <summary className={styles.summary}>
                {cis ? formatMessage(messages.docsCis) : formatMessage(messages.docsNonCis)}

                <Icon name="chevron-down-24" />
            </summary>

            <ul>
                {terms.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                        <a
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {item[lang]}
                        </a>
                    </li>
                ))}
            </ul>
        </details>
    );
}

export default TermsDetails;
