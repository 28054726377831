import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    title: {
        id: 'header.more',
        defaultMessage: 'Ещё',
    },
    noAuth: {
        id: 'profile.no_auth_title',
        defaultMessage: 'Авторизуйтесь, чтобы получить доступ ко всем функциям сервиса',
    },
    loginToAccount: {
        id: 'profile.login_to_account',
        defaultMessage: 'Войти в аккаунт',
    },
    copied: {
        id: 'profile.id.copied',
        defaultMessage: 'ID успешно скопирован',
    },
});

export default messages;
