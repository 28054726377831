import React, { useContext, useEffect, useState } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { useBreakpoints } from '@vkplay/ui';

import { getLocalStorageClosed, showPopupDomain } from '@components/PopupBubble/SystemPopups/RecPopup/helpers';
import NotificationsContext from '@components/TopNav/Notifications/context/NotificationsContext';
import AppContext from '@context/AppContext';
import { useOffsetLeft } from '@hooks';
import { HiddenElementsTypes } from '@services/constants';

import NotificationPopup from './NotificationPopup';
import styles from './PopupBubble.module.css';
import RecPopup from './SystemPopups/RecPopup';

import type { ReactElement } from 'react';

function PopupBubble() {
    const { list, onReadNotification } = useContext(NotificationsContext);
    const { hiddenElements } = useContext(AppContext);
    const { offsetLeft } = useOffsetLeft('#vkp-navbar');
    const [elements, setElements] = useState<ReactElement[]>([]);
    const [notificationIds, setNotificationIds] = useState<string[]>([]);
    const { tabletMdMin } = useBreakpoints();
    const { pushReachGoal } = useAnalyticsMethods();

    useEffect(() => {
        const newList: ReactElement[] = [];
        const isAdvClosed = getLocalStorageClosed();

        if (!isAdvClosed && !hiddenElements?.includes(HiddenElementsTypes.RecPopup) && showPopupDomain) {
            newList.push(<RecPopup onClose={handlePopupClose} />);
        }

        setElements((prevState) => [...newList, ...prevState]);
    }, []);

    useEffect(() => {
        if (!tabletMdMin) return;

        const newList: ReactElement[] = [];
        const newIds: string[] = [];

        if (list.length > 0) {
            newList.push(
                ...list
                    .filter((item) => !item.is_read && !notificationIds.includes(item.id) && item.needs_bubble)
                    .map((item) => {
                        newIds.push(item.id);

                        return (
                            <NotificationPopup
                                notification={item}
                                onClose={handleNotificationClose}
                                key={item.id}
                            />
                        );
                    }),
            );
        }

        setElements((prevState) => [...prevState, ...newList]);
        setNotificationIds((prevState) => [...prevState, ...newIds]);
    }, [list.length]);

    function handleNotificationClose(id: string) {
        onReadNotification(id);
        setElements((prevState) => prevState.slice(1));

        pushReachGoal({
            params: {
                category: 'notifications',
                action: 'close',
            },
        });
    }

    function handlePopupClose() {
        setElements((prevState) => prevState.slice(1));
    }

    if (elements.length === 0) return null;

    return (
        <div
            className={styles.popupContainer}
            style={{
                transform: `translateX(${offsetLeft}px)`,
            }}
        >
            {elements[0]}
        </div>
    );
}

export default PopupBubble;
