import cloud from './cloud.schema';
import live from './live.schema';
import market from './market.schema';
import media from './media.schema';
import pvp from './pvp.schema';
import store from './store.schema';

import type { NavigationPreset, NavigationSchema } from './types';

const SCHEMA = {
    store,
    cloud,
    market,
    live,
    media,
    pvp,
} as const satisfies NavigationSchema;

export const PROJECTS_ORDER = ['store', 'cloud', 'market', 'live', 'media', 'pvp'] satisfies NavigationPreset[];

export default SCHEMA;
