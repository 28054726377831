import SCHEMA from '@navigation';
import getCurrentAliasUrl from '@navigation/utils/getCurrentAliasUrl';

import getPathname from './getPathname';

import type { NavigationItemBasic, NavigationPreset } from '@navigation/types';

interface Params {
    preset?: NavigationPreset;
    subnav?: NavigationItemBasic[];
    currentPathname?: string;
}

export default function getSubnavSchema({
    preset,
    subnav,
    currentPathname,
}: Params) {
    if (!preset) {
        return {
            schema: [],
            label: '',
        };
    }

    const currentPath = (currentPathname || window.location.pathname)?.replace('/gamecenter/', '/');
    const currentSchema = SCHEMA[preset];
    const label = currentSchema?.label;

    console.debug('current', currentPath);

    const schema = (subnav || currentSchema?.subnav || [])
        .map((item: NavigationItemBasic) => {
            const pathname = getPathname(item.href?.replace('/gamecenter/', '/'));
            const isItemActive = item.allowPartialMatch ? currentPath.includes(pathname) : pathname === currentPath;
            const isIncludedUrl = getCurrentAliasUrl(item, 'include');
            const isExcludedUrl = getCurrentAliasUrl(item, 'exclude');

            console.debug('current_active', isItemActive);

            return ({
                ...item,
                isActive: (isItemActive && !isExcludedUrl) || isIncludedUrl,
            });
        })
        // Убрать "Мои игры" из стора
        .filter((item: NavigationItemBasic) => !(preset === 'store' && item.id === 'my'));

    return {
        schema,
        label,
    };
}
