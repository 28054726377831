import React, { useEffect } from 'react';

import { createPortal } from 'react-dom';

import ThemeWrapper from '@kit/ThemeWrapper';

import type { PortalProps } from './types';
import type { FC } from 'react';

const Portal: FC<PortalProps> = ({
    into = 'body',
    children,
    shouldDisableBodyScroll,
}) => {
    const elem = document.querySelector(into) ?? document.body;

    const wrappedChildren = (
        <ThemeWrapper>{children}</ThemeWrapper>
    );

    useEffect(() => {
        if (!shouldDisableBodyScroll) {
            return;
        }

        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'initial';
        };
    }, []);

    return createPortal(
        wrappedChildren,
        elem,
    );
};

export default Portal;
