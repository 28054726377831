import React from 'react';

import { useIntl } from 'react-intl';

import messages from './messages';
import styles from './SearchDropdownPlaceholder.module.css';
import LoaderSvg from './svg/loader.svg?react';

import type { SearchDropdownPlaceholderProps } from './types';
import type { FC } from 'react';

const SearchDropdownPlaceholder: FC<SearchDropdownPlaceholderProps> = ({ isLoading }) => {
    const { formatMessage } = useIntl();

    if (isLoading) {
        return (
            <div key="placeholder" className={styles.root}>
                <div className={styles.loader}>
                    <LoaderSvg />
                </div>
            </div>
        );
    }

    return (
        <div key="placeholder" className={styles.root}>
            <div className={styles.title}>
                {formatMessage(messages.placeholderNotFoundTitle)}
            </div>
            <div className={styles.description}>
                {formatMessage(messages.placeholderNotFoundDescription)}
            </div>
        </div>
    );
};

export default SearchDropdownPlaceholder;
