import React, { type FC } from 'react';

import { useIntl } from 'react-intl';

import messages from './messages';
import styles from './SearchEmpty.module.css';

const SearchEmpty: FC = () => {
    const { formatMessage } = useIntl();

    return (
        <div className={styles.empty}>
            <div className={styles.title}>
                {formatMessage(messages.emptyTitle)}
            </div>
            <div className={styles.descr}>
                {formatMessage(messages.emptyDescr)}
            </div>
        </div>
    );
};

export default SearchEmpty;
