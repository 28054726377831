import {
    catalogMessages, cloudMessages, marketMessages, mediaMessages, otherMessages, streamMessages, tourneyMessages,
} from './messages';

export const catalogLinks = [
    {
        title: catalogMessages.catalog,
        href: 'https://vkplay.ru/play/',
    },
    {
        title: catalogMessages.catalogAvailable,
        href: 'https://vkplay.ru/play/',
    },
    {
        title: catalogMessages.catalogFree,
        href: 'https://vkplay.ru/play/is_free/',
    },
    {
        title: catalogMessages.catalogDiscounts,
        href: 'https://vkplay.ru/play/sale/',
    },
    {
        title: catalogMessages.catalogMyGames,
        href: 'https://profile.vkplay.ru/profile/games',
    },
];

export const cloudLinks = [
    {
        title: cloudMessages.cloud,
        href: 'https://cloud.vkplay.ru',
    },
    {
        title: cloudMessages.cloudMain,
        href: 'https://cloud.vkplay.ru/',
    },
    {
        title: cloudMessages.cloudRates,
        href: 'https://cloud.vkplay.ru/#plans_calculator',
    },
    {
        title: cloudMessages.cloudDownload,
        href: 'https://cloud.vkplay.ru/dashboard/download/',
    },
    {
        title: cloudMessages.cloudFAQ,
        href: 'https://cloud.vkplay.ru/dashboard/faq/',
    },
];

export const marketLinks = [
    {
        title: marketMessages.market,
        href: 'https://market.vkplay.ru/',
    },
    {
        title: marketMessages.goods,
        href: 'https://market.vkplay.ru/',
    },
    {
        title: marketMessages.refill,
        href: 'https://market.vkplay.ru/shop/255/',
    },
];

export const streamLinks = [
    {
        title: streamMessages.streams,
        href: 'https://vkplay.ru/live/',
    },
    {
        title: streamMessages.often,
        href: 'https://vkplay.ru/live/',
    },
    {
        title: streamMessages.online,
        href: 'https://vkplay.ru/live/',
    },
];

export const mediaLinks = [
    {
        title: mediaMessages.media,
        href: 'https://media.vkplay.ru/',
    },
    {
        title: mediaMessages.allMaterials,
        href: 'https://media.vkplay.ru/',
    },
    {
        title: mediaMessages.gameNews,
        href: 'https://media.vkplay.ru/news/',
    },
    {
        title: mediaMessages.articles,
        href: 'https://media.vkplay.ru/articles/feat/',
    },
    {
        title: mediaMessages.guides,
        href: 'https://media.vkplay.ru/articles/secret/',
    },
];

export const tourneyLinks = [
    {
        title: tourneyMessages.tourney,
        href: 'https://pvp.vkplay.ru/',
    },
    {
        title: tourneyMessages.review,
        href: 'https://pvp.vkplay.ru/',
    },
    {
        title: tourneyMessages.allTourneys,
        href: 'https://pvp.vkplay.ru/tournaments',
    },
];

export const otherLinks = [
    {
        title: otherMessages.other,
        href: '',
    },
    {
        title: otherMessages.developers,
        href: 'https://developers.vkplay.ru/welcome',
    },
    {
        title: otherMessages.contacts,
        href: 'https://documentation.vkplay.ru/contacts',
    },
    {
        title: otherMessages.support,
        href: 'https://support.vkplay.ru/',
    },
];

export const bottomLinks = {
    terms: 'https://documentation.vkplay.ru/terms_vkp/tou_vkp',
    policy: {
        ru_RU: 'https://documentation.vkplay.ru/terms_vkp/privacy_vkp',
        en_US: 'https://documentation.vkplay.ru/terms_vkp/privacy_world_vkp',
    },
    use_recs: 'https://documentation.vkplay.ru/terms_vkp/adv_algo_rules',
    imprint: 'https://documentation.vkplay.ru/terms_vkp/imprint_vkp',
};

export const COLUMNS = [
    catalogLinks,
    cloudLinks,
    marketLinks,
    streamLinks,
    mediaLinks,
    tourneyLinks,
    otherLinks,
];

export const SOCIAL = [
    {
        href: 'https://vk.com/play',
        icon: 'social-vk',
    },
    {
        href: 'https://t.me/vkplay_ru',
        icon: 'social-telegram',
    },
    {
        href: 'https://dzen.ru/vkpm',
        icon: 'social-dzen',
    },
];
