import React from 'react';

import { inGamecenter } from '@vkplay/shared';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import messages from './messages';
import styles from './Notifications.module.css';

function EmptyList() {
    const { formatMessage } = useIntl();

    return (
        <div className={cn(styles.emptyList, { [styles.gc]: inGamecenter })}>
            <span>{formatMessage(messages.noNotificationsYet)}</span>
            <span>{formatMessage(messages.futureNotifications)}</span>
        </div>
    );
}

export default EmptyList;
