import React from 'react';

import { Button } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import styles from '@components/TopNav/Notifications/Notification/Notification.module.css';

import messages from '../messages';

import type { UnsubscribedNotificationProps } from '../types';

function UnsubscribedNotification({
    notification: {
        data: {
            project_name,
            image_url,
        },
    },
    revert,
}: UnsubscribedNotificationProps) {
    const { formatMessage } = useIntl();

    return (
        <div
            className={cn(styles.wrapper, styles.updated)}
        >
            <div className={styles.content}>
                <img
                    alt={project_name}
                    src={image_url}
                    className={styles.picture}
                />

                <div className={styles.data}>
                    <span className={styles.title}>
                        {formatMessage(messages.unsubscribed, { project_name })}
                    </span>

                    <div className={styles.actions}>
                        <Button
                            onClick={revert}
                            size="sm"
                            appearance="alpha"
                        >
                            {formatMessage(messages.cancel)}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnsubscribedNotification;
