import React, {
    Fragment,
    useEffect, useRef,
} from 'react';

import { inGamecenter } from '@vkplay/shared';
import { useBreakpoints } from '@vkplay/ui';
import cn from 'classnames';
import { Toaster } from 'sonner';

import Footer from '@components/Footer';
import GCDownloadHint from '@components/GCDownloadHint';
import PopupBubble from '@components/PopupBubble';
import Sidebar from '@components/Sidebar';
import SteamError from '@components/SteamError';
import Subnav from '@components/Subnav';
import TermsDialog from '@components/TermsDialog';
import TopNav from '@components/TopNav';
import { NotificationsContextProvider } from '@components/TopNav/Notifications/context/NotificationsContext';
import { AppProvider } from '@context/AppContext';
import { IntlProvider } from '@context/IntlContext';
import { SnackBarSettings, SnackBarSettingsMob } from '@kit/SnackBar/settings';
import ThemeWrapper from '@kit/ThemeWrapper';

import styles from './App.module.css';
import Ready from './components/Ready';
import ScrollTopButton from './components/ScrollTopButton';

import type { AppShellProps } from '@/types';
import type { FC } from 'react';

const AppShell: FC<AppShellProps> = ({
    children,
    appNode,
    containerType,
    ...config
}) => {
    const mainRef = useRef<HTMLDivElement>(null);
    const { simplified, scrollTopButtonOffset } = config;
    const { tabletSmMax } = useBreakpoints();
    const hideNavGC = inGamecenter || config.hideNavGC;
    const hideNav = hideNavGC || config.hideNav;
    const hideFooter = hideNavGC || config.hideFooter;
    const isHidden = config.hideNav && config.hideFooter;

    function placeAppNode(container: HTMLDivElement, newChildren: Node) {
        container.replaceChildren(newChildren);
    }

    useEffect(() => {
        if (!appNode || !mainRef.current) {
            return;
        }

        placeAppNode(mainRef.current, appNode);
    }, [appNode, mainRef]);

    if (typeof window === 'undefined') {
        return children;
    }

    const SimplifiedHeader = (
        <div
            className={cn({
                [styles.appGameCenter]: inGamecenter && !isHidden,
            })}
            id="vkp-app-shell"
        >
            {!hideNav && (
                <IntlProvider>
                    <TopNav />
                </IntlProvider>
            )}
            <main
                className={styles.mainAppContent}
                ref={mainRef}
            >
                {children}
            </main>
            {!hideFooter && (
                <IntlProvider>
                    <Footer />
                </IntlProvider>
            )}
        </div>
    );

    const WithNotificationProvider = hideNav ? Fragment : NotificationsContextProvider;

    return (
        <AppProvider config={config}>
            <ThemeWrapper
                className={cn(styles.wrapper, {
                    [styles.simplified]: simplified,
                })}
                theme="dark"
            >
                {simplified ? SimplifiedHeader
                    : (
                        <WithNotificationProvider>
                            <div
                                className={cn(styles.appShell, {
                                    [styles.appGameCenter]: inGamecenter && !isHidden,
                                })}
                                id="vkp-app-shell"
                            >
                                {!hideNav && (
                                    <IntlProvider>
                                        <Sidebar />
                                    </IntlProvider>
                                )}
                                <div className={styles.shellContent}>
                                    {!config.hideNav && (
                                        <IntlProvider>
                                            {!hideNavGC && <TopNav />}
                                            <Subnav />
                                        </IntlProvider>
                                    )}

                                    <main
                                        className={styles.mainAppContent}
                                        style={{ container: `app / ${containerType}` }}
                                        ref={mainRef}
                                    >
                                        {/* тут чёрная дыра, в ней пропадёт всё
                                    кроме children (см. placeAppNode) */}
                                        {children}
                                    </main>
                                    {!hideNav && (
                                        <IntlProvider>
                                            <PopupBubble />
                                        </IntlProvider>
                                    )}
                                    {!hideFooter && (
                                        <IntlProvider>
                                            <Footer />
                                        </IntlProvider>
                                    )}
                                </div>
                            </div>
                        </WithNotificationProvider>
                    )}
                <IntlProvider>
                    <Toaster
                        {...SnackBarSettings}
                        {...(tabletSmMax && SnackBarSettingsMob)}
                    />
                    {!hideNav && (<ScrollTopButton topOffset={scrollTopButtonOffset} />)}
                    {!hideNavGC && (<TermsDialog />)}
                    <SteamError />
                    <GCDownloadHint />
                </IntlProvider>
            </ThemeWrapper>
            <IntlProvider>
                <Ready />
            </IntlProvider>
        </AppProvider>
    );
};

export default AppShell;
