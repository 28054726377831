import React, {
    useContext, useState,
} from 'react';

import { Dialog } from '@vkplay/ui';

import TermsContent from '@components/TermsDialog/TermsContent';
import useGetTerms from '@components/TermsDialog/useGetTerms';
import AppContext from '@context/AppContext';
import ThemeWrapper from '@kit/ThemeWrapper';
import { HiddenElementsTypes } from '@services/constants';

import styles from './Terms.module.css';

function TermsDialog() {
    const [open, setOpen] = useState(false);
    const { hiddenElements } = useContext(AppContext);
    const hideTerms = hiddenElements?.includes(HiddenElementsTypes.Terms);

    const { cisTerms, worldTerms } = useGetTerms({ hideTerms, setOpen });

    return (
        <Dialog
            open={open}
            onClose={() => {}}
            className={styles.dialog}
            hideCloseIcon
        >
            <ThemeWrapper>
                <TermsContent
                    setOpen={setOpen}
                    cisTerms={cisTerms}
                    worldTerms={worldTerms}
                />
            </ThemeWrapper>
        </Dialog>
    );
}

export default TermsDialog;
