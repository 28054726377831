import React from 'react';

import { Icon } from '@vkplay/ui';
import cn from 'classnames';
import { toast } from 'sonner';

import styles from './Snackbar.module.css';

import type { SendMessageParams, SnackBarProps } from '@kit/SnackBar/types';

const iconsMap = {
    success: <Icon name="check-long" />,
    error: <Icon name="error" />,
    warning: <Icon name="warning" />,
    info: <Icon name="info-circle" />,
};

const sendMessage = ({
    message,
    status,
    className = 'snackbar-toast',
    title,
    options,
}: SendMessageParams) => {
    const opts = {
        duration: 5000,
        unstyled: true,
        className,
        ...options,
    };

    toast.custom((t) => (
        <div className={cn(styles.snackbar, {
            [styles[status]]: status,
        })}
        >
            <button className={styles.close} onClick={() => toast.dismiss(t)}>
                <Icon name="close" />
            </button>
            <div className={styles.content}>
                <div className={styles.icon}>
                    {iconsMap[status]}
                </div>
                <div className={styles.info}>
                    {title ? (
                        <>
                            <h3 className={styles.title}>{title}</h3>
                            <p className={styles.description}>{message}</p>
                        </>
                    ) : (
                        <p className={styles.text}>{message}</p>
                    )}
                </div>
            </div>
        </div>
    ), opts);
};

const snackbar: SnackBarProps = {
    sendMessage,
};

export default snackbar;
