import BrowserDetect from './detect';

const envDetect = BrowserDetect.detect().parse(navigator.userAgent);

export const browserFamily = envDetect.browser.family;
export const browserVersion = envDetect.browser.major;

export const getBrowserClassName = () => `is${browserFamily}`;

export const getBrowserVersionClassName = () => {
    if (browserFamily.toLowerCase() === 'chrome' && +browserVersion >= 115) {
        return `is${browserFamily}TopBar`;
    }

    return `is${browserFamily}${browserVersion}`;
};

export const browserClassName = getBrowserClassName();
export const browserVersionClassName = getBrowserVersionClassName();
