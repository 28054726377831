import { useState, useEffect, type FC } from 'react';

import { useAnalytics, useAnalyticsMethods } from '@vkplay/analytics';

import type { PageViewTypes } from '@components/PageView/types';

const PageView: FC<PageViewTypes> = ({ isReady, userId }) => {
    const [isViewed, setIsViewed] = useState(false);
    const { pushPageView, pushSetUser } = useAnalyticsMethods();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { isAnalyticsAllowed } = useAnalytics();

    useEffect(() => {
        if (isReady && isAnalyticsAllowed && !isViewed) {
            pushPageView({
                url: window.location.href,
            });

            setIsViewed(true);
        }

        if (userId) {
            pushSetUser(userId);
        }
    }, [isReady, isAnalyticsAllowed, userId, isViewed]);

    return null;
};

export default PageView;
