import type { ReactNode } from 'react';
import type { ITooltip } from 'react-tooltip';

export enum LangTooltipEvents {
    Click = 'click',
}

export type LangTooltipProps = ITooltip & {
    locale: string,
    onEvent: (event: LangTooltipEvents, key: string) => void,
}

export interface LangDropdownProps {
    tooltipId?: string;
    isMobile?: boolean;
    transparent?: boolean;
    title?: ReactNode | string;
}

export interface LangMobileProps {
    isOpen: boolean;
    onClose: () => void;
    onSetLang: () => void;
    onSelectLang: (lang: string) => void;
    selectedLang: string;
}
