import React, { type FC } from 'react';

import cn from 'classnames';

import styles from '@components/Sidebar/Sidebar.module.css';
import { useTranslate } from '@hooks';

import type { SidebarSubItemProps } from '@components/Sidebar/types';

const SidebarSubItem: FC<SidebarSubItemProps> = ({ item, onClick }) => {
    const label = useTranslate(item.label);

    return (
        <li
            key={`${item.id}_${item.href}`}
            className={styles.navItem}
        >
            <a
                href={item.href}
                className={cn(styles.navItemLink, styles.navItemLinkSub)}
                data-id={item.id}
                onClick={onClick}
            >
                <span className={styles.navItemBtn}>
                    {label}
                </span>
            </a>
        </li>
    );
};

export default SidebarSubItem;
