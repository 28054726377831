import React, { useCallback, useContext, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { offset } from '@floating-ui/dom';
import { useAnalyticsMethods } from '@vkplay/analytics';
import { Button, Icon, Tooltip } from '@vkplay/ui';
import cn from 'classnames';

import SessionContext from '@context/SessionContext';
import { showLogin } from '@services/auth';

import List from './List';
import styles from './Wishlist.module.css';
import topPopupStyles from '../TopNav/TopPopup.module.css';

function Wishlist() {
    const { pushReachGoal } = useAnalyticsMethods();
    const { noSession, loaded } = useContext(SessionContext);
    const [count, setCount] = useState(0);

    const handleClick = useCallback(() => {
        if (noSession) {
            showLogin();
        }

        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-library',
            },
        });
    }, [loaded]);

    if (!loaded) return null;

    return (
        <>
            <Button
                size="lg"
                appearance="neutral"
                onClick={handleClick}
                className={topPopupStyles.button}
                leftIcon={<Icon name="bookmark-outline" />}
                data-tooltip-id="wishlist-wishlist"
            />
            {!noSession && (
                <Tooltip
                    id="wishlist-wishlist"
                    place="bottom-start"
                    className={cn(topPopupStyles.wrapper, topPopupStyles.wishlist, styles.wishlist, {
                        [topPopupStyles.full]: count === 0,
                    })}
                    afterShow={() => {}}
                    opacity={1}
                    clickable
                    openOnClick
                    closeEvents={{
                        click: true,
                    }}
                    middlewares={[
                        offset(10),
                    ]}
                >
                    <List setCount={setCount} />
                </Tooltip>
            )}
        </>
    );
}

export default Wishlist;
