import React from 'react';

import { Icon, useBreakpoints } from '@vkplay/ui';
import cn from 'classnames';

import { useTranslate } from '@hooks';

import styles from './Footer.module.css';

import type { ColumnElement, FooterColumnProps } from './types';

function FooterColumnLink({
    title, href,
}: ColumnElement) {
    const linkTitle = useTranslate(title);

    return (
        <a
            className={cn(styles.baseText, styles.link)}
            href={href}
            key={linkTitle}
            target="_blank"
            rel="noreferrer"
        >
            {linkTitle}
        </a>
    );
}

function FooterColumn({
    column,
}: FooterColumnProps) {
    const { isMobile } = useBreakpoints();
    const [title, ...links] = column;
    const columnTitle = useTranslate(title.title);

    const Wrapper = isMobile ? 'details' : 'div';

    return (
        <Wrapper
            className={styles.column}
            open={!isMobile}
        >
            <summary
                className={styles.title}
            >
                {(!isMobile && title.href) ? (
                    <a
                        href={title.href}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {columnTitle}
                    </a>
                ) : (
                    columnTitle
                )}

                <Icon
                    name="chevron-right"
                    className={styles.marker}
                />
            </summary>

            <div className={styles.columnList}>
                {links.map((link, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <FooterColumnLink key={`${idx}-${link.href}`} {...link} />
                ))}
            </div>
        </Wrapper>
    );
}

export default FooterColumn;
